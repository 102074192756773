import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IonText,
  isPlatform,
  IonGrid,
  IonCol,
  IonButton,
  IonIcon,
  IonRow,
  IonRouterLink,
  useIonViewWillEnter
} from '@ionic/react'
import { Plugins } from '@capacitor/core'
import { Storage } from '@capacitor/storage'
import { Device } from '@capacitor/device'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { logoFacebook } from 'ionicons/icons'
import { LineLogin } from '@ionic-native/line-login'

import { PageLayout, PageContent } from 'components/layout'
import SignUpForm from 'forms/SignUpForm'
import { setErrorAlertMessageSucceeded } from 'actions/resources'
import {
  initiatiePhoneAuthentication,
  signIn,
  signUp,
  updateCurrentDevice
} from 'actions/authentication'
import Spinner from 'components/Spinner'

import './SignUpPage.scss'
import { tenantSchema } from '@seekster/schemas'
import { getCurrentResource } from 'selectors/authentication'
import TenantLogoHeader from 'components/TenantLogoHeader'
import LHeader from 'components/typography/LHeader/LHeader'
import MediumParagraph from 'components/typography/MediumParagraph'
import SendOtpForm from 'forms/SendOtpForm'
import Paragraph from 'components/typography/Paragraph'
import SmallParagraph from 'components/typography/SmallParagraph'
import { usePolicyModal } from 'hooks/policy'
import PolicyTermsModal from 'components/modals/PolicyTermsModal'

const { FacebookLogin, SignInWithApple } = Plugins

const isDev = process.env.NODE_ENV === 'development'

const SignUpPage = ({ history }) => {
  const { t } = useTranslation(['sign_up', 'verify_phone_number'])
  const dispatch = useDispatch()
  const [deviceInfo, setDeviceInfo] = useState({})
  const [loading, setLoading] = useState(false)

  const { openPolicy, openPolicyModal } = usePolicyModal()

  const tenant =
    useSelector((state) => getCurrentResource(state, tenantSchema)) || new Map()

  useEffect(() => {
    Device.getInfo().then(setDeviceInfo)
  }, [])

  useIonViewWillEnter(() => {
    openPolicy()
  })

  // ======== Update these functions to allow email registrations ===========
  // const tenantsThatAllowEmailRegistrations = []
  // const showRegisterByEmailForm = tenantsThatAllowEmailRegistrations.includes(
  //   tenant.get('slug')
  // =========================================================================

  // For now never show email form, uncomment above to add tenants that allow
  // email registration
  const showRegisterByEmailForm = false

  // Registers a new empty user and creates a blank verification.
  // If a verification already exists (but the user is still blank without a password)
  // We will try to login to that verification
  const handleSmsRegistration = async (phoneNumber) => {
    // if (!termsAccepted) return setShowModal(true)
    setLoading(true)

    dispatch(initiatiePhoneAuthentication(phoneNumber))
      .then(() => history.push('/sign_up/verify'))
      .catch((error) => dispatch(setErrorAlertMessageSucceeded(error)))
      .finally(() => setLoading(false))
  }

  const handleSubmit = (data) => {
    return dispatch(signUp(data))
      .then(() => {
        return Storage.get({ key: 'locale' })
      })
      .then(({ value }) => {
        return dispatch(updateCurrentDevice({ locale: value }))
      })
      .catch((e) => {
        if (/Email has already been taken/.test(e.response.body.message)) {
          e.response.body.message = t('alert_email_taken')
        }
        dispatch(setErrorAlertMessageSucceeded(e))

        throw e
      })
  }

  const signUpWithFacebook = async () => {
    setLoading(true)

    const permissions = ['public_profile', 'email']

    try {
      const result = await FacebookLogin.login({ permissions })

      if (result && result.accessToken) {
        const { userId, token } = result.accessToken

        const url = `https://graph.facebook.com/${userId}?fields=first_name,last_name,email,picture.width(500).height(500)&type=large&access_token=${token}`

        const response = await fetch(url)
        const userData = await response.json()

        await dispatch(
          signIn('/oauth', {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            remote_avatar_url: userData.picture.data.url,
            omniauth_identities_attributes: {
              uid: userId,
              provider: 'facebook',
              token
            }
          })
        )

        console.log('facebook login')
        setLoading(false)
        FacebookLogin.logout()
        history.push('/')
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.warn('Facebook Login Error:', e)
      setLoading(false)
    }
  }

  const signUpWithLine = async () => {
    setLoading(true)

    try {
      const { email, pictureURL, displayName, userID } = await LineLogin.login()
      const { accessToken } = await LineLogin.getAccessToken()

      await dispatch(
        signIn('/oauth', {
          first_name: displayName,
          email,
          remote_avatar_url: pictureURL,
          omniauth_identities_attributes: {
            uid: userID,
            provider: 'line',
            token: accessToken
          }
        })
      )

      setLoading(false)
      history.push('/')
    } catch (e) {
      console.warn('Line Login Error:', e)
      setLoading(false)
    }
  }

  const signUpWithApple = async () => {
    setLoading(true)

    try {
      const { response } = await SignInWithApple.Authorize()

      // TODO:
      // cache givenName, familyName, email in localStorage by lookup with repsonse.user

      await dispatch(
        signIn('/oauth', {
          first_name: response.givenName,
          last_name: response.familyName,
          email: response.email,
          omniauth_identities_attributes: {
            uid: response.user,
            provider: 'apple',
            token: response.identityToken
          }
        })
      )

      setLoading(false)
      history.push('/')
    } catch (e) {
      console.warn('Apple Login Error:', e)
      setLoading(false)
    }
  }

  return (
    <PageLayout withSafeAreaTop className='sign-up-page-core-layout'>
      <PageContent className='sign-up-page'>
        <IonGrid className='grid-container'>
          <IonCol>
            <IonRow className='sign-up-header-section'>
              <IonRow className='header-text'>
                <LHeader>{t('welcome')}</LHeader>
              </IonRow>
              <IonRow className='header-logo'>
                <TenantLogoHeader url={tenant.get('dark_logo_url')} />
              </IonRow>
            </IonRow>
            <MediumParagraph>{t('create_your_account')}</MediumParagraph>
            <MediumParagraph>{t('by_method')}</MediumParagraph>
            <div className='sign-up-send-otp-form'>
              <SendOtpForm
                defaultNumber=''
                t={t}
                useBigButton
                buttonText={t('continue_sms')}
                onSubmit={(phoneNumber) => handleSmsRegistration(phoneNumber)}
                p2={t('sign_up_to_verify')}
                placeholder={t('input_placeholder')}
              />
            </div>
            <div className='sign-up-social-login-buttons'>
              <IonButton
                type='button'
                className='line-login-button'
                expand='block'
                onClick={signUpWithLine}
              >
                <img
                  className='line-login-button__icon'
                  src='/assets/img/line_logo.png'
                  alt='Line Logo'
                />
                <IonText className='line-login-button__text'>
                  {t('sign_up_with_line')}
                </IonText>
              </IonButton>
              <IonButton
                type='button'
                className='facebook-sign-in-button'
                expand='block'
                onClick={signUpWithFacebook}
              >
                <IonIcon
                  className='facebook-sign-in-button__icon'
                  slot='start'
                  icon={logoFacebook}
                />
                <IonText className='facebook-sign-in-button__text'>
                  {t('sign_up_with_facebook')}
                </IonText>
              </IonButton>
              {(isDev ||
                (isPlatform('ios') && parseInt(deviceInfo.osVersion, 10) >= 13)) && (
                <IonButton
                  type='button'
                  className='apple-sign-in-button'
                  expand='block'
                  onClick={signUpWithApple}
                >
                  <img
                    className='apple-sign-in-button__icon'
                    src='/assets/img/apple-logo-dark.png'
                    alt='Apple Logo'
                  />
                  <IonText className='apple-sign-in-button__text'>
                    {t('sign_up_with_apple')}
                  </IonText>
                </IonButton>
              )}
            </div>
            {showRegisterByEmailForm && (
              <>
                <div className='button-divider'>
                  <div className='line' />
                  <IonText className='text' color='medium'>
                    {t('or')}
                  </IonText>
                  <div className='line' />
                </div>
                <SignUpForm onSubmit={handleSubmit} push={history.push} />
              </>
            )}
            <div className='policy-paragraph'>
              <Paragraph>
                <span className='blue-underline' onClick={() => openPolicyModal('terms')}>
                  {t('terms_part')}
                </span>{' '}
                <span>{t('and_part')}</span>{' '}
                <span
                  className='blue-underline'
                  onClick={() => openPolicyModal('privacy')}
                >
                  {t('policy_part')}
                </span>
              </Paragraph>
            </div>
            <IonRow className='sign-up-sign-in-row'>
              <SmallParagraph>
                <span>{t('sign_in_before')}</span>{' '}
                <IonRouterLink routerLink='/sign_in'>
                  <span className='blue-underline bold'>{t('sign_in_after')}</span>
                </IonRouterLink>
              </SmallParagraph>
            </IonRow>
          </IonCol>
        </IonGrid>
      </PageContent>
      {/* <PrivacyModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        acceptCondition={() => setTermsAccepted(true)}
        dissmissCondition={() => setTermsAccepted(false)}
      /> */}
      <PolicyTermsModal />

      {loading && <Spinner overlaid />}
    </PageLayout>
  )
}

SignUpPage.propTypes = {
  history: PropTypes.object
}

export default SignUpPage
