import React from 'react'
import {
  IonCard, IonCardHeader, IonCardSubtitle,
  IonCardTitle, IonCardContent
} from '@ionic/react'
import moment from 'moment'

import './CalendarCard.scss'

const CalendarCard = ({ date, onRemoveCard }) => {
  const formatDate = moment(`${date.month}-${date.day}-${date.year}`, 'MM-DD-YYYY')

  return (
    <>
      <img
        className='close-icon'
        src='/assets/img/close_icon@2x.png'
        alt='Clock'
        onClick={() => onRemoveCard(date)}
      />

      <IonCard className='calendar-card'>
        <IonCardHeader className='card-header-container'>
          <IonCardSubtitle className='center-text-subtitle'>{formatDate.format('dddd')}</IonCardSubtitle>
          <IonCardTitle className='center-text-title'>{date.day}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent className='center-text-content'>
          {formatDate.format('MMM')} {date.year}
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default CalendarCard
