import { useState, useRef, useEffect, useCallback } from 'react'
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { Geolocation } from '@capacitor/geolocation'
import { useHistory } from 'react-router-dom'
import uuid4 from 'uuid/v4'

export const useEventId = () => {
  const [eventId, setEventId] = useState(null)

  const generateEventId = () => {
    setEventId(uuid4())
  }

  return [eventId, generateEventId]
}

export const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useCurrentLocation = () => {
  const [{ latitude, longitude }, setCurrentPosition] = useState({})

  useEffect(() => {
    const getCurrentLocation = async () => {
      const currentPosition = await Geolocation.getCurrentPosition()

      setCurrentPosition({
        latitude: currentPosition.coords.latitude,
        longitude: currentPosition.coords.longitude
      })
    }

    getCurrentLocation()
  }, [])

  return { latitude, longitude }
}

export const useJobTabs = (defaultTab = 'details') => {
  const { location } = useHistory()
  const [currentTab, setTab] = useState(defaultTab)

  useIonViewWillEnter(() => {
    if (location.state && location.state.tab) {
      setTab(location.state.tab)
    }
  })

  useIonViewWillLeave(() => {
    setTab('details')
  })

  return [currentTab, setTab]
}

export const useHookWithRefCallback = () => {
  const ref = useRef(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRef = useCallback((node) => {
    if (node) {
    }

    ref.current = node
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return [ref, setRef] // eslint-disable-line react-hooks/exhaustive-deps
}
