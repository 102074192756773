import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { useHistory } from 'react-router-dom'
import {
  IonItemGroup,
  IonItemDivider,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react'

import JobCard from 'components/JobCard'
import { DateFormatter } from 'components/formatters'

import './JobHistoryList.scss'

const JobHistoryList = ({ data }) => {
  const { push } = useHistory()

  return (
    <IonList style={{ marginBottom: 0 }}>
      {
        data.map(group => (
          <IonItemGroup key={group.get('start_time')}>
            <IonItemDivider className='group-header' color='secondary' sticky>
              <IonLabel className='group-label'>
                <DateFormatter
                  value={group.get('start_time')}
                  format='DD MMMM YYYY'
                />
              </IonLabel>
            </IonItemDivider>

            {
              group.get('job_providers').map(jobProvider => {
                const job = jobProvider.get('job') || Map()

                return (
                  <IonItem
                    key={jobProvider.get('id')}
                    button
                    onClick={() => push(`/jobs/history/${jobProvider.get('id')}`)}
                    detail={false}
                    lines='full'
                    className='list-item'
                  >
                    <JobCard
                      type={job.get('type')}
                      jobNumber={job.get('number')}
                      serviceName={job.getIn(['service', 'name'])}
                      serviceIconUrl={job.getIn(['service', 'icon_url'])}
                      startTime={job.get('start_time')}
                      endTime={job.get('end_time')}
                      address={job.getIn(['location', 'masked_address'])}
                      footer={{ state: jobProvider.get('state') }}
                      hasLogistics={job.get('route')}
                      route={job.get('route')}
                      id={jobProvider.get('id')}
                      ratingLink={jobProvider.get('rating_link')}
                      isHistoryPage
                    />
                  </IonItem>
                )
              })
            }
          </IonItemGroup>
        ))
      }
    </IonList>
  )
}

JobHistoryList.propTypes = {
  data: ImmutablePropTypes.list
}

export default JobHistoryList
