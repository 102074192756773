import React, { useRef, useState } from 'react'
import { IonButton, IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { bankAccountSchema } from '@seekster/schemas'

import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'

import BankAccountForm from 'forms/BankAccountForm'

import { useResourceActions, useResource } from 'hooks/resources'
import Spinner from 'components/Spinner'

const BankAccountEditPage = ({ history, match }) => {
  const { t } = useTranslation(['settings', 'bank_account'])
  const submitButtonRef = useRef()
  const { update: updateBankAccount, loading: updating } = useResourceActions(bankAccountSchema)
  const [showUpdateSucceededAlert, setShowUpdateSucceededAlert] = useState(false)

  const [bankAccount, { loading }] = useResource(
    `/bank_accounts/${match.params.id}`,
    bankAccountSchema,
    { id: match.params.id }
  )

  const handleSubmit = (data) => {
    return updateBankAccount(`/bank_accounts/${match.params.id}`, data)
      .then(() => {
        setShowUpdateSucceededAlert(true)
      })
      .catch(() => alert('ERROR BANK ACCOUNT'))
  }

  const handleDismissAlert = () => {
    setShowUpdateSucceededAlert(false)

    history.push('/settings/my_bank_account')
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('edit_bank_account')}
        withBackButton
        backTo='/settings/my_bank_account'
      />

      <PageContent className='bank-account-new-page' loading={loading}>
        <BankAccountForm
          bankAccount={bankAccount}
          onSubmit={handleSubmit}
          submitButtonRef={submitButtonRef}
        />

        <IonAlert
          isOpen={showUpdateSucceededAlert}
          onDidDismiss={handleDismissAlert}
          message={t('profile_updated')}
          buttons={[t('okay')]}
        />
      </PageContent>

      <PageFooter>
        <IonButton
          expand='block'
          onClick={() => {
            submitButtonRef.current.click()
          }}
          disabled={updating}
        >
          {t('save')}
        </IonButton>
      </PageFooter>

      {updating && <Spinner overlaid />}
    </PageLayout>
  )
}

export default BankAccountEditPage
