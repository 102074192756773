import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IonSelect, IonSelectOption, IonList, IonImg, IonThumbnail,
  IonItemGroup, IonItemDivider, IonLabel, IonItem
} from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { regionSchema, categorySchema } from '@seekster/schemas'

import { useCollection } from 'hooks/collections'
import { clearCollectionData } from 'actions/collections'
import Spinner from 'components/Spinner'

import './ServiceSelect.scss'

const ServiceSelect = ({ value, onChange }) => {
  const { t } = useTranslation('sign_up')
  const dispatch = useDispatch()

  const [regions] = useCollection('/regions', regionSchema)

  const defaultRegionId = regions.first() && regions.first().get('id')

  const [regionId, setRegionId] = useState(defaultRegionId)

  useEffect(() => {
    dispatch(clearCollectionData(categorySchema))
  }, [dispatch, regionId])

  const [categories, { loading }] = useCollection('/categories', categorySchema, {
    query: { region_id: regionId }
  })

  const handleSelectRegion = (e) => {
    setRegionId(e.target.value)

    onChange && onChange([])
  }

  const handleClickServiceCard = (serviceId) => {
    let newValue

    if (value.includes(serviceId)) {
      newValue = value.filter(id => id !== serviceId)
    }
    else {
      newValue = [...value, serviceId]
    }

    onChange && onChange(newValue)
  }

  return (
    <>
      <IonLabel color='primary' className='region-select-field-label'>
        {t('fields.label.please_select_region')}
      </IonLabel>

      <div className='region-select-container'>
        <IonSelect
          placeholder={t('fields.placeholder.region')}
          value={regionId || (regions.first() && regions.first().get('id'))}
          onIonChange={handleSelectRegion}
          okText={t('okay')}
          cancelText={t('cancel')}
          interfaceOptions={{ header: t('fields.label.please_select_region') }}
        >
          {
            regions.map(region => (
              <IonSelectOption key={region.get('id')} value={region.get('id')}>
                {region.get('name')}
              </IonSelectOption>
            ))
          }
        </IonSelect>
      </div>

      <IonLabel color='primary' className='services-select-field-label'>
        {t('fields.label.please_select_services')}
      </IonLabel>

      {
        loading ? (
          <Spinner />
        ) : (
          <IonList>
            {
              categories.map(category => (
                <IonItemGroup key={category.get('id')}>
                  <IonItemDivider>
                    <IonLabel className='category-name-label'>
                      {category.get('name')}
                    </IonLabel>
                  </IonItemDivider>

                  <div className='service-list-container'>
                    {
                      category.get('services').map(service => {
                        const isSelected = value.includes(service.get('id'))

                        return (
                          <IonItem
                            className='service-card-container'
                            key={service.get('id')}
                            button
                            lines='none'
                            detail={false}
                            onClick={() => handleClickServiceCard(service.get('id'))}
                          >
                            <IonThumbnail className='service-card-thumbnail'>
                              <IonImg
                                src={service.get('thumbnail_url')}
                                className={clsx(
                                  'service-card-img',
                                  isSelected && 'service-card-img--selected'
                                )}
                              />
                            </IonThumbnail>

                            <IonLabel
                              position='stacked'
                              className='service-name-label'
                              color={isSelected && 'secondary'}
                            >
                              {service.get('name')}
                            </IonLabel>
                          </IonItem>
                        )
                      })
                    }
                  </div>
                </IonItemGroup>
              ))
            }
          </IonList>
        )
      }
    </>
  )
}

ServiceSelect.defaultProps = {
  value: []
}

ServiceSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
}

export default ServiceSelect
