import { Storage } from '@capacitor/storage'

import {
  LOAD_BASIC_AUTH_SUCCEEDED,
  LOAD_LOCAL_DATA_SUCCEEDED,
  SET_LOCAL_DATA_SUCCEEDED
} from 'constants/localStorage'
import {
  CLEAR_TEAM_DATA_SUCCEEDED,
  LOAD_TEAM_DATA_SUCCEEDED
} from 'constants/teamDistribution'

export const loadLocalData = (key) => (dispatch) => {
  return Storage.get({ key }).then(({ value }) => {
    if (value) {
      // TO_DO -> support object data

      dispatch({
        type: LOAD_LOCAL_DATA_SUCCEEDED,
        response: { [key]: value }
      })
    }
  })
}

export const loadTeamData = (key) => (dispatch) => {
  return Storage.get({ key }).then(({ value }) => {
    if (value) {
      const paylord = typeof value === 'string' ? JSON.parse(value) : value
      dispatch({
        type: LOAD_TEAM_DATA_SUCCEEDED,
        payload: { [key]: paylord }
      })
    }
  })
}

export const clearTeamData = (key) => (dispatch) => {
  return Storage.remove({ key }).then(() => {
    dispatch({
      type: CLEAR_TEAM_DATA_SUCCEEDED
    })
  })
}

export const setLocalData = (key, value) => (dispatch) => {
  return Storage.set({ key, value }).then(() => {
    dispatch({
      type: SET_LOCAL_DATA_SUCCEEDED,
      response: { [key]: value }
    })
  })
}

export const setBasicAuthentication = (data) => (dispatch) => {
  return Storage.set({ key: 'basicAuth', value: JSON.stringify(data) }).then(() => {
    return dispatch({
      type: LOAD_BASIC_AUTH_SUCCEEDED,
      response: data
    })
  })
}

export const loadBasicAuthentication = () => (dispatch) => {
  return Storage.get({ key: 'basicAuth' }).then(({ value }) => {
    let basicAuth = {}

    if (value) {
      basicAuth = JSON.parse(value)
    } else {
      basicAuth = {
        clientAccessKey: process.env.REACT_APP_SEEKSTER_ACCESS_KEY,
        clientSecretKey: process.env.REACT_APP_SEEKSTER_SECRET_KEY
      }
    }

    return dispatch({
      type: LOAD_BASIC_AUTH_SUCCEEDED,
      response: basicAuth
    })
  })
}
