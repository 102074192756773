import React from 'react'
import { Map } from 'immutable'
import { Form } from 'formik'
import moment from 'moment'

import BaseField from 'components/fields/BaseField'
import BankAccountSelect from 'components/inputs/BankAccountSelect'
import { useTranslation } from 'react-i18next'

import './WalletWithdrawalForm.scss'

const Remark = ({ feeDescription, remark }) => {
  return (
    <>
      <p className='withdrawal-remark--grey'>{feeDescription}</p>
      <p className='withdrawal-remark--red'>{remark}</p>
    </>
  )
}

const DefaultRemark = ({ tenantSlug, upcomingPayoutCycle: cycle, feeAmountThb }) => {
  const { t } = useTranslation('wallet')
  const payoutDate = moment(cycle.get('payout_date')).format('DD MMMM YYYY')
  const cycleEndDate = moment(cycle.get('cycle_end_date', '').split('T')[0]).format('DD MMMM')
  const cycleEndTime = cycle.get('cycle_end_time', '').slice(0, 5)

  if (tenantSlug === 'seekster') {
    return (
      <>
        <label className='withdrawal-remark__label--grey'>
          {t('withdrawal_form.commission_label', { amount: feeAmountThb })}
        </label>
        <p className='withdrawal-remark--grey'>
          {
            t('withdrawal_form.commission_description', {
              amount: feeAmountThb,
              payoutDate
            })
          }
        </p>
        <p className='withdrawal-remark--red'>
          {
            t('withdrawal_form.remark_description', {
              payoutDate,
              cycleEndDate,
              cycleEndTime
            })
          }
        </p>
      </>
    )
  }
  else {
    return null
  }
}

const WalletWithdrawalForm = ({
  tenantSlug,
  availableBalance,
  upcomingPayoutCycle,
  feeAmountThb,
  feeDescription,
  remark,
  submitButtonRef
}) => {
  const { t } = useTranslation('wallet')

  return (
    <Form className='wallet-withdrawal-form'>
      <div>
        <div className='wallet-withdrawal-form__title'>
          {t('withdrawal_form.withdraw_from')}
          <img src='/assets/img/ic_wallet_grey.png' alt='Wallet' />
          {t('withdrawal_form.your_wallet')}
        </div>

        <p className='wallet-withdrawal-form__balance'>
          {t('withdrawal_form.available_balance')}
          <span>{availableBalance.get('display_value')}</span>
        </p>

        <BaseField
          name='bank_account_id'
          component={BankAccountSelect}
          label={t('withdrawal_form.to')}
        />
        <BaseField
          name='amount_satangs'
          type='tel'
          label={t('withdrawal_form.amount')}
        />

        {
          feeDescription && remark ? (
            <Remark
              feeDescription={feeDescription}
              remark={remark}
            />
          ) : (
            <DefaultRemark
              tenantSlug={tenantSlug}
              upcomingPayoutCycle={upcomingPayoutCycle}
              feeAmountThb={feeAmountThb}
            />
          )
        }
      </div>

      <input
        ref={submitButtonRef}
        type='submit'
        style={{ display: 'none' }}
      />
    </Form>
  )
}

WalletWithdrawalForm.defaultProps = {
  availableBalance: Map(),
  upcomingPayoutCycle: Map()
}

export default WalletWithdrawalForm
