import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { PageLayout, PageHeader, PageContent } from 'components/layout'
import ImageSlide from 'components/ImageSlide'

const PhotosPage = ({ location, history }) => {
  const { t } = useTranslation('jobs')

  const getUrlsFromRouterState = () => {
    if (location.state && location.state.urls) {
      return location.state.urls
    }
    else {
      return []
    }
  }

  const handleBack = () => {
    const pathNodes = location.pathname.split('/')
    const leafNode = [...pathNodes].reverse()[0]

    if (leafNode === 'report_photos') {
      const backToPath = pathNodes.slice(0, pathNodes.length - 1).join('/')

      history.replace(backToPath, { tab: 'report' })
    }
    else {
      history.goBack()
    }
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('photos')}
        withBackButton
        onBack={handleBack}
      />

      <PageContent>
        <ImageSlide
          urls={getUrlsFromRouterState()}
          slideOptions={{ initialSlide: location.state && location.state.initialSlide }}
        />
      </PageContent>
    </PageLayout>
  )
}

PhotosPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default PhotosPage
