export const generateOffsetCoordinate = (originalCoord, circleRadius) => {
  const a = Math.random() * 2 * Math.PI
  const r = circleRadius * Math.sqrt(Math.random())

  const offsetX = r * Math.cos(a)
  const offsetLng = offsetX / (111111 * Math.cos(originalCoord.longitude))

  const offsetY = r * Math.sin(a)
  const offsetLat = offsetY / 111111

  const latitude = originalCoord.latitude + offsetLat
  const longitude = originalCoord.longitude + offsetLng

  return { latitude, longitude }
}

export default generateOffsetCoordinate
