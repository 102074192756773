import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { alertCircle, checkmarkCircle } from 'ionicons/icons'

import BaseField from 'components/fields/BaseField'
import CameraInputBasic from 'components/inputs/CameraInputBasic'
import RadioInput from 'components/inputs/RadioInput'
import DatePicker from 'components/inputs/DatePicker'

import './ProfileForm.scss'

import Input from 'components/inputs/Input'

const ProfileForm = ({ submitButtonRef, data, handleEditPhone }) => {
  const { t } = useTranslation(['sign_up', 'verify_phone_number'])
  const isVerified = data.get('verified')

  return (
    <Form className='profile-form-container'>
      <BaseField
        name='avatar'
        component={CameraInputBasic}
        initialValue={data.get('avatar_url')}
        noLabel
      />

      <BaseField
        name='gender'
        component={RadioInput}
        options={[
          { value: 'male', label: t('male') },
          { value: 'female', label: t('female') }
        ]}
      />

      <BaseField name='first_name' required />

      <BaseField name='last_name' required />

      <BaseField
        name='phone_number'
        type='tel'
        required
        verified={isVerified}
        endLabel={
          <IonRouterLink routerLink='/verification/generate'>
            <span className='edit-phone' onClick={handleEditPhone}>
              {isVerified ? t('fields.edit_phonenumber') : t('fields.verify_phonenumber')}
            </span>
          </IonRouterLink>
        }
        render={({ form, field }) => (
          <Input disabled {...form} {...field}>
            <IonIcon
              className={`${isVerified ? 'verified' : ''}`}
              icon={isVerified ? checkmarkCircle : alertCircle}
            />
          </Input>
        )}
      />

      <BaseField
        name='date_of_birth'
        render={({ form, field }) => {
          return (
            <DatePicker
              {...form}
              {...field}
              onChange={(value) =>
                form.setFieldValue(field.name, moment(value).format('YYYY-MM-DD'))
              }
            />
          )
        }}
      />

      <BaseField name='email' readonly disabled />

      <input ref={submitButtonRef} type='submit' style={{ display: 'none' }} />
    </Form>
  )
}

ProfileForm.propTypes = {
  submitButtonRef: PropTypes.object,
  data: ImmutablePropTypes.map,
  handleEditPhone: PropTypes.func
}

export default ProfileForm
