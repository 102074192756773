import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IonAlert, IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import {
  walletTransactionSchema,
  walletSchema,
  bankAccountSchema,
  manualPayrollIntegrationSchema,
  accountingConfigurationSchema,
  tenantSchema,
  integrationInstanceSchema
} from '@seekster/schemas'

import { useResource, useResourceActions } from 'hooks/resources'
import { clearCollectionData } from 'actions/collections'
import { getCurrentIdAttribute } from 'selectors/authentication'

import {
  PageLayout,
  PageHeader,
  PageContent,
  PageFooter
} from 'components/layout'
import WalletWithdrawalForm from 'forms/WalletWithdrawalForm'
import Spinner from 'components/Spinner'
import { useCollection } from 'hooks/collections'

const WalletWithdrawalPage = ({ history }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('wallet')
  const submitButtonRef = useRef()
  const [showAlert, setShowAlert] = useState(false)
  const [withdrawalData, setWithdrawalData] = useState()

  const tenantSlug = useSelector(state => getCurrentIdAttribute(state, tenantSchema))

  const [
    wallet,
    {
      loading: loadingWallet
    }
  ] = useResource('/wallet', walletSchema, { implicit: true })

  const [
    manualPayrollIntegration,
    {
      loading: loadingManualPayrollIntegration
    }
  ] = useResource('/manual_payroll_integration', manualPayrollIntegrationSchema, { implicit: true })

  const [
    accountingConfiguration,
    {
      loading: loadingAccountingConfiguration
    }
  ] = useResource('/tenant/accounting_configuration', accountingConfigurationSchema, { implicit: true })

  const [
    payrollIntegration,
    {
      loading: loadingPayrollIntegration
    }
  ] = useResource('/integrations/payroll/integration_instance', integrationInstanceSchema, { implicit: true })

  const [
    bankAccounts,
    {
      loading: loadingBankAccounts
    }
  ] = useCollection('/bank_accounts', bankAccountSchema)

  const {
    create: withdraw,
    loading: withdrawing
  } = useResourceActions(walletSchema)

  const handleWithdraw = () => {
    withdraw('/wallet/withdraw', {
      amount_satangs: withdrawalData.amount_satangs * 100,
      bank_account_id: withdrawalData.bank_account_id
    })
      .then(() => {
        dispatch(clearCollectionData(walletTransactionSchema))
        history.push('/settings/wallet', { fetch: true })
      })
      .catch((error) => console.log(error))
  }

  const handleSubmit = (data) => {
    setWithdrawalData(data)
    setShowAlert(true)
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('withdrawal')}
        withBackButton
        backTo='/settings/wallet'
      />

      <PageContent>
        <WalletWithdrawalForm
          onSubmit={handleSubmit}
          defaultBankAccountId={bankAccounts.first()?.get('id')}
          tenantSlug={tenantSlug}
          availableBalance={wallet.get('available_balance')}
          upcomingPayoutCycle={accountingConfiguration.get('upcoming_payout_cycle')}
          feeAmountThb={payrollIntegration.getIn(['definition', 'fee'])}
          feeDescription={manualPayrollIntegration.get('fee_field')}
          remark={manualPayrollIntegration.get('remark_field')}
          submitButtonRef={submitButtonRef}
          t={t}
        />

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={t('withdrawal_confirmation')}
          buttons={[{
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: t('confirm'),
            handler: handleWithdraw
          }]}
        />
      </PageContent>

      <PageFooter>
        <IonButton
          expand='block'
          onClick={() => {
            submitButtonRef.current.click()
          }}
        >
          {t('withdraw')}
        </IonButton>
      </PageFooter>

      {
        (loadingWallet ||
        loadingManualPayrollIntegration ||
        loadingAccountingConfiguration ||
        loadingPayrollIntegration ||
        loadingBankAccounts ||
        withdrawing) && <Spinner overlaid />
      }
    </PageLayout>
  )
}

export default WalletWithdrawalPage
