import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  IonLabel,
  IonAvatar,
  IonButton,
  IonCol,
  IonImg,
  IonItem,
  IonList,
  IonRow,
  IonText
} from '@ionic/react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Map } from 'immutable'
import { jobSchema, commentSchema } from '@seekster/schemas'
import { useTranslation } from 'react-i18next'

import TeamJobCommentModal from 'components/modals/TeamJobCommentModal'
import { fetchChildren } from 'hooks/collections'

import './JobComment.scss'

const JobComment = (props) => {
  const { job = Map() } = props
  const [comments, setComments] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [isExpandComment, setIsExpandComment] = useState(false)
  const { t } = useTranslation('team_lead')

  const dispatch = useDispatch()

  useEffect(() => {
    fetchComment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, isModalOpen])

  const fetchComment = async () => {
    const { body: _comments } = await dispatch(
      fetchChildren(jobSchema, job.get('id'), commentSchema)
    )
    setComments(_comments)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleExpandComment = () => {
    setIsExpandComment(true)
  }

  return (
    <>
      <TeamJobCommentModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        jobId={job.get('id')}
        comments={comments}
      />
      <IonRow className='comment-row comment'>
        <IonCol>
          <IonText color='primary'>
            <h5>
              <strong>{t('comment')}</strong>
            </h5>
          </IonText>
          {comments.length < 1 ? (
            <div className='mock-textarea' onClick={handleOpenModal}>
              {t('add_comment')}
            </div>
          ) : (
            <IonList>
              {isExpandComment ? (
                comments.map((comment) => (
                  <IonItem
                    key={comment.id}
                    lines='none'
                    style={{
                      border: '1px solid #CECECE66',
                      borderRadius: '5px',
                      marginTop: '10px'
                    }}
                  >
                    <IonAvatar slot='start'>
                      <IonImg src='assets/img/default_avatar.png' alt='' />
                    </IonAvatar>
                    <IonLabel>
                      <h2>{comment.provider?.display_name}</h2>
                      <p>{moment(comment.created_at).format('D MMM YYYY, HH:mm')}</p>
                      <div className='ion-text-wrap'>{comment.content}</div>
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonItem
                  key={comments[0].id}
                  lines='none'
                  style={{
                    border: '1px solid #CECECE66',
                    borderRadius: '5px',
                    marginTop: '10px'
                  }}
                >
                  <IonAvatar slot='start'>
                    <IonImg src='assets/img/default_avatar.png' alt='' />
                  </IonAvatar>
                  <IonLabel>
                    <h2>{comments[0].provider?.display_name}</h2>
                    <p>{moment(comments[0].created_at).format('D MMM YYYY, HH:mm')}</p>
                    <div className='ion-text-wrap'>{comments[0].content}</div>
                  </IonLabel>
                </IonItem>
              )}
            </IonList>
          )}
          <IonRow className='comment-btn-box'>
            <IonCol>
              <IonButton onClick={handleOpenModal}>{t('add_comment')}</IonButton>
            </IonCol>
            {comments.length - 1 > 0 && !isExpandComment && (
              <IonCol
                className='ion-text-right expand-comment-text'
                onClick={handleExpandComment}
              >
                +{comments.length - 1} {t('comment')}
              </IonCol>
            )}
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

JobComment.propTypes = {
  job: PropTypes.object
}

export default JobComment
