import { getAccessToken, getBasicAuth } from 'selectors/authentication'
import request from 'utils/request'
import {
  ENTER_MODAL,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  INVALIDATE_VERIFICATION_SUCCESS,
  INVALIDATE_VERIFICATION_FAIL
} from 'constants/phone_verification'

export const generateOtp =
  (number, countryCode, locale = 'th') =>
  async (dispatch, getState) => {
    const phoneNumber = number || null
    try {
      const result = request
        .auth(getBasicAuth(getState()))
        .post(`/verifications/generate_otp?locale=${locale}`)
        .authentication(getAccessToken(getState()))
        .send({ phone_number: phoneNumber })

      dispatch({
        type: GENERATE_OTP_SUCCESS,
        response: { phoneNumber, countryCode, otpRequestedOn: new Date() }
      })

      return result
    } catch (error) {
      dispatch({
        type: GENERATE_OTP_FAIL
      })
      throw error
    }
  }

export const invalidateVerification = () => async (dispatch, getState) => {
  try {
    const result = request
      .auth(getBasicAuth(getState()))
      .put('/verifications/invalidate')
      .authentication(getAccessToken(getState()))
      .send()

    dispatch({
      type: INVALIDATE_VERIFICATION_SUCCESS
    })

    return result
  } catch (error) {
    dispatch({
      type: INVALIDATE_VERIFICATION_FAIL
    })
    throw error
  }
}

export const verifyOtp =
  (otp, locale = 'th') =>
  async (dispatch, getState) => {
    try {
      const result = request
        .auth(getBasicAuth(getState()))
        .put(`/verifications/verify?locale=${locale}`)
        .authentication(getAccessToken(getState()))
        .send({ otp_code: otp })

      dispatch({
        type: VERIFY_OTP_SUCCESS
      })

      return result
    } catch (error) {
      dispatch({
        type: VERIFY_OTP_FAIL
      })
      throw error
    }
  }

export const enterModal = (dispatch, payload) => {
  dispatch({
    type: ENTER_MODAL,
    response: payload
  })
}
