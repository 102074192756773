import React from 'react'
import './Subheader.scss'
import PropTypes from 'prop-types'

const Subheader = ({ isInfo, children, white }) => {
  return (
    <h4
      className={`typo-subheader ${white && 'white'}`}
      style={
        isInfo && {
          color: '#2D95F4'
        }
      }
    >
      {children}
    </h4>
  )
}

Subheader.propTypes = {
  isInfo: PropTypes.bool,
  children: PropTypes.string
}

export default Subheader
