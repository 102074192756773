import React from 'react'
import { Provider } from 'react-redux'
import { IonApp, setupConfig } from '@ionic/react'

import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import './theme/variables.scss'
import './theme/theme.scss'

import store from './store'
import InitializedLayout from './InitializedLayout'
import { Toaster } from 'react-hot-toast'

setupConfig({ mode: 'ios' })

const App = () => {
  return (
    <Provider store={store}>
      <IonApp>
        <InitializedLayout />
        <Toaster
          containerClassName='app-toaster-container'
          className='app-toaster'
          containerStyle={{ bottom: '80px' }}
        />
      </IonApp>
    </Provider>
  )
}

export default App
