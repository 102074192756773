import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonRow,
  IonSearchbar,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react'

import ListSkeleton from 'components/ListSkeleton'
import { PageHeader, PageLayout } from 'components/layout'
import { useTeamDistribution } from 'hooks/teamDistribution'
import ProviderCard from '../ProviderCard'

import './AssignProviderPage.scss'

const AssignProviderPage = ({ match }) => {
  const { push } = useHistory()
  const [loading, setLoading] = useState(false)
  const { currentTeam, fetchTeam } = useTeamDistribution()
  const [members, setMembers] = useState([])

  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    fetchTeam(`/teams/${currentTeam?._id}/members`, {
      query: {
        sort: 'startTime:desc',
        search: search
      }
    }).then((res) => {
      setMembers(res.body)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentTeam])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = 'none'
  })

  useIonViewWillLeave(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = ''
  })

  const handleSelectProvider = (providerId) => {
    push(`${match.url}/${providerId}`)
  }

  return (
    <PageLayout className='assign-provider-page'>
      <PageHeader
        className='assign-provder-page___page-header'
        title='Assign Job'
        withBackButton
      >
        <IonSearchbar
          className='searchbar'
          mode='ios'
          placeholder='Search name or number '
          value={search}
          onIonChange={handleSearch}
          showClearButton='never'
          debounce={600}
        />
      </PageHeader>
      <IonContent>
        <IonGrid>
          {loading ? (
            <ListSkeleton animated />
          ) : (
            <IonRow>
              <IonCol>
                <IonList className='assign-provider-page___list'>
                  {members.map((member) => (
                    <ProviderCard
                      key={member._id}
                      member={member.member}
                      onClick={() => handleSelectProvider(member.member.wfUserId)}
                    />
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </PageLayout>
  )
}

export default AssignProviderPage
