import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react'

import './PopupCalendarCard.scss'

const PopupCalendarCard = ({ onRemoveCard, isActive, isDisabled }) => {
  return (
    <>
      <img
        className='pop-up-close-icon'
        src='/assets/img/close_icon@2x.png'
        alt='Clock'
        onClick={() => onRemoveCard()}
      />

      <IonCard className='pop-up-calendar-card'>
        <IonCardContent className='pop-up-center-text-content'>
          {isActive && 'กรุณาเลือกวันอื่น เนื่องจากคุณมีงานในวันดังกล่าว'}
          {isDisabled &&
            'ไม่สามารถเลือกวันนี้ได้ หากต้องการเลือกวันนี้กรุณาลบข้อมูลวันที่คุณเคยสร้างไว้ในช่วงเวลานี้หรือเลือกวันอื่น'}
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default PopupCalendarCard
