import React from 'react'
import { IonImg } from '@ionic/react'

import './TenantLogoHeader.scss'
const TenantLogoHeader = ({ url }) => {
  return (
    <div className='logo-container'>
      <IonImg className='tenant-logo' src={url} />
    </div>
  )
}

export default TenantLogoHeader
