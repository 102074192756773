import { denormalize } from 'normalizr'
import { Map } from 'immutable'
import reReselect from 're-reselect'

import getEntities from './entities'

export const getState = state => state.get('collections')

export const getSchemaState = reReselect(
  getState,
  (state, schema) => schema,
  (state, schema, cacheKey) => cacheKey,
  (collectionState, schema, cacheKey) => collectionState.get(cacheKey || schema._key) || Map()
)((state, schema, cacheKey) => cacheKey || schema._key)

export const getTotalPages = reReselect(
  getSchemaState,
  schemaState => Math.ceil(schemaState.get('total') / schemaState.get('perPage')) || 1
)((state, schema, cacheKey) => cacheKey || schema._key)

export const getTotalCount = reReselect(
  getSchemaState,
  schemaState => schemaState.get('total')
)((state, schema, cacheKey) => cacheKey || schema._key)

export const getCollectionIds = reReselect(
  getSchemaState,
  schemaState => schemaState.get('data')
)((state, schema, cacheKey) => cacheKey || schema._key)

export const getCollection = reReselect(
  getCollectionIds,
  getEntities,
  (state, schema) => schema,
  (ids, entities, schema) => denormalize(ids, [schema], entities)
)((state, schema, cacheKey) => cacheKey || schema._key)
