import { IonButton } from '@ionic/react'
import React from 'react'
import './BigButton.scss'

const BigButton = ({ children, ...rest }) => {
  return (
    <IonButton className='big-btn' expand='block' {...rest}>
      {children}
    </IonButton>
  )
}

export default BigButton
