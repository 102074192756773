import { withFormik } from 'formik'
import * as Yup from 'yup'
import { PASSWORD_MIN_CHARACTERS } from 'constants/formConstants'
import SignUpForm from './SignUpForm'

const formikConfig = {
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string()
      .required()
      .min(PASSWORD_MIN_CHARACTERS)
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values).then(() => {
      props.push('/sign_up/registering')
    })
  }
}

export default withFormik(formikConfig)(SignUpForm)
