import { Map } from 'immutable'
import { normalize } from 'normalizr'

import {
  FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  UPDATE_CURRENT_DEVICE_SUCCEEDED,
  SIGN_OUT
} from 'constants/authentication'
import { FETCH_COLLECTION_SUCCEEDED } from 'constants/collections'
import {
  CREATE_RESOURCE_SUCCEEDED,
  FETCH_RESOURCE_SUCCEEDED,
  UPDATE_RESOURCE_SUCCEEDED,
  DELETE_RESOURCE_SUCCEEDED
} from 'constants/resources'

import {
  CACHE_TICKET_ID as FIREBASE_CACHE_TICKET_ID,
  TICKET_SUCCEEDED as FIREBASE_TICKET_SUCCEEDED
} from 'constants/firebase/resources'

const initialState = Map()

const mergeStrategy = (oldEntity, newEntity) => {
  return Map.isMap(oldEntity)
    ? oldEntity.mergeWith(mergeStrategy, newEntity)
    : newEntity
}

const entitiesReducer = (
  state = initialState,
  { type, response, schema, payload, parentSchema, parentId }
) => {
  let normalizedData

  switch (type) {
    case FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED:
      normalizedData = normalize(response.body, schema)

      return state.mergeWith(mergeStrategy, normalizedData.entities)

    case FETCH_COLLECTION_SUCCEEDED:
      normalizedData = normalize(response.body, [schema])

      return state.mergeWith(mergeStrategy, normalizedData.entities)

    case FETCH_CURRENT_DEVICE_SUCCEEDED:
    case UPDATE_CURRENT_DEVICE_SUCCEEDED:
    case CREATE_RESOURCE_SUCCEEDED:
    case FETCH_RESOURCE_SUCCEEDED:
    case UPDATE_RESOURCE_SUCCEEDED:
    case DELETE_RESOURCE_SUCCEEDED:
      normalizedData = normalize(response.body, schema)

      return state.mergeWith(mergeStrategy, normalizedData.entities)

    case SIGN_OUT:
      return state.filter((_, entity) => entity === 'tenants')

    case FIREBASE_CACHE_TICKET_ID:
      return state.set('ticket', payload)

    case FIREBASE_TICKET_SUCCEEDED:
      return state.set('ticket', response.body.ticketId)

    default:
      return state
  }
}

export default entitiesReducer
