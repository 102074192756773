import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import './Countdown.scss'
import { useHistory, useLocation, useParams } from 'react-router'

// Time Rendering
// Time Rendering
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Redirect to '/jobs' path
    // window.location.href = '/jobs'
  }

  // Calculate total hours by including days
  const totalHours = days * 24 + hours

  // Format hours, minutes, and seconds to display two digits
  const formattedHours = totalHours.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  const formattedMinutes = minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  const formattedSeconds = seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })

  return (
    <div className='time-render-root'>
      <div className='hour-countdown'>
        <div>
          <div className='timer-label'>{formattedHours}</div>
          <div className='time-text'>ชั่วโมง</div>
        </div>
      </div>
      <div className='splitter'>
        <span>:</span>
      </div>
      <div className='minute-countdown'>
        <div>
          <div className='timer-label'>{formattedMinutes}</div>
          <div className='time-text'>นาที</div>
        </div>
      </div>
      <div className='splitter'>
        <span>:</span>
      </div>
      <div className='second-countdown'>
        <div>
          <div className='timer-label'>{formattedSeconds}</div>
          <div className='time-text'>วินาที</div>
        </div>
      </div>
    </div>
  )
}

// Main
function CustomCountdown(props) {
  const history = useHistory()
  const [completed, setCompleted] = useState(false)
  const { goBack } = props
  const location = useLocation()
  const currentPath = location.pathname
  const params = useParams()

  useEffect(() => {
    if (new Date() > new Date(props.time)) {
      if (currentPath === `/jobs/${params.id}`) {
        history.push('/jobs')
      }
    }
  }, [history, props.time])

  const styles = {
    backgroundColor: 'red',
    color: 'white',
    justifyContent: 'center',
    padding: '5px'
  }

  return (
    <div id='countdown' style={styles}>
      <div className='coundown-message'>
        งานนี้มีการเลื่อน วัน-เวลา ตอบรับงานภายในเวลา
      </div>
      <div className='time-container'>
        <div className='timer-label'>
          <Countdown
            date={props.time}
            renderer={(props) => renderer({ ...props, completed })}
            onComplete={() => setCompleted(true)}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomCountdown
