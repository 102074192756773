import React from 'react'
import PropTypes from 'prop-types'
import {
  IonContent,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react'
import { refresh } from 'ionicons/icons'

import Spinner from 'components/Spinner'

import './PageContent.scss'

const PageContent = ({ children, onRefresh, loading, ...rest }) => {
  const handleRefresh = (e) => {
    onRefresh()

    setTimeout(() => {
      e.detail.complete()
    }, 500)
  }

  return (
    <IonContent
      className='page-content-container'
      {...rest}
    >
      {
        onRefresh && (
          <IonRefresher
            slot='fixed'
            onIonRefresh={handleRefresh}
            className='page-refresher'
            // pullMin={45}
            pullMax={1000}
          >
            <IonRefresherContent
              pullingIcon={refresh}
              refreshingSpinner='crescent'
            />
          </IonRefresher>
        )
      }

      {
        loading ? <Spinner /> : children
      }
    </IonContent>
  )
}

PageContent.propTypes = {
  children: PropTypes.node,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool
}

export default PageContent
