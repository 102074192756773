import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'

import SignUpDetailsForm from './SignUpDetailsForm'

const formikConfig = {
  mapPropsToValues: ({ data = Map() }) => ({
    gender: data.get('gender') || 'female',
    first_name: data.get('first_name'),
    last_name: data.get('last_name'),
    email: data.get('email'),
    phone_number: data.get('phone_number')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    // email: Yup.string().email().required(),
    phone_number: Yup.string()
      .matches(/^0[23456789]\d{7,8}$/, 'Invalid phone number')
      .required()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(values)
      .then(() => {
        props.push('/sign_up/pending_approval')
      })
      .catch((error) => {
        alert(error.response.body.message)
        setSubmitting(false)
      })
  }
}

export default withFormik(formikConfig)(SignUpDetailsForm)
