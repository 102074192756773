import thunkMiddleware from 'redux-thunk'
import Raven from 'raven-js'
import createRavenMiddleware from 'raven-for-redux'
import { applyMiddleware, compose, createStore } from 'redux'

import rootReducer from 'reducers'

const enhancers = []

if (
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__())
}

Raven.config(process.env.REACT_APP_SENTRY_DSN).install()

const middlewares = [
  thunkMiddleware,
  createRavenMiddleware(Raven)
]

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewares),
    ...enhancers
  )
)

export default store
