import React, { useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { eye, eyeOff } from 'ionicons/icons'

import Input from 'components/inputs/Input'

const PasswordInput = (props) => {
  const [showPassword, toggleShowPassword] = useState(false)

  return (
    <Input {...props} type={showPassword ? 'text' : 'password'}>
      <IonButton
        slot='end'
        shape='round'
        fill='clear'
        onClick={() => toggleShowPassword(!showPassword)}
      >
        <IonIcon icon={showPassword ? eye : eyeOff} />
      </IonButton>
    </Input>
  )
}

export default PasswordInput
