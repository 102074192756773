import { IonRow, IonText, useIonViewWillEnter } from '@ionic/react'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import './Timer.scss'

const Timer = ({ pre, after, resetPre, resetAfter, onReset }) => {
  const Ref = useRef(null)
  const [timer, setTimer] = useState('00:00')

  useIonViewWillEnter(() => clearTimer(getDeadTime()))

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    return {
      total,
      minutes,
      seconds
    }
  }

  const startTimer = useCallback((e) => {
    const { total, minutes, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }, [])

  const clearTimer = useCallback(
    (e) => {
      setTimer('03:00')
      if (Ref.current) clearInterval(Ref.current)
      const id = setInterval(() => {
        startTimer(e)
      }, 1000)
      Ref.current = id
    },
    [startTimer]
  )

  const onClickReset = () => {
    if (timer === '00:00') {
      clearTimer(getDeadTime())
      onReset()
    }
  }

  const getDeadTime = () => {
    let deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + 180)
    return deadline
  }

  useEffect(() => {
    clearTimer(getDeadTime())

    return () => {
      Ref.current = false
    }
  }, [clearTimer])

  return (
    <div className='timer'>
      <IonRow className='timer-row'>
        <IonText>
          {pre} {timer} {after}
        </IonText>
      </IonRow>
      <IonRow className='reset-row'>
        <IonText className='pre'>{resetPre}</IonText>
        <IonText
          className={`after ${timer !== '00:00' && 'disabled'}`}
          onClick={() => onClickReset()}
        >
          {resetAfter}
        </IonText>
      </IonRow>
    </div>
  )
}
Timer.propTypes = {
  pre: PropTypes.string,
  after: PropTypes.string,
  resetPre: PropTypes.string,
  resetAfter: PropTypes.string,
  onReset: PropTypes.func
}

export default Timer
