import React, { useEffect, useState } from 'react'
import { PushNotifications } from '@capacitor/push-notifications'
import { IonChip, IonLabel } from '@ionic/react'
import { conversationSchema, messageSchema } from '@seekster/schemas'
import moment from 'moment'

import { useCollection } from 'hooks/collections'

import { RelativeTimeIntevalFormatter } from 'components/formatters'
import Spinner from 'components/Spinner'

import groupByStartDate from 'utils/groupByStartDate'

import './ConversationBody.scss'

const ConversationBody = ({ conversationId, latestMessage }) => {
  const messagesEndRef = React.createRef()
  const [newMessageId, setNewMessageId] = useState(latestMessage)
  const [messages, { loading }] = useCollection(
    `/conversations/${conversationId}/messages`,
    messageSchema,
    {
      cacheKey: conversationId,
      parentSchema: conversationSchema,
      reload: latestMessage || newMessageId
    }
  )

  const scrollToBottom = () => {
    messagesEndRef.current !== null && messagesEndRef.current.scrollIntoView()
  }

  useEffect(scrollToBottom, [messagesEndRef])

  useEffect(() => {
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      setNewMessageId(notification.data.id)
    })
  })

  return (
    <div
      style={{
        height: 'calc(100vh - 120px)',
        width: '100%',
        padding: '1rem 1rem 0 1rem',
        overflow: 'auto'
      }}
    >
      <ul>
        {loading ? (
          <Spinner />
        ) : (
          groupByStartDate(messages, messageSchema, ['created_at']).map((group, i) => (
            <React.Fragment key={i}>
              <div className='chip-container'>
                <IonChip>
                  <IonLabel>
                    <RelativeTimeIntevalFormatter value={group.get('created_at')} />
                  </IonLabel>
                </IonChip>
              </div>

              {
                // eslint-disable-next-line array-callback-return
                group &&
                  group
                    .get('messages')
                    .sort()
                    // eslint-disable-next-line array-callback-return
                    .map((message, index) => {
                      const senderType = message.getIn(['sender', 'type'])
                      if (senderType === 'providers') {
                        return (
                          <li key={index} className='container-provider'>
                            <div className='conversation-timstamp'>
                              <p>{moment(message.get('created_at')).format('HH:mm')}</p>
                            </div>
                            <div className='conversation-content provider'>
                              {message.get('image_url') ? (
                                <img src={message.get('image_url')} alt='upload' />
                              ) : (
                                <p>{message.get('content')}</p>
                              )}
                            </div>
                          </li>
                        )
                      } else if (senderType === 'customers') {
                        return (
                          <li key={index}>
                            <div className='conversation-content customer'>
                              {message.get('image_url') ? (
                                <img src={message.get('image_url')} alt='upload' />
                              ) : (
                                <p>{message.get('content')}</p>
                              )}
                            </div>
                            <div className='conversation-timstamp'>
                              <p>{moment(message.get('created_at')).format('HH:mm')}</p>
                            </div>
                          </li>
                        )
                      }
                    })
              }
            </React.Fragment>
          ))
        )}
        <div ref={messagesEndRef} />
      </ul>
    </div>
  )
}

export default ConversationBody
