import { Device } from '@capacitor/device'
import { Storage } from '@capacitor/storage'
import jsonToFormData from 'json-form-data'
import { tenantSchema, deviceSchema } from '@seekster/schemas'

import request from 'utils/request'
import { getBasicAuth, getAccessToken } from 'selectors/authentication'
import {
  SIGN_IN_SUCCEEDED,
  SIGN_UP_SUCCEEDED,
  RESET_PASSWORD_SUCCEEDED,
  SIGN_OUT,
  FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  UPDATE_CURRENT_DEVICE_SUCCEEDED,
  UPDATE_PASSWORD_SUCCEEDED,
  INITIATE_PHONE_AUTH_SUCCEEDED,
  INITIATE_PHONE_AUTH_FAILED
} from 'constants/authentication'
import { GENERATE_OTP_SUCCESS } from 'constants/phone_verification'

const signInSucceeded = (response) => ({
  type: SIGN_IN_SUCCEEDED,
  response
})

export const signUpSucceeded = (response) => ({
  type: SIGN_UP_SUCCEEDED,
  response
})

const resetPasswordSucceeded = (response) => ({
  type: RESET_PASSWORD_SUCCEEDED,
  response
})

const updatePasswordSucceeded = (response) => ({
  type: UPDATE_PASSWORD_SUCCEEDED,
  response
})

const beginSignOut = () => ({
  type: SIGN_OUT
})

const fetchTenantWithBasicAuthSucceeded = (response, schema) => ({
  type: FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED,
  response,
  schema
})

const fetchCurrentDeviceSucceeded = (response, schema) => ({
  type: FETCH_CURRENT_DEVICE_SUCCEEDED,
  response,
  schema
})

const updateCurrentDeviceSucceeded = (response, schema) => ({
  type: UPDATE_CURRENT_DEVICE_SUCCEEDED,
  response,
  schema
})

const withDevicesAttributes = async (data) => {
  const info = await Device.getInfo()
  const language = await Storage.get({ key: 'locale' })
  const { identifier: deviceId } = await Device.getId()

  return {
    ...data,
    devices_attributes: {
      locale: language.value,
      os_version: info.osVersion,
      client_version: info.appVersion,
      brand: info.manufacturer,
      model: info.model,
      uuid: deviceId
    }
  }
}

export const signUp =
  (data, referrerId = null) =>
  async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes(data)

    const formData = jsonToFormData(dataWithDevicesAttributes, {
      showLeafArrayIndexes: false
    })

    const requestBuilder = request
      .auth(...getBasicAuth(getState()))
      .post(referrerId ? `/register?referrer_id=${referrerId}` : '/register')

    for (const [key, value] of formData.entries()) {
      requestBuilder.field(key, value)
    }
    return requestBuilder
      .then((response) => {
        Storage.set({
          key: 'accessToken',
          value: response.body.access_token
        })

        dispatch(signUpSucceeded(response))

        return response
      })
      .catch((error) => {
        throw error
      })
  }

export const signIn =
  (endpoint, data = false) =>
  async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes(data)

    const formData = jsonToFormData(dataWithDevicesAttributes, {
      showLeafArrayIndexes: false
    })

    const requestBuilder = request.auth(...getBasicAuth(getState())).post(endpoint)

    for (const [key, value] of formData.entries()) {
      requestBuilder.field(key, value)
    }

    return requestBuilder
      .then((response) => {
        Storage.set({
          key: 'accessToken',
          value: response.body.access_token
        })

        dispatch(signInSucceeded(response))

        return response
      })
      .catch((error) => {
        throw error
      })
  }

export const signOut = () => async (dispatch, getState) => {
  await Promise.all([
    Storage.remove({ key: 'accessToken' }).then(() => dispatch(beginSignOut())),
    Storage.remove({ key: 'tdAccessToken' })
  ])

  return request
    .auth(...getBasicAuth(getState()))
    .del('/sign_out')
    .authentication(getAccessToken(getState()))
}

export const forgotPassword =
  (data, options = {}) =>
  (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .post('/reset_instructions')
      .send(data)
      .then((response) => {
        dispatch(resetPasswordSucceeded(response, options))
        return response
      })
  }

export const updatePassword =
  (data, options = {}) =>
  async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes(data)

    return request
      .auth(...getBasicAuth(getState()))
      .put('/update_password')
      .send(dataWithDevicesAttributes)
      .then((response) => {
        dispatch(updatePasswordSucceeded(response, options))
        return response
      })
  }

export const fetchTenantWithBasicAuth = () => (dispatch, getState) => {
  return request
    .auth(...getBasicAuth(getState()))
    .get('/tenant')
    .then((response) => {
      dispatch(fetchTenantWithBasicAuthSucceeded(response, tenantSchema))

      return response
    })
    .catch((error) => {
      throw error
    })
}

export const fetchCurrentDevice = () => (dispatch, getState) => {
  return request
    .auth(...getBasicAuth(getState()))
    .get('/device')
    .authentication(getAccessToken(getState()))
    .then((response) => {
      dispatch(fetchCurrentDeviceSucceeded(response, deviceSchema))

      return response
    })
    .catch((error) => {
      throw error
    })
}

export const updateCurrentDevice = (data) => async (dispatch, getState) => {
  const info = await Device.getInfo()
  const language = await Storage.get({ key: 'locale' })

  const value = {
    ...data,
    locale: language.value,
    os_version: info.osVersion,
    client_version: info.appVersion,
    brand: info.manufacturer,
    model: info.model,
    uuid: info.uuid
  }

  const formData = jsonToFormData(value, {
    showLeafArrayIndexes: false
  })

  const requestBuilder = request
    .auth(...getBasicAuth(getState()))
    .patch('/device')
    .authentication(getAccessToken(getState()))

  for (const [key, value] of formData.entries()) {
    requestBuilder.field(key, value)
  }

  return requestBuilder.then((response) => {
    dispatch(updateCurrentDeviceSucceeded(response, deviceSchema))

    return response
  })
}

export const fetchCustomToken = () => (dispatch, getState) => {
  return request
    .get(process.env.REACT_APP_FIREBASE_FUNCTION_CUSTOM_TOKEN_URL)
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .authentication(getAccessToken(getState()))
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

// =================== Phone authentication flows ======================

// This api call will either register a new blank user with the phone number
// or update a verification with a new otp code and expiry (so we can log in to it)
export const initiatiePhoneAuthentication =
  (phoneNumber) => async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes({
      phone_number: phoneNumber
    })

    return request
      .auth(...getBasicAuth(getState()))
      .post('/initiate_phone_authentication?locale=th')
      .send(dataWithDevicesAttributes)
      .then((response) => {
        dispatch({
          type: INITIATE_PHONE_AUTH_SUCCEEDED
        })
        dispatch({
          type: GENERATE_OTP_SUCCESS,
          response: { phoneNumber, countryCode: '+66', otpRequestedOn: new Date() }
        })
        return response
      })
      .catch((error) => {
        dispatch({
          type: INITIATE_PHONE_AUTH_FAILED
        })
        throw error
      })
  }

// Calls api to try to log in with phone number and password
// TODO: We might want to dispatch on error
export const signInWithPhoneNumberAndPassword =
  (phoneNumber, password) => async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes({
      phone_number: phoneNumber,
      password
    })
    return request
      .auth(...getBasicAuth(getState()))
      .post('/sign_in_with_phone_number_password?locale=th')
      .send(dataWithDevicesAttributes)
      .then((response) => {
        Storage.set({
          key: 'accessToken',
          value: response.body.access_token
        })

        dispatch(signInSucceeded(response))
        return response
      })
      .catch((error) => {
        throw error
      })
  }

// Use this function when we are sure there already is an account
// if an account is not found we will NOT create an account with this function
// TODO: We might want to dispatch on error
export const signInWithPhoneNumberSendOtp =
  (phoneNumber) => async (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .post('/sign_in_with_phone_number_send?locale=th')
      .send({ phone_number: phoneNumber })
      .then((response) => {
        dispatch({
          type: GENERATE_OTP_SUCCESS,
          response: { phoneNumber, countryCode: '+66', otpRequestedOn: new Date() }
        })
        return response
      })
      .catch((error) => {
        // dispatch here
        throw error
      })
  }

// Allows a user to sign in with otp, this function returns a device
// that we can save in state / localStorage to sign in.
// TODO: We might want to dispatch the result after calling the end point
export const signInWithPhoneNumberVerify =
  (phoneNumber, otpCode) => async (dispatch, getState) => {
    const dataWithDevicesAttributes = await withDevicesAttributes({
      phone_number: phoneNumber,
      otp_code: otpCode
    })

    return request
      .auth(...getBasicAuth(getState()))
      .post('/sign_in_with_phone_number_verify?locale=th')
      .send(dataWithDevicesAttributes)
      .then((response) => {
        // dispatch here
        return response
      })
      .catch((error) => {
        // dispatch here
        throw error
      })
  }

// This gets called automatically from backend when calling
// initiate phone authentication if a phone number has not been registered
// Currently this is unused (03-08-2022) and it might not be needed.
export const registerWithPhoneNumber = (phoneNumber) => async (dispatch, getState) => {
  const dataWithDevicesAttributes = await withDevicesAttributes({
    phone_number: phoneNumber
  })
  return request
    .auth(...getBasicAuth(getState()))
    .post('/register_with_phone_number?locale=th')
    .send(dataWithDevicesAttributes)
    .then((response) => {
      dispatch({
        type: GENERATE_OTP_SUCCESS,
        response: { phoneNumber, countryCode: '+66', otpRequestedOn: new Date() }
      })
      return response
    })
    .catch((error) => {
      // dispatch here
      throw error
    })
}
