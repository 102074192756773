import React from 'react'
import PropTypes from 'prop-types'
import { IonItemGroup, IonList } from '@ionic/react'

import './ReferralList.scss'
import ReferralCard from 'components/ReferralCard'

const ReferralList = ({ referrals }) => {
  return (
    <IonList>
      {referrals.map((referral) => (
        <IonItemGroup key={`referral-card-id-${referral.get('id')}`}>
          <ReferralCard referral={referral} />
        </IonItemGroup>
      ))}
    </IonList>
  )
}

ReferralList.propTypes = {
  referrals: PropTypes.object
}

export default ReferralList
