import React from 'react'
import PropTypes from 'prop-types'
import { IonRadioGroup, IonRadio, IonList, IonItem, IonLabel } from '@ionic/react'

import './RadioInput.scss'

const RadioInput = ({ value, onChange, options, required }) => {
  const handleChange = (e) => {
    onChange && onChange(e.target.value)
  }

  return (
    <IonList className='radio-input-list-container'>
      <IonRadioGroup
        value={value}
        onIonChange={handleChange}
      >
        {
          options.map(({ value, label }, i) => (
            <IonItem key={i} lines='none'>
              <IonLabel>{label}</IonLabel>

              <IonRadio slot='start' value={value} mode='md' />
            </IonItem>
          ))
        }

        <input
          required={required}
          value={value}
          style={{ opacity: 0, height: 0, position: 'absolute' }}
        />
      </IonRadioGroup>
    </IonList>
  )
}

RadioInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool
}

export default RadioInput
