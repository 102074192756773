import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { IonToast, IonLoading, IonIcon } from '@ionic/react'

import BaseField from 'components/fields/BaseField'
import GoogleStaticMap from 'components/GoogleStaticMap'
import { useCurrentLocation } from 'hooks/interfaces'
import { navigateCircleOutline } from 'ionicons/icons'

import './AddressForm.scss'

const AddressForm = ({ data, submitButtonRef, values, setFieldValue }) => {
  const { t } = useTranslation('addresses')
  const [{ latitude, longitude }] = useCurrentLocation()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const setToCurrentAddress = async () => {
    const geocoder = await new window.google.maps.Geocoder()
    const location = await new window.google.maps.LatLng(latitude, longitude)
    setShowLoading(true)

    geocoder.geocode({ latLng: location }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        results[0].address_components.forEach((component) => {
          if (component.types.includes('route')) {
            values.street = component.long_name
          } else if (component.types.includes('sublocality_level_1')) {
            values.district = component.long_name
          } else if (component.types.includes('administrative_area_level_1')) {
            values.province = component.long_name
          } else if (component.types.includes('sublocality_level_2')) {
            values.sub_district = component.long_name
          } else if (component.types.includes('postal_code')) {
            values.zip_code = component.long_name
          } else if (component.types.includes('street_number')) {
            values.alley = component.long_name
          }
        })

        values.latitude = latitude
        values.longitude = longitude

        setTimeout(function () {
          setShowLoading(false)
          setShowSuccessToast(true)
        }, 2000)
      } else {
        setTimeout(function () {
          setShowLoading(false)
          setShowErrorToast(true)
        }, 2000)
      }
    })
  }

  return (
    <Form className='address-form-container'>
      <div>
        <BaseField name='contact_name' required />

        <BaseField name='house_number' required />

        {values.latitude && values.longitude && (
          <div className='update-current-location-map-container'>
            <GoogleStaticMap
              center={{
                latitude: values.latitude,
                longitude: values.longitude
              }}
              marker='pin'
              size='small'
              scale={2}
              zoom={20}
              showExpandButton={false}
            />

            <div
              onClick={() => setToCurrentAddress()}
              className='update-current-location-btn'
            >
              <IonIcon
                slot='icon-only'
                icon={navigateCircleOutline}
                className='update-home-address-icon'
              />
              {t('update_home_location')}
            </div>
          </div>
        )}

        <input ref={submitButtonRef} type='submit' style={{ display: 'none' }} />

        <IonToast
          isOpen={showSuccessToast}
          onDidDismiss={() => setShowSuccessToast(false)}
          message={t('update_home_location_success')}
          duration={2000}
          color='dark'
        />
        <IonToast
          isOpen={showErrorToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={t('update_home_location_error')}
          duration={2000}
          color='dark'
        />
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message='Please wait...'
          duration={5000}
        />
      </div>
    </Form>
  )
}

AddressForm.propTypes = {
  data: ImmutablePropTypes.map,
  submitButtonRef: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object
}

export default AddressForm
