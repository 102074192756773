import React from 'react'
import { useTranslation } from 'react-i18next'

import { bankAccountSchema } from '@seekster/schemas'
import EmptyListPlaceholder from 'components/lists/EmptyListPlaceholder'

import { PageLayout, PageHeader, PageContent } from 'components/layout'
import { useCollection } from 'hooks/collections'

import BankAccountList from 'components/lists/BankAccountList'

const MyBankAccountPage = () => {
  const { t } = useTranslation('settings')
  const [
    bankAccounts,
    {
      loading: loadingBankAccounts
    }
  ] = useCollection('/bank_accounts', bankAccountSchema)

  return (
    <PageLayout>
      <PageHeader
        title={t('my_bank_account_page')}
        withBackButton
        backTo='/settings'
      />

      <PageContent loading={loadingBankAccounts}>
        {
          bankAccounts.isEmpty() ? (
            <EmptyListPlaceholder message={t('no_bank_account_message')} />
          ) : (
            <BankAccountList bankAccounts={bankAccounts} />
          )
        }
      </PageContent>
    </PageLayout>
  )
}

export default MyBankAccountPage
