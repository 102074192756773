import { IonContent, IonGrid } from '@ionic/react'
import { providerSchema } from '@seekster/schemas'
import { triggerResourceWithData } from 'actions/resources'
import { PageHeader, PageLayout } from 'components/layout'
import Header from 'components/typography/Header/Header'
import Paragraph from 'components/typography/Paragraph'
import Subheader from 'components/typography/Subheader'
import providerState from 'constants/providerState'
import SetPasswordForm from 'forms/SetPasswordForm'
import { useResource } from 'hooks/resources'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import './SignUpSetPasswordPage.scss'
const SignUpSetPasswordPage = () => {
  const { t } = useTranslation(['sign_up', 'fields'])
  const [provider] = useResource('/provider', providerSchema, { implicit: true })

  const dispatch = useDispatch()
  const handleSubmit = async (values) => {
    try {
      dispatch(
        triggerResourceWithData('/provider/set_password', providerSchema, {
          password: values.new_password
        })
      )
      if (provider.get('state') === providerState.registering)
        return push('/sign_up/registering')
      return push('/sign_in')
    } catch (ex) {
      console.log(ex)
    }
  }

  const { push } = useHistory()
  const loading = false
  return (
    <PageLayout>
      <PageHeader title={t('set_password.title')} withBackButton backTo={'/sign_up'} />
      <IonContent>
        <IonGrid className='sign-up-set-password-container'>
          <div className='text-container'>
            <div className='subheader-container'>
              <Subheader>{t('set_password.subheader')}</Subheader>
            </div>
            <div className='header-container'>
              <Header>{t('set_password.header')}</Header>
            </div>
            <div className='paragraph-container'>
              <Paragraph>{t('set_password.paragraph')}</Paragraph>
            </div>
          </div>
          <SetPasswordForm onSubmit={handleSubmit} push={push} loading={loading} t={t} />
        </IonGrid>
      </IonContent>
    </PageLayout>
  )
}

export default SignUpSetPasswordPage
