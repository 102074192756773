import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonRow } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarCard from 'components/AvatarCard'
import { providerSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'
import SendOtpForm from 'forms/SendOtpForm'
import { useEnterModal } from 'hooks/phone_verification'
import TenantLogoHeader from 'components/TenantLogoHeader'
import Header from 'components/typography/Header/Header'
import Paragraph from 'components/typography/Paragraph'
import { PageLayout, PageHeader } from 'components/layout'
import { usePhoneVerification } from 'hooks/phone_verification'

import { tenantSchema } from '@seekster/schemas'

import './VerifyGeneratePage.scss'
import { useSelector } from 'react-redux'
import { getCurrentResource } from 'selectors/authentication'
import { useHistory } from 'react-router-dom'

const VerifyGeneratePage = () => {
  const { t, i18n } = useTranslation(['verify_phone_number'])
  const [showInput, setShowInput] = useState(false)
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const { push } = useHistory()
  const { enteredFrom } = useEnterModal()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const enteredFromSettingsPage = enteredFrom === '/settings/profile'
  const { genOtp } = usePhoneVerification()

  const handleGenerateOtp = async (otp) => {
    await genOtp(otp, '+66')
    push('/verification/verify')
  }
  useEffect(() => {
    if (enteredFromSettingsPage) setShowInput(true)
  }, [enteredFrom, enteredFromSettingsPage])
  return (
    <PageLayout>
      <PageHeader title={'Verify phone number'} withBackButton backTo={enteredFrom} />
      <IonContent className='verify-info-container'>
        <IonGrid className='verify-info-grid'>
          <IonRow className='verify-info-logo'>
            <TenantLogoHeader url={tenant.get('dark_logo_url')} />
          </IonRow>
          <IonRow className='verify-info-header'>
            <Header>{t('info.header')}</Header>
          </IonRow>
          <IonRow className='verify-info-paragraph'>
            <Paragraph>{t('info.paragraph', { tenant: tenant.get('name') })}</Paragraph>
          </IonRow>
          {!showInput ? (
            <IonRow className='verify-info-picture-row'>
              {provider && (
                <IonRow className='avatar-row'>
                  <AvatarCard provider={provider} />
                </IonRow>
              )}
              <IonImg
                src='/assets/img/mobile_verify.png'
                className='verify-info-picture'
              />
            </IonRow>
          ) : (
            <IonRow
              className={showInput ? 'verify-info-form' : 'verify-info-form-hidden'}
            >
              {provider && (
                <SendOtpForm
                  defaultNumber={provider.get('phone_number') || ''}
                  buttonText={t('info.submit_button')}
                  p={t('info.small_paragraph')}
                  p2={t('info.small_paragraph2')}
                  t={t}
                  locale={i18n.language}
                  verified={provider.get('verified')}
                  useBigButton
                  enteredFromSettingsPage={enteredFromSettingsPage}
                  onSubmit={(otp) => handleGenerateOtp(otp)}
                />
              )}
            </IonRow>
          )}

          <IonRow className='verify-info-btn'>
            {!showInput && (
              <IonCol>
                <IonButton expand='block' onClick={() => setShowInput(!showInput)}>
                  {t('info.button')}
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </PageLayout>
  )
}

export default VerifyGeneratePage
