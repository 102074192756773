import { withFormik } from 'formik'

import ChatForm from './ChatForm'

const formikConfig = {
  enableReinitialize: true,

  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(`/conversations/${values.conversationId}/messages`, values)

    resetForm({ type: 'text', value: '' })
  }
}

export default withFormik(formikConfig)(ChatForm)
