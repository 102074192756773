import jsonToFormData from 'json-form-data'

import request from 'utils/request'
import { getBasicAuth, getAccessToken } from 'selectors/authentication'
import {
  CREATE_RESOURCE_SUCCEEDED,
  FETCH_RESOURCE_SUCCEEDED,
  UPDATE_RESOURCE_SUCCEEDED,
  DELETE_RESOURCE_SUCCEEDED
} from 'constants/resources'
import { SET_ERROR_ALERT_MESSAGE_SUCCEEDED } from 'constants/interfaces'

const createResourceSucceeded = (response, schema, { parentSchema, parentId }) => ({
  type: CREATE_RESOURCE_SUCCEEDED,
  response,
  schema,
  parentSchema,
  parentId
})

const fetchResourceSucceeded = (response, schema) => ({
  type: FETCH_RESOURCE_SUCCEEDED,
  response,
  schema
})

const updateResourceSucceeded = (response, schema) => ({
  type: UPDATE_RESOURCE_SUCCEEDED,
  response,
  schema
})

const deleteResourceSucceeded = (response, schema) => ({
  type: DELETE_RESOURCE_SUCCEEDED,
  response,
  schema
})
export const setErrorAlertMessageSucceeded = (error) => {
  // Error cases
  // 1. message
  // 2. error.response.body.message
  // 3. error.response.body.error
  const message =
    error.response && error.response.body
      ? error.response.body.message
      : 'Internal Server Error!'

  return {
    type: SET_ERROR_ALERT_MESSAGE_SUCCEEDED,
    data: { message }
  }
}

export const createResource =
  (endpoint, schema, data, options = {}) =>
  (dispatch, getState) => {
    const formData = jsonToFormData(data, {
      showLeafArrayIndexes: false
    })

    const requestBuilder = request
      .auth(...getBasicAuth(getState()))
      .post(endpoint)
      .authentication(getAccessToken(getState()))

    for (const [key, value] of formData.entries()) {
      requestBuilder.field(key, value)
    }

    return requestBuilder
      .then((response) => {
        dispatch(createResourceSucceeded(response, schema, options))

        return response
      })
      .catch((error) => {
        dispatch(setErrorAlertMessageSucceeded(error))

        throw error
      })
  }

export const fetchResource =
  (endpoint, schema, options = {}) =>
  (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .get(endpoint)
      .authentication(getAccessToken(getState()))
      .then((response) => {
        dispatch(fetchResourceSucceeded(response, schema))

        return response
      })
      .catch((error) => {
        dispatch(setErrorAlertMessageSucceeded(error))

        throw error
      })
  }

  export const fetchFeedback =
  (endpoint, schema, options = {}) =>
  (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .get(endpoint)
      .authentication(getAccessToken(getState()))
      .then((response) => {
        // dispatch(fetchResourceSucceeded(response, schema))
        return response
      })
      .catch((error) => {
        // dispatch(setErrorAlertMessageSucceeded(error))
        throw error
      })
  }

export const deletedResource =
  (endpoint, schema, options = {}) =>
  (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .delete(endpoint)
      .authentication(getAccessToken(getState()))
      .then((response) => {
        dispatch(deleteResourceSucceeded(response, schema))

        return response
      })
      .catch((error) => {
        dispatch(setErrorAlertMessageSucceeded(error))

        throw error
      })
  }

export const updateResource =
  (endpoint, schema, data, options = {}) =>
  (dispatch, getState) => {
    const formData = jsonToFormData(data, {
      showLeafArrayIndexes: false
    })

    const requestBuilder = request
      .auth(...getBasicAuth(getState()))
      .patch(endpoint)
      .authentication(getAccessToken(getState()))

    for (const [key, value] of formData.entries()) {
      requestBuilder.field(key, value)
    }

    return requestBuilder
      .then((response) => {
        dispatch(updateResourceSucceeded(response, schema))

        return response
      })
      .catch((error) => {
        dispatch(setErrorAlertMessageSucceeded(error))

        throw error
      })
  }

export const triggerResource =
  (endpoint, schema, options = {}) =>
  (dispatch, getState) => {
    return request
      .auth(...getBasicAuth(getState()))
      .put(endpoint)
      .authentication(getAccessToken(getState()))
      .then((response) => {
        dispatch(updateResourceSucceeded(response, schema))

        return response
      })
      .catch((error) => {
        const expectMessage = [
          "Event 'accept' cannot transition from 'assigned'. ",
          "Event 'accept' cannot transition from 'accepted'. "
        ]

        const message = error.response.body
          ? expectMessage.includes(error.response.body.message)
            ? false
            : error.response.body.message
          : 'Internal Server Error!'

        if (message) {
          dispatch(setErrorAlertMessageSucceeded(error))
        }

        throw error
      })
  }

export const triggerResourceWithData =
  (endpoint, schema, data, options = {}) =>
  (dispatch, getState) => {
    const formData = jsonToFormData(data, {
      showLeafArrayIndexes: false
    })

    const requestBuilder = request
      .auth(...getBasicAuth(getState()))
      .put(endpoint)
      .authentication(getAccessToken(getState()))

    for (const [key, value] of formData.entries()) {
      requestBuilder.field(key, value)
    }

    return requestBuilder
      .then((response) => {
        dispatch(updateResourceSucceeded(response, schema))

        return response
      })
      .catch((error) => {
        const exceptMessage = ["Event 'complete' cannot transition from 'completed'. "]
        const message = error.response.body
          ? exceptMessage.includes(error.response.body.message)
            ? false
            : error.response.body.message
          : 'Internal Server Error!'

        if (message) {
          dispatch(setErrorAlertMessageSucceeded(error))
        }

        throw error
      })
  }
