import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import QrReader from 'react-qr-reader'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'

const UploadQRScanner = ({ onScan }) => {
  const { t } = useTranslation('switch_tenant')
  const reader = useRef(null)

  const handleError = (error) => {
    console.error('ERROR::', error)
  }

  const handleImgLoad = (e) => {
    console.log('handleImgLoad')
  }

  const handleOpenImgDialog = () => {
    reader.current.openImageDialog()
  }

  return (
    <div>
      <QrReader
        ref={reader}
        delay={1000}
        onError={handleError}
        onScan={onScan}
        style={{ width: '100%' }}
        legacyMode
        onImageLoad={handleImgLoad}
      />

      <IonButton expand='block' onClick={handleOpenImgDialog}>
        {t('upload_qr_code_or_take_a_picture')}
      </IonButton>
    </div>
  )
}

UploadQRScanner.propTypes = {
  onScan: PropTypes.func
}

export default UploadQRScanner
