import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { IonList, IonItem } from '@ionic/react'
import distanceDifferent from 'utils/distanceDifferent'

import MainJobCard from 'components/MainJobCard'

import './MainLeadList.scss'

const MainLeadList = ({
  data,
  isDistance,
  distanceEnabled,
  latitude,
  longitude,
  restDates,
  availableDates,
  availableDistance,
  maxLeads
}) => {
  const { push } = useHistory()

  function compare(a, b) {
    if (isDistance) {
      if (
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'latitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'longitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        ) <
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'latitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'longitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        )
      ) {
        return -1
      }
      if (
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'latitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'longitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        ) <
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'latitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'longitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        )
      ) {
        return 1
      }
      return 0
    }
  }
  const calculateJobWithinDistance = (
    providerLatitude,
    providerLongitude,
    leadLatitude,
    leadLongitude
  ) => {
    const distance = parseFloat(
      distanceDifferent(providerLatitude, providerLongitude, leadLatitude, leadLongitude)
    )
    if (availableDistance * 1000 < distance) {
      return false
    }
    return true
  }
  const filterLeads = (maxCount) => {
    const leads = []
    data.forEach((group) => {
      group
        .get('leads')
        .sort(compare)
        .forEach((lead) => {
          const jobIsWithinDistance = calculateJobWithinDistance(
            latitude,
            longitude,
            !lead.getIn(['inquiry', 'has_logistics'])
              ? lead.getIn(['inquiry', 'location', 'latitude'])
              : lead.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !lead.getIn(['inquiry', 'has_logistics'])
              ? lead.getIn(['inquiry', 'location', 'longitude'])
              : lead.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
          const leadIsPending = lead.getIn(['inquiry', 'state']) === 'pending_provider'
          if (leadIsPending && jobIsWithinDistance && leads.length < maxCount) {
            leads.push(lead)
          }
        })
    })
    return leads
  }
  const leads = filterLeads(maxLeads)
  return (
    <IonList>
      {leads.map((lead) => (
        <IonItem
          key={lead.get('id')}
          button
          onClick={() => push(`/leads/${lead.getIn(['inquiry', 'id'])}`)}
          detail={false}
          lines='none'
          className='main-list-item'
        >
          <MainJobCard
            type={lead.get('type')}
            jobNumber={lead.getIn(['inquiry', 'number'])}
            serviceName={lead.getIn(['inquiry', 'service', 'name'])}
            serviceIconUrl={lead.getIn(['inquiry', 'service', 'icon_url'])}
            chargeCustomerPrice={lead.getIn(['inquiry', 'price', 'display_value'])}
            payout={lead.getIn(['inquiry', 'payout', 'display_value'])}
            paymentMethod={lead.getIn(['inquiry', 'payment_method'])}
            startTime={lead.get('start_time')}
            restDates={restDates}
            availableDates={availableDates}
            endTime={lead.get('end_time')}
            address={lead.getIn(['inquiry', 'location', 'masked_address'])}
            state={lead.get('state')}
            preferredBy={lead.getIn(['inquiry', 'preferred_customer'])}
            hasLogistics={lead.getIn(['inquiry', 'has_logistics'])}
            route={lead.getIn(['inquiry', 'route'])}
            remark={lead.getIn(['inquiry', 'remarks_for_provider'])}
            distanceEnabled={latitude !== null && latitude !== null && distanceEnabled}
          />
        </IonItem>
      ))}
    </IonList>
  )
}

MainLeadList.propTypes = {
  data: ImmutablePropTypes.list,
  availableDates: ImmutablePropTypes.list,
  restDates: ImmutablePropTypes.list,
  isDistance: PropTypes.bool,
  distanceEnabled: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  availableDistance: PropTypes.number,
  maxLeads: PropTypes.number
}

export default MainLeadList
