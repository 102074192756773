import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { IonImg, IonButton } from '@ionic/react'

import generateOffsetCoordinate from 'utils/generateOffsetCoordinate'

import './GoogleStaticMap.scss'

const getCoordinates = (lat, lng, rad, detail = 4) => {
  let coordinates = ''

  const pi = Math.PI

  const _lat = (lat * pi) / 180
  const _lng = (lng * pi) / 180
  const d = rad / 1000 / 6371

  for (let i = 0; i <= 360; i += detail) {
    const brng = (i * pi) / 180

    let pLat = Math.asin(
      Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng)
    )
    const pLng =
      ((_lng +
        Math.atan2(
          Math.sin(brng) * Math.sin(d) * Math.cos(_lat),
          Math.cos(d) - Math.sin(_lat) * Math.sin(pLat)
        )) *
        180) /
      pi
    pLat = (pLat * 180) / pi

    coordinates += `|${pLat},${pLng}`
  }

  return coordinates
}

const GoogleStaticMap = ({ center, circleRadius, offSetMarkerCenter, marker, route, size = 'normal', scale = 1, zoom = 14.5, showExpandButton = true }) => {
  const [url, setUrl] = useState()
  const [currentLat, setCurrentLat] = useState()

  useEffect(() => {
    if (center.latitude !== undefined && currentLat !== center.latitude) {
      const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap?'

      const { latitude, longitude } = offSetMarkerCenter
        ? generateOffsetCoordinate(center, circleRadius)
        : center

      const getMarkerOption = (markerType) => {
        if (markerType === 'multiCircles') {
          return {
            markers: multiMarkersQuery(),
            path: multiAreasQuery()
          }
        } else if (markerType === 'circle') {
          return {
            path: `color:0x52a8f760|weight:3|fillcolor:0x52a8f730${getCoordinates(
              latitude,
              longitude,
              circleRadius
            )}`
          }
        } else if (markerType === 'pin') {
          const iconUrl =
            'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/icon/destination_marker.png'

          return {
            markers: `icon:${iconUrl}|size:${size}|scale:${scale}|${[latitude, longitude].join(',')}`
          }
        } else {
          return {}
        }
      }

      const queryParams = qs.stringify(
        {
          zoom: zoom,
          size: '800x430',
          scale: 2,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          center: [latitude, longitude].join(','),
          ...getMarkerOption(marker)
        },
        { arrayFormat: 'repeat' }
      )

      setUrl(baseUrl + queryParams)
      setCurrentLat(center.latitude)
    }
  }, [center]) // eslint-disable-line react-hooks/exhaustive-deps

  function multiMarkersQuery(language) {
    const destinationMaker =
      'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/delivery_th.png'
    const originMaker =
      'https://seekster-web.s3-ap-southeast-1.amazonaws.com/assets/images/pick_up_th.png'

    return [
      `icon:${originMaker}|${[
        route.getIn(['origin', 'latitude']) - 0.008,
        route.getIn(['origin', 'longitude'])
      ].join(',')}`,
      `icon:${destinationMaker}|${[
        route.getIn(['destination', 'latitude']) - 0.008,
        route.getIn(['destination', 'longitude'])
      ].join(',')}`
    ]
  }

  function multiAreasQuery() {
    return [
      `color:0x2D95F499|weight:3|fillcolor:0x2D95F430${getCoordinates(
        route.getIn(['origin', 'latitude']),
        route.getIn(['origin', 'longitude']),
        300
      )}`,
      `color:0xAB511E99|weight:3|fillcolor:0xAB511E30${getCoordinates(
        route.getIn(['destination', 'latitude']),
        route.getIn(['destination', 'longitude']),
        300
      )}`
    ]
  }

  return (
    <div className='static-map-container'>
      <IonImg src={url} style={{ width: '100%' }} alt='google-map' />
      {
        showExpandButton &&
          <IonButton expand='block' className='static-map-expand-icon'>
            ดูแผนที่
          </IonButton> 
      }
    </div>
  )
}

GoogleStaticMap.propTypes = {
  center: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  circleRadius: PropTypes.number,
  offSetMarkerCenter: PropTypes.bool,
  marker: PropTypes.oneOf(['circle', 'pin', 'multiCircles']),
  route: PropTypes.object,
  zoom: PropTypes.number,
  size: PropTypes.string,
  scale: PropTypes.number,
  showExpandButton: PropTypes.bool
}

GoogleStaticMap.defaultProps = {
  circleRadius: 500
}

export default GoogleStaticMap
