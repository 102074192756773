import React from 'react'
import PropTypes from 'prop-types'
import { IonToolbar, IonFooter } from '@ionic/react'

const PageFooter = ({ children, toolbarProps, ...rest }) => {
  return (
    <IonFooter {...rest}>
      <IonToolbar {...toolbarProps}>
        <div style={{ maxWidth: 768, margin: '0 auto' }}>
          {children}
        </div>
      </IonToolbar>
    </IonFooter>
  )
}

PageFooter.propTypes = {
  children: PropTypes.node,
  toolbarProps: PropTypes.object
}

export default PageFooter
