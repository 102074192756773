import {
  CLEAR_ERROR_ALERT_MESSAGE_SUCCEEDED,
  SET_CURRENT_LOCATION_SUCCEEDED
} from 'constants/interfaces'

export const clearErrorAlertMessage = () => (dispatch) => {
  return dispatch({
    type: CLEAR_ERROR_ALERT_MESSAGE_SUCCEEDED
  })
}

export const setCurrentLocation = (coordinates) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_LOCATION_SUCCEEDED,
    data: { coordinates }
  })
}
