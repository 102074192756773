import React from 'react'
import PropTypes from 'prop-types'
import { IonText } from '@ionic/react'

import './Segment.scss'

const Segment = ({ title, children, noPadding, chip }) => {
  return (
    <div className='segment-container'>
      {title && (
        <div className='segment-title'>
          <IonText className='title'>{title}</IonText>
          {chip}
        </div>
      )}
      <div className={!noPadding ? 'segment-content' : ''}>{children}</div>
    </div>
  )
}

Segment.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  chip: PropTypes.node,
  noPadding: PropTypes.bool
}

export default Segment
