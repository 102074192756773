const clientId = process.env.REACT_APP_LINE_CLIENT_ID
const clientSecret = process.env.REACT_APP_LINE_CLIENT_SECRET
const redirectUri = process.env.REACT_APP_APPLICATION_URL + '/sign_up_referral'

export const generateLineUrl = () => {
  const state = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 20)
  return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=profile%20openid%20email`
}

const getLineToken = async (authCode) => {
  const requestBody = {
    grant_type: 'authorization_code',
    code: authCode,
    redirect_uri: redirectUri,
    client_id: clientId,
    client_secret: clientSecret
  }
  const formBody = getFormBody(requestBody)
  try {
    const result = await fetch('https://api.line.me/oauth2/v2.1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    })
    const token = await result.json()
    return token
  } catch (ex) {
    throw ex
  }
}

const getFormBody = (objProps) => {
  let formBody = []
  for (var property in objProps) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(objProps[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }
  formBody = formBody.join('&')
  return formBody
}

const getUserFromIdToken = async (idToken) => {
  const requestBody = {
    id_token: idToken,
    client_id: process.env.REACT_APP_LINE_CLIENT_ID
  }
  const formBody = getFormBody(requestBody)
  try {
    const result = await fetch('https://api.line.me/oauth2/v2.1/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    })
    const jsonRes = await result.json()
    return jsonRes
  } catch (ex) {
    throw ex
  }
}
export const loginWithLine = async (code) => {
  try {
    const token = await getLineToken(code)
    const { id_token, access_token } = token
    const user = await getUserFromIdToken(id_token)
    return {
      user,
      access_token
    }
  } catch (ex) {
    throw ex
  }
}
