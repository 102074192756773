import React from 'react';
import { string, func, bool } from 'prop-types';
import {
  IonButton, IonContent, IonFooter, IonImg, IonModal,
} from "@ionic/react";
import Header from 'components/typography/Header/Header';
import Paragraph from 'components/typography/Paragraph';

import './NotificationModal.scss';
import clsx from 'clsx';

const NotificationModal = ({
  isOpen, title, description, imageUrl, onClose, buttonText,
}) => {

  const hasImage = Boolean(imageUrl);

  const onDismiss = (e) => {
    onClose();
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      className={clsx("notification-wrapper-modal", { 'has-image': hasImage })}
    >
      <IonContent >
        <div className="wrapper-content">
          {imageUrl && (
            <IonImg src={imageUrl} style={{ height: 178 }} alt="notification" className="notification-image" />
          )}
          <div className="description-wrapper-content">
            <Header>{title}</Header>
            <Paragraph>{description}</Paragraph>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className="wrapper-footer">
          <IonButton fill="outline" onClick={onDismiss}>
            {buttonText}
          </IonButton>
        </div>
      </IonFooter>
    </IonModal>
  )
};

export default NotificationModal;

NotificationModal.propTypes = {
  isOpen: bool,
  title: string.isRequired,
  description: string.isRequired,
  imageUrl: string.isRequired,
  buttonText: string,
  onClose: func,
};

NotificationModal.defaultProps = {
  isOpen: false,
  title: '',
  description: '',
  imageUrl: '',
  buttonText: 'Close',
  onClose: () => { },
};
