import React from 'react'
import PropTypes from 'prop-types'
import { IonLabel, IonText, IonItem } from '@ionic/react'
import { Field, ErrorMessage } from 'formik'
import Cleave from 'cleave.js/react'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'

import Input from 'components/inputs/Input'

import './BankAccountNumberField.scss'

const BankAccountNumberField = ({
  name,
  label,
  noLabel,
  required,
  placeholder,
  render,
  initialValue,
  noErrorMessagePlaceholder,
  component: Component,
  ...rest
}) => {
  const { t } = useTranslation('fields')

  const renderCleave = ({ field, form }) => {
    return (
      <IonItem
        className={clsx(
          'input-container',
          'input-container--basic',
          // Boolean(error) && 'input-container--error'
        )}
        lines='none'
        style={{ zIndex: 0 }}
      >
        <Cleave
          {...field}
          value={field.value || initialValue}
          placeholder={placeholder && t(`placeholder.${name}`)}
          options={{
            blocks: [3, 1, 5, 1],
            delimiters: ['-'],
            numericOnly: true
          }}
          {...rest}
        />
      </IonItem>
    )
  }

  return (
    <Field name={name}>
      {(renderProps) => (
        <>
          {
            !noLabel && (
              <IonLabel
                className='field-label'
                color='primary'
              >
                {label || t(`label.${name}`)}
                {
                  required && (
                    <IonText color='danger'> *</IonText>
                  )
                }
              </IonLabel>
            )
          }

          {renderCleave(renderProps)}

          <ErrorMessage
            name={name}
            render={(message) => (
              message
                ? <IonText color='danger'>
                    <p style={{ margin: 0 }}>{message}</p>
                  </IonText>
                : null
            )}
          />

          {
            !noErrorMessagePlaceholder && (
              <IonText className='error-message-placeholder'>
                <p>.</p>
              </IonText>
            )
          }

        </>
      )}
    </Field>
  )
}

BankAccountNumberField.defaultProps = {
  component: Input
}

BankAccountNumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.bool,
  initialValue: PropTypes.string,
  render: PropTypes.func,
  noErrorMessagePlaceholder: PropTypes.bool,
  component: PropTypes.elementType
}

export default BankAccountNumberField
