import { withFormik } from 'formik'
import * as Yup from 'yup'

import ResetPasswordForm from './ResetPasswordForm'

const formikConfig = {
  mapPropsToValues: ({ token }) => ({
    password: '',
    re_password: '',
    reset_password_token: token
  }),

  validationSchema: Yup.object().shape({
    password: Yup.string().min(8, 'Password has to be longer than 8 characters!')
      .required('password is required!'),
    re_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
      .then(() => {
        props.push('/confirm_reset_password')
      })
  }
}

export default withFormik(formikConfig)(ResetPasswordForm)
