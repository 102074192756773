import React from 'react'

import './LineLoginButton.scss'
import { IonButton, IonText } from '@ionic/react'

const LineLoginButton = ({ children, handleClick }) => {
  return (
    <IonButton className='line-login-button' expand='block' onClick={handleClick}>
      <img
        className='line-login-button__icon'
        src='/assets/img/line_logo.png'
        alt='Line Logo'
      />
      <IonText className='line-login-button__text'>{children}</IonText>
    </IonButton>
  )
}

export default LineLoginButton
