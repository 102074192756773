import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'

import BaseField from 'components/fields/BaseField'
import BankAccountNumberField from 'components/fields/BankAccountNumberField'
import BankSelect from 'components/inputs/BankSelect'
import { useTranslation } from 'react-i18next'

import './BankAccountForm.scss'

const BankAccountForm = ({ submitButtonRef }) => {
  const { t } = useTranslation('bank_account')

  return (
    <Form className='bank-account-form-container'>
      <div>
        <BaseField
          name='bank_id'
          component={BankSelect}
          label={t('bank_name')}
          required
        />

        <BankAccountNumberField
          name='account_number'
          style={{ border: 'none' }}
          type='tel'
          required
        />

        <BaseField
          name='account_name'
          required
        />

        <p className='annotation'>{t('new_bank_account_annotation')}</p>

        <input
          ref={submitButtonRef}
          type='submit'
          style={{ display: 'none' }}
        />
      </div>
    </Form>
  )
}

BankAccountForm.propTypes = {
  submitButtonRef: PropTypes.object
}

export default BankAccountForm
