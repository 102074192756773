import axios from 'axios'

const callService = axios.create()
const token = localStorage.getItem('CapacitorStorage.accessToken')

// Request interceptor for API calls
callService.interceptors.request.use(
  async (config) => {
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
      return config
    } else {
      window.location.href = '/sign_in'
    }
  },
  (error) => {
    Promise.reject(error)
  }
)

export { callService }
