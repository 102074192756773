import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { locate } from 'ionicons/icons'

const GoogleDynamicMap = ({ current, destination, marker, circleRadius, route }) => {
  const mapElementRef = useRef()
  const mapInstantRef = useRef()
  const mapInstant = mapInstantRef.current

  const hasCurrent = Boolean(current.latitude && current.longitude)
  const hasDestination = Boolean(destination.latitude && destination.longitude)

  useEffect(() => {
    const setupGoogleMap = async () => {
      const map = await new window.google.maps.Map(mapElementRef.current, {
        zoom: 12,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false
      })

      mapInstantRef.current = map

      // For trigger map tiles to load every time map is instantiated.
      mapElementRef.current.style.height = '99%'
      setTimeout(() => {
        mapElementRef.current.style.height = '100%'
      }, 600)
    }

    if (window.google && window.google.maps) {
      setupGoogleMap()
    }
  }, [])

  useEffect(() => {
    let currentLocationMarker = null
    let circleMarker = null
    let pinMarker = null
    let circleDestinationMarker = null
    let imageOriginMaker = null
    let imageDestinationMaker = null

    if (mapInstant && hasCurrent && hasDestination) {
      const bounds = new window.google.maps.LatLngBounds()

      if (marker === 'multiCircles') {
        bounds.extend({ lat: route.getIn(['origin', 'latitude']), lng: route.getIn(['origin', 'longitude']) })
        bounds.extend({ lat: destination.latitude, lng: destination.longitude })
        mapInstant.fitBounds(bounds)
      }
      else {
        bounds.extend({ lat: current.latitude, lng: current.longitude })
        bounds.extend({ lat: destination.latitude, lng: destination.longitude })
        mapInstant.fitBounds(bounds)
      }

      currentLocationMarker = new window.google.maps.Marker({
        position: {
          lat: current.latitude,
          lng: current.longitude
        },
        map: mapInstant,
        icon: 'assets/img/geolocation_marker.png'
      })

      if (marker === 'multiCircles') {
        const pickUpWindow = new window.google.maps.InfoWindow({ disableAutoPan: true, maxWidth: 200 })

        circleMarker = new window.google.maps.Circle({
          strokeColor: '#2D95F4',
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: '#2D95F4',
          fillOpacity: 0.3,
          map: mapInstant,
          center: { lat: route.getIn(['origin', 'latitude']), lng: route.getIn(['origin', 'longitude']) },
          radius: circleRadius
        })

        circleDestinationMarker = new window.google.maps.Circle({
          strokeColor: '#AB511E',
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: '#AB511E',
          fillOpacity: 0.3,
          map: mapInstant,
          center: { lat: destination.latitude, lng: destination.longitude },
          radius: circleRadius
        })

        imageDestinationMaker = new window.google.maps.Marker({
          position: {
            lat: destination.latitude - 0.01,
            lng: destination.longitude
          },
          map: mapInstant,
          icon: 'assets/img/delivery_th.png'
        })

        imageOriginMaker = new window.google.maps.Marker({
          position: { lat: route.getIn(['origin', 'latitude']) - 0.01, lng: route.getIn(['origin', 'longitude']) },
          map: mapInstant,
          icon: 'assets/img/pick_up_th.png'
        })

        pickUpWindow.setContent('circleMarker')
        pickUpWindow.open(mapInstant, circleMarker)

        pickUpWindow.setContent('circleDestinationMarker')
        pickUpWindow.open(mapInstant, circleDestinationMarker)

        // pickUpWindow.setContent('imageOriginMaker')
        // pickUpWindow.open(mapInstant, imageOriginMaker)

        // pickUpWindow.setContent('imageDestinationMaker')
        // pickUpWindow.open(mapInstant, imageDestinationMaker)

      }
      else if (marker === 'circle') {
        circleMarker = new window.google.maps.Circle({
          strokeColor: '#52a8f7',
          strokeOpacity: 0.6,
          strokeWeight: 3,
          fillColor: '#52a8f7',
          fillOpacity: 0.4,
          map: mapInstant,
          center: { lat: destination.latitude, lng: destination.longitude },
          radius: circleRadius
        })
      }
      else if (marker === 'pin') {
        pinMarker = new window.google.maps.Marker({
          position: {
            lat: destination.latitude,
            lng: destination.longitude
          },
          map: mapInstant,
          icon: 'assets/img/destination_marker.png'
        })
      }

      return () => {
        currentLocationMarker && currentLocationMarker.setMap(null)
        circleMarker && circleMarker.setMap(null)
        pinMarker && pinMarker.setMap(null)
        circleDestinationMarker && circleDestinationMarker.setMap(null)
        imageOriginMaker && imageOriginMaker.setMap(null)
        imageDestinationMaker && imageDestinationMaker.setMap(null)
      }
    }
  }, [
    hasCurrent,
    hasDestination,
    current.latitude,
    current.longitude,
    destination.latitude,
    destination.longitude,
    mapInstant,
    circleRadius,
    marker,
    route
  ])

  const handleSetCenter = () => {
    const smoothZoom = (map, max, cnt) => {
      if (cnt >= max) {
        return map.panTo({ lat: current.latitude, lng: current.longitude })
      }
      else {
        window.google.maps.event.addListenerOnce(map, 'zoom_changed', () => {
          smoothZoom(map, max, cnt + 1)
        })

        setTimeout(() => {
          map.setZoom(cnt)
        }, 80)
      }
    }

    if (mapInstant) {
      smoothZoom(mapInstant, 14, mapInstant.getZoom())
    }
  }

  return (
    <>
      <div ref={mapElementRef} style={{ height: '100%', width: '100%' }} id='map-canvas' />

      <IonFab vertical='bottom' horizontal='end' slot='fixed'>
        <IonFabButton color='light' onClick={handleSetCenter}>
          <IonIcon icon={locate} mode='md' />
        </IonFabButton>
      </IonFab>
    </>
  )
}

GoogleDynamicMap.propTypes = {
  current: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  destination: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  circleRadius: PropTypes.number,
  marker: PropTypes.oneOf(['multiCircles', 'circle', 'pin']),
  route: PropTypes.object
}

GoogleDynamicMap.defaultProps = {
  circleRadius: 500
}

export default GoogleDynamicMap
