import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { IonIcon, IonAvatar, isPlatform } from '@ionic/react'
import { create } from 'ionicons/icons'

import convertUriToImageFile from 'utils/convertUriToImageFile'

import './CameraInputBasic.scss'

defineCustomElements(window)

const CameraInputBasic = ({ value, onChange, cameraOptions }) => {
  const [previewUrl, setPreviewUrl] = useState(value)

  const takePhotoOrSelectFromGallery = async () => {
    try {
      const options = {
        quality: isPlatform('ios') ? 5 : 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        ...cameraOptions
      }

      const originalPhoto = await Camera.getPhoto(options)
      const path = originalPhoto.webPath
      const imageFile = await convertUriToImageFile(path)

      setPreviewUrl(path)

      onChange && onChange(imageFile)
    } catch (e) {
      console.error('ERROR', e)
    }
  }

  return (
    <div className='camera-input-basic__container'>
      <IonAvatar
        className='camera-input-basic__preview'
        onClick={takePhotoOrSelectFromGallery}
      >
        {previewUrl && <img src={previewUrl} alt='Camera Preview' />}
      </IonAvatar>

      <IonIcon
        className='camera-input-basic__edit-button'
        icon={create}
        mode='md'
        onClick={takePhotoOrSelectFromGallery}
      />
    </div>
  )
}

CameraInputBasic.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  cameraOptions: PropTypes.object
}

export default CameraInputBasic
