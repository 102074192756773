import React from 'react'
import './MediumParagraph.scss'
import PropTypes from 'prop-types'

const MediumParagraph = ({ children }) => {
  return <p className='typo-small-paragraph'>{children}</p>
}

MediumParagraph.propTypes = {
  children: PropTypes.string
}

export default MediumParagraph
