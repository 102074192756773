import { withFormik } from 'formik'
import * as Yup from 'yup'

import AddressForm from './AddressForm'

const formikConfig = {
  mapPropsToValues: ({ data = Map() }) => ({
    contact_name: data.getIn(['home_address', 'contact_name']),
    house_number: data.getIn(['home_address', 'house_number']),
    alley: data.getIn(['home_address', 'alley']),
    street: data.getIn(['home_address', 'street']),
    province: data.getIn(['home_address', 'province']),
    district: data.getIn(['home_address', 'district']),
    sub_district: data.getIn(['home_address', 'sub_district']),
    zip_code: data.getIn(['home_address', 'zip_code']),
    phone_number: data.get('phone_number'),
    latitude: data.getIn(['home_address', 'latitude']),
    longitude: data.getIn(['home_address', 'longitude']),
    id: data.getIn(['home_address', 'id']),
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    contact_name: Yup.string().required(),
    house_number: Yup.string().required(),
  }),

  handleSubmit: (values, { props }) => {
    const newValues = { home_address_attributes: values }
    props.onSubmit(newValues)
  }
}

export default withFormik(formikConfig)(AddressForm)
