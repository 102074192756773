import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'immutable'
import { isEqual } from 'lodash'

import {
  createResource,
  fetchResource,
  updateResource,
  triggerResource,
  triggerResourceWithData,
  deletedResource,
  fetchFeedback,
} from 'actions/resources'
import { getResource, getSchemaState } from 'selectors/resources'
import { getCurrentResource } from 'selectors/authentication'
import { usePrevious } from 'hooks/utils'

export const useResource = (endpoint, schema, options = {}) => {
  const prevOptions = usePrevious(options)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const resource =
    useSelector((state) => {
      if (options.id) {
        return getResource(state, options.id, schema)
      } else if (options.implicit) {
        const resource = getSchemaState(state, schema)

        return resource && resource.first()
      } else {
        return getCurrentResource(state, schema)
      }
    }) || Map()

  useEffect(() => {
    let isMounted = true

    if (!isEqual(prevOptions, options)) {
      setLoading(true)

      dispatch(fetchResource(endpoint, schema, {}))
        .then((response) => {
          if (isMounted) {
            setLoading(false)
          }
        })
        .catch((error) => {
          if (isMounted) {
            setError(error)

            setLoading(false)
          }
        })

      return () => (isMounted = false)
    } else {
      setLoading(false)
    }
  }, [dispatch, endpoint, schema, options, prevOptions])

  return [resource, { loading, error }]
}

export const useResourceActions = (schema) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  const actionBuilder = (thunkAction) => {
    return (endpoint, ...rest) => {
      setLoading(true)

      return dispatch(thunkAction(endpoint, schema, ...rest))
        .then((response) => {
          setLoading(false)

          return response
        })
        .catch((error) => {
          setLoading(false)
          setError(error)

          throw error
        })
    }
  }

  return {
    create: actionBuilder(createResource),
    fetch: actionBuilder(fetchResource),
    fetchFeedback: actionBuilder(fetchFeedback),
    update: actionBuilder(updateResource),
    deleted: actionBuilder(deletedResource),
    trigger: actionBuilder(triggerResource),
    triggerWithData: actionBuilder(triggerResourceWithData),
    loading,
    error
  }
}
