import React from 'react'
import { Form } from 'formik'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import BaseField from 'components/fields/BaseField'
import RadioInput from 'components/inputs/RadioInput'
import DatePicker from 'components/inputs/DatePicker'
import ServiceSelect from 'components/inputs/ServiceSelect'

import './SignUpDetailsForm.scss'

const SignUpDetailsForm = ({ data }) => {
  const { t } = useTranslation('sign_up')

  return (
    <Form className='sign-up-details-form-container'>
      <BaseField name='first_name' required />
      <BaseField name='last_name' required />
      <BaseField
        name='date_of_birth'
        render={({ form, field }) => {
          return (
            <DatePicker
              {...form}
              {...field}
              onChange={(value) =>
                form.setFieldValue(field.name, moment(value).format('YYYY-MM-DD'))
              }
            />
          )
        }}
      />
      <BaseField
        name='gender'
        component={RadioInput}
        options={[
          { value: 'female', label: t('female') },
          { value: 'male', label: t('male') }
        ]}
      />

      <BaseField name='email' />

      <BaseField
        name='phone_number'
        disabled={data.get('phone_number') !== null}
        type='tel'
        maxlength={10}
        required
      />
      <BaseField name='service_ids' noLabel component={ServiceSelect} />

      <IonButton
        type='submit'
        expand='block'
        className='sign-up-details-form-submit-button'
      >
        {t('next')}
      </IonButton>
    </Form>
  )
}

export default SignUpDetailsForm
