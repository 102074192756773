import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { PageLayout, PageContent } from 'components/layout'
import './SignUpReferralCompletePage.scss'
import { IonImg, IonRow, IonText, isPlatform } from '@ionic/react'

const SignUpReferralCompletePage = ({ history }) => {
  const { location } = useHistory()
  const [referrerName, setReferrerName] = useState()

  useEffect(() => {
    if (location.state) {
      setReferrerName(location.state.referrerName)
    } else {
      history.push('/')
    }
  }, [location.state, history])

  const { t } = useTranslation(['sign_up_referral'])
  const isIos = isPlatform('ios')
  const getDownloadUrl = () => {
    if (isPlatform('android'))
      return {
        url: 'https://play.google.com/store/apps/details?id=co.seekster.android.production',
        img_url:
          'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png?hl=de'
      }
    return {
      url: 'https://play.google.com/store/apps/details?id=co.seekster.android.production',
      img_url:
        'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png?hl=de'
    }
  }

  const download = getDownloadUrl()
  return (
    <PageLayout className='sign-up-referral-complete-container'>
      <PageContent>
        <div className='sign-up-referral-complete-content-container'>
          <IonRow className='sign-up-referral-complete-success-image-row'>
            <img
              className='sign-up-referral-complete-pending-approve-img'
              src='/assets/img/check.png'
              alt='pending'
            />
          </IonRow>
          <IonRow className='sign-up-referral-complete-success-text-row'>
            <IonText className='sign-up-referral-complete-success-text'>
              {t('referral_completed_registered')}
            </IonText>
          </IonRow>
          <IonRow className='sign-up-referral-complete-success-text-row'>
            <IonText className='sign-up-referral-complete-success-text'>
              {t('referral_completed_referrer_name')}
            </IonText>
          </IonRow>
          <IonRow className='abc'>
            <IonText className='sign-up-referral-complete-name-text'>
              {referrerName && referrerName}
            </IonText>
          </IonRow>
          <IonRow className='sign-up-referral-complete-content-row'>
            <IonText className='sign-up-referral-complete-content-text'>
              {t('referral_completed_next_steps')}
            </IonText>
          </IonRow>
          <IonRow className='sign-up-referral-complete-social-row'>
            <div className='sign-up-referral-complete-line-logo-container'>
              <img
                className='sign-up-referral-complete-line-logo'
                src='/assets/img/line_logo.png'
                alt='line logo'
              />
            </div>
            <a
              href='https://line.me/R/ti/p/@joinseekster'
              className='sign-up-referral-complete-line-link'
            >
              <IonText className='sign-up-referral-complete-line-text'>
                @joinseekster
              </IonText>
            </a>
          </IonRow>
          <IonRow className='sign-up-referral-download-app-text-row'>
            <IonText className='sign-up-referral-download-app-text'>
              {t('referral_completed_download_app')}
            </IonText>
          </IonRow>
          <IonRow className='sign-up-referral-download-container'>
            {isIos ? (
              <a
                href='https://apps.apple.com/th/app/workforce-partner/id1498182157?itsct=apps_box_link&itscg=30200'
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  borderRadius: '13px',
                  width: '134px',
                  height: '40px'
                }}
              >
                <img
                  src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1492387200&h=f7b756708b6e666ab4658a040e01621a'
                  alt='Download on the App Store'
                  style={{ borderRadius: '13px', width: '134px', height: '40px' }}
                />
              </a>
            ) : (
              <a className='sign-up-referral-download-app' href={download.url}>
                <IonImg src={download.img_url} />
              </a>
            )}
          </IonRow>
        </div>
      </PageContent>
    </PageLayout>
  )
}

SignUpReferralCompletePage.propTypes = {
  history: PropTypes.object
}

export default SignUpReferralCompletePage
