import React from 'react'
import PropTypes from 'prop-types'

import ImmutablePropTypes from 'react-immutable-proptypes'
import { IonGrid, IonRow, IonCol, IonAvatar, IonText, IonImg } from '@ionic/react'

import './MainJobCard.scss'

const MainJobCard = ({
  serviceName,
  serviceIconUrl,
  chargeCustomerPrice,
  payout,
  paymentMethod,
  startTime
}) => {
  const formatedTime = `${startTime.split('T')[0].split('-').join('/')} - ${
    startTime.split('+')[1]
  }`
  return (
    <IonGrid className='main-job-card-container' fixed>
      <IonRow>
        <IonCol>
          <IonAvatar class='main-job-card-service-icon'>
            <IonImg className='service-icon' src={serviceIconUrl} alt='Service Icon' />
          </IonAvatar>
        </IonCol>
        <IonCol size='6' className='main-job-card-content-col'>
          <IonRow>
            <IonText className='main-job-card-service-name'>{serviceName}</IonText>
          </IonRow>
          <IonRow className=''>
            <IonCol>
              <IonRow>
                <IonText className='main-job-card-start-time'>{formatedTime}</IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol>
          <IonRow className='row-flex-end'>
            <IonText className='payout' color='black'>
              {paymentMethod && paymentMethod.get('direct') ? (
                <div>
                  <span className='payout-symbol'>{chargeCustomerPrice[0]} </span>
                  <span className='payout-price'>
                    {chargeCustomerPrice.slice(1, chargeCustomerPrice.length)}
                  </span>
                </div>
              ) : (
                <div>
                  <span className='payout-symbol'>{payout[0]} </span>
                  <span className='payout-price'>{payout.slice(1, payout.length)}</span>
                </div>
              )}
            </IonText>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

MainJobCard.propTypes = {
  serviceName: PropTypes.string,
  serviceIconUrl: PropTypes.string,
  chargeCustomerPrice: PropTypes.string,
  payout: PropTypes.string,
  paymentMethod: ImmutablePropTypes.map,
  startTime: PropTypes.string
}

export default MainJobCard
