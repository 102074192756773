import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IonSlides, IonSlide, IonLabel, IonFab, IonFabButton } from '@ionic/react'

import './ImageSlide.scss'

const ImageSlide = ({ urls, slideOptions }) => {
  const totalImages = urls.length || 1
  const [currentImage, setCurrentImage] = useState(1)

  const handleSlideDidLoad = () => {
    const initialImage = slideOptions.initialSlide ? slideOptions.initialSlide + 1 : 1

    setCurrentImage(initialImage)
  }

  const prev = () => {
    setCurrentImage(currentImage => currentImage - 1)
  }

  const next = () => {
    setCurrentImage(currentImage => currentImage + 1)
  }

  return (
    <div className='img-slide__container'>
      <IonFab vertical='top' horizontal='end' slot='fixed'>
        <IonFabButton className='img-slide__pagination-chip'>
          <IonLabel>{currentImage} / {totalImages}</IonLabel>
        </IonFabButton>
      </IonFab>

      {
        urls.length !== 0 && (
          <IonSlides
            options={slideOptions}
            onIonSlidePrevEnd={prev}
            onIonSlideNextEnd={next}
            onIonSlidesDidLoad={handleSlideDidLoad}
          >
            {
              urls.map((url, i) => (
                <IonSlide key={`${url}-${i}`}>
                  <img src={url} alt='Slide' />
                </IonSlide>
              ))
            }
          </IonSlides>
        )
      }
    </div>
  )
}

ImageSlide.propTypes = {
  urls: PropTypes.array,
  slideOptions: PropTypes.object
}

ImageSlide.defaultProps = {
  urls: [],
  slideOptions: {}
}

export default ImageSlide
