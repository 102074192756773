/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IonButton,
  IonFab,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonSpinner,
  IonSlides,
  IonSlide,
  IonToast,
  IonLoading,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  IonModal,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import {
  leadSchema,
  announcementSchema,
  tenantSchema,
  providerSchema,
  providerActiveDateSchema,
  providerRestDateSchema
} from '@seekster/schemas'
import { Plugins } from '@capacitor/core'

import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import LeadList from 'components/lists/LeadList'
import EmptyListPlaceholder from 'components/lists/EmptyListPlaceholder'
import JobSkeletonCard from 'components/JobSkeletonCard'
import {
  funnelOutline,
  arrowForwardOutline,
  closeOutline,
  navigateCircleOutline
} from 'ionicons/icons'
import SearchInput from 'components/inputs/SearchInput'
import { useCurrentLocation } from 'hooks/interfaces'
import LeadFilterForm from 'components/filters/LeadFilterForm'

import { useCollection } from 'hooks/collections'
import { useResource, useResourceActions } from 'hooks/resources'
import { useEventId } from 'hooks/utils'
import groupByStartDate from 'utils/groupByStartDate'
import { getCurrentResource } from 'selectors/authentication'

import './LeadIndexPage.scss'

import closeIcon from 'assets/svg/icon_close.svg'

const { Browser } = Plugins

const LeadIndexPage = ({ location }) => {
  const searchRef = useRef(null)
  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState({})
  const [filterDate, setFilterDate] = useState(null)
  const [filterSelected, setFilterSelected] = useState([])
  const [isDistance, setIsDistance] = useState(false)
  const backButtonListenerHandle = useRef()
  const pushState = location.state && location.state.fetch
  const { t, i18n } = useTranslation(['leads', 'translation'])
  const [currentPage, setCurrentPage] = useState(1)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const submitLeadFilterButtonRef = useRef()
  const [modalFilterOpen, setModalFilterOpen] = useState(false)
  const [modalDistanceOpen, setModalDistanceOpen] = useState(false)
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [eventId, generateEventId] = useEventId()
  const [leads, { loading, totalCount, totalPages }] = useCollection(
    '/leads',
    leadSchema,
    {
      dependencies: [eventId, i18n.language, pushState, query, filter],
      query: { page: currentPage, query, filter },
      cacheKey: `leads-${eventId}-${query}-${JSON.stringify(filter)}`
    }
  )

  const [announcements] = useCollection('/announcements', announcementSchema, {
    dependencies: [eventId, i18n.language, pushState]
  })

  const [restDates] = useCollection('/provider_rest_dates', providerRestDateSchema, {
    dependencies: [eventId, pushState],
    cacheKey: `resDates-index-${eventId}`
  })

  const [availableDates] = useCollection(
    '/provider_available_dates',
    providerActiveDateSchema,
    {
      dependencies: [eventId, pushState],
      cacheKey: `available-index-${eventId}`
    }
  )
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const { update: updateProfile, loading: updating } = useResourceActions(providerSchema)
  const [{ latitude, longitude }] = useCurrentLocation()
  const distanceEnabled = tenant.getIn([
    'general_configuration',
    'location_provider_enabled'
  ])

  useIonViewDidEnter(() => {
    backButtonListenerHandle.current = Plugins.App.addListener(
      'backButton',
      Plugins.App.exitApp
    )
  })

  useIonViewDidLeave(() => {
    if (backButtonListenerHandle.current) {
      backButtonListenerHandle.current.remove()
      backButtonListenerHandle.current = undefined
    }
  })

  const handleRefresh = () => {
    generateEventId()
    setCurrentPage(1)
    setQuery(searchRef.current?.value || '')
  }

  const handleLoadMore = () => {
    setCurrentPage((page) => page + 1)
  }

  const setCurrentHomeAddress = async () => {
    const geocoder = await new window.google.maps.Geocoder()
    const location = await new window.google.maps.LatLng(latitude, longitude)
    const home_address_attributes = {}
    setShowLoading(true)

    geocoder.geocode({ latLng: location }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        results[0].address_components.forEach((component) => {
          if (component.types.includes('route')) {
            home_address_attributes.street = component.long_name
          } else if (component.types.includes('sublocality_level_1')) {
            home_address_attributes.district = component.long_name
          } else if (component.types.includes('administrative_area_level_1')) {
            home_address_attributes.province = component.long_name
          } else if (component.types.includes('sublocality_level_2')) {
            home_address_attributes.sub_district = component.long_name
          } else if (component.types.includes('postal_code')) {
            home_address_attributes.zip_code = component.long_name
          } else if (component.types.includes('street_number')) {
            home_address_attributes.alley = component.long_name
          }
        })

        home_address_attributes.latitude = latitude
        home_address_attributes.longitude = longitude

        const data = {
          home_address_attributes: home_address_attributes
        }

        return updateProfile('/provider', data).then(() => {
          setShowLoading(false)
          setShowSuccessToast(true)
        })
      } else {
        setShowLoading(false)
        setShowErrorToast(true)
      }
    })
  }

  const handleSubmitFilterLeads = (values) => {
    generateEventId()
    setFilter(values)
    setFilterDate(values)
    setFilterSelected(values.filter ? values.filter : [])
    setModalFilterOpen(false)
  }

  const resetFilterDate = () => {
    const deletedDate = filter
    delete deletedDate.start_time

    generateEventId()
    setFilter(deletedDate)
    setFilterDate(null)
  }

  const setFormatDate = (date) => {
    const dateFormatSplit = date.split('-')
    const day = dateFormatSplit[2]
    const month = dateFormatSplit[1]
    const year = dateFormatSplit[0]

    return `${day}/${month}/${year}`
  }

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1.15,
    spaceBetween: 8,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    loop: false,
    loopedSlides: 2
  }

  const handleFilterDistance = (value) => {
    setIsDistance(value)
    setModalDistanceOpen(false)
  }

  const handleResetFilter = (value) => {
    const newValue = filterSelected.filter((data) => data !== value)

    setFilterSelected(newValue)
    if (newValue.length > 0) {
      setFilter({
        ...filter,
        filter: newValue
      })
    } else {
      const deletedKey = filter
      delete deletedKey.filter

      setFilter(filter)
    }
  }

  return (
    <PageLayout>
      <PageHeader title={t('leads')} />
      <PageContent onRefresh={handleRefresh}>
        <IonModal isOpen={modalFilterOpen} showBackdrop={false}>
          <PageHeader
            title={t('filter_leads')}
            action={
              <IonIcon
                icon={closeIcon}
                style={{ paddingRight: '1rem' }}
                onClick={() => setModalFilterOpen(false)}
              />
            }
          />
          <PageContent>
            <LeadFilterForm
              onSubmit={handleSubmitFilterLeads}
              submitButtonRef={submitLeadFilterButtonRef}
              filterDate={filterDate}
            />
          </PageContent>
          <PageFooter>
            <IonButton
              expand='block'
              onClick={() => submitLeadFilterButtonRef.current.click()}
            >
              {t('submit')}
            </IonButton>
          </PageFooter>
        </IonModal>

        <IonModal
          isOpen={modalDistanceOpen}
          cssClass='modal-distance-filter-container'
          swipeToClose
          onDidDismiss={() => setModalDistanceOpen(false)}
        >
          <IonGrid className='filter-distance-lead-container' fixed>
            <IonRow
              className='filter-lead-row-first'
              onClick={() => handleFilterDistance(true)}
            >
              <IonCol size='1' className='check-icon-column'>
                <IonRow>
                  {isDistance && (
                    <img
                      className='check-icon'
                      src='/assets/img/check.png'
                      alt='Marker'
                    />
                  )}
                </IonRow>
              </IonCol>

              <IonCol>
                <IonRow>
                  <IonText className='address'>โชว์ระยะทางใกล้</IonText>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow
              className='filter-lead-row'
              onClick={() => handleFilterDistance(false)}
            >
              <IonCol size='1' className='check-icon-column'>
                <IonRow>
                  {!isDistance && (
                    <img
                      className='check-icon'
                      src='/assets/img/check.png'
                      alt='Marker'
                    />
                  )}
                </IonRow>
              </IonCol>

              <IonCol>
                <IonRow>
                  <IonText className='address'>โชว์เวลาใกล้</IonText>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonModal>

        <SearchInput
          ref={searchRef}
          onSearch={setQuery}
          loading={loading}
          count={leads.count()}
          showHistory={false}
          placeholder={t('search_placeholder')}
        />
        <div className='filter-lead-container'>
          <IonGrid className='form-container'>
            <IonRow className='ion-align-items-center'>
              <IonCol size='8'>
                <IonButton
                  onClick={() => setModalFilterOpen(true)}
                  fill='outline'
                  className='filter-btn-container main'
                >
                  <IonIcon
                    slot='icon-only'
                    icon={funnelOutline}
                    className='filter-btn-icon'
                  />
                  {t('filter')}
                </IonButton>
              </IonCol>

              {distanceEnabled && provider.getIn(['home_address', 'latitude']) && (
                <IonCol size='4'>
                  <IonButton
                    onClick={() => setModalDistanceOpen(true)}
                    fill='outline'
                    className='filter-btn-container'
                  >
                    <img
                      slot='icon-only'
                      src='/assets/img/options-outline@2x.png'
                      className='filter-btn-icon'
                      alt='filter-icon'
                    />
                    {isDistance ? 'โชว์งานระยะใกล้' : 'โชว์เวลาล่าสุด'}
                  </IonButton>
                </IonCol>
              )}

              <IonCol size='12'>
                {filterDate !== null && filterDate.start_time !== undefined && (
                  <div className='filter-label-container'>
                    <div
                      className='filter-label'
                      onClick={() => setModalFilterOpen(true)}
                    >
                      {filterDate?.start_time?.from !== null &&
                        setFormatDate(filterDate?.start_time?.from)}
                      <IonIcon icon={arrowForwardOutline} className='arrow-btn-icon' />
                      {filterDate?.start_time?.to !== null &&
                        setFormatDate(filterDate?.start_time?.to)}
                    </div>
                    <div className='cancel-icon-container'>
                      <IonIcon
                        icon={closeOutline}
                        className='arrow-btn-icon'
                        onClick={() => resetFilterDate()}
                      />
                    </div>
                  </div>
                )}
              </IonCol>

              <IonCol size='7'>
                {filterSelected.length > 0 &&
                  filterSelected.map((filter) => {
                    return (
                      <div className='filter-label-container-status' key={filter}>
                        <div
                          className='filter-label'
                          onClick={() => setModalFilterOpen(true)}
                        >
                          {t(`tag.${filter}`)}
                        </div>
                        <div className='cancel-icon-container'>
                          <IonIcon
                            icon={closeOutline}
                            className='arrow-btn-icon'
                            onClick={() => handleResetFilter(filter)}
                          />
                        </div>
                      </div>
                    )
                  })}
              </IonCol>

              {(!!query || Object.keys(filter).length !== 0) && (
                <IonCol size='8'>
                  <div className='search-result'>
                    {t('search_result', { search_count: leads.count() })}
                  </div>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </div>
        {!announcements.isEmpty() && provider.get('id') !== 19868 && (
          <IonSlides pager options={slideOpts} className='slide-container'>
            {announcements.map((announcement) => {
              if (announcement.get('static_banner')) {
                return (
                  <IonSlide key={announcement.get('id')}>
                    <div className='announcement-container'>
                      <IonImg
                        src={announcement.get('banner_url')}
                        className='announcement-img'
                        alt='announcement'
                      />
                    </div>
                  </IonSlide>
                )
              } else if (announcement.get('link') !== '') {
                return (
                  <IonSlide
                    key={announcement.get('id')}
                    onClick={() =>
                      Browser.open({
                        url: announcement.get('link'),
                        toolbarColor: '#1b4170'
                      })
                    }
                  >
                    <div className='announcement-container'>
                      <IonImg
                        src={announcement.get('banner_url')}
                        className='announcement-img'
                        alt='announcement'
                      />
                    </div>
                  </IonSlide>
                )
              } else {
                return (
                  <IonSlide
                    key={announcement.get('id')}
                    onClick={() =>
                      Browser.open({
                        url: `https://${tenant.get('slug')}.${
                          process.env.REACT_APP_WORKFORCE_WEB_DOMAIN
                        }/th/announcements/${announcement.get('id')}`,
                        toolbarColor: '#1b4170'
                      })
                    }
                  >
                    <div className='announcement-container'>
                      <IonImg
                        src={announcement.get('banner_url')}
                        className='announcement-img'
                        alt='announcement'
                      />
                    </div>
                  </IonSlide>
                )
              }
            })}
          </IonSlides>
        )}

        {loading && leads.isEmpty() ? (
          <>
            <JobSkeletonCard />
            <JobSkeletonCard />
            <JobSkeletonCard />
          </>
        ) : leads.isEmpty() ? (
          <EmptyListPlaceholder
            message={
              searchRef.current?.value ? t('search_not_found') : t('no_leads_message')
            }
          />
        ) : (
          <LeadList
            isDistance={isDistance}
            latitude={provider.getIn(['home_address', 'latitude'])}
            longitude={provider.getIn(['home_address', 'longitude'])}
            distanceEnabled={distanceEnabled}
            data={groupByStartDate(leads, leadSchema, ['start_time'])}
            availableDates={availableDates}
            restDates={restDates}
          />
        )}

        {totalCount > 10 && currentPage <= totalPages && (
          <IonButton expand='full' color='light' onClick={handleLoadMore}>
            {loading ? <IonSpinner color='secondary' /> : t('load_more')}
          </IonButton>
        )}

        <div style={{ marginBottom: !provider.get('home_address') && '64px' }} />
      </PageContent>

      {distanceEnabled &&
        (!provider.get('home_address') ||
          !provider.getIn(['home_address', 'latitude']) ||
          !provider.getIn(['home_address', 'longitude'])) && (
          <IonFab vertical='bottom' slot='fixed' className='address-fab'>
            <IonItem className='address-item'>
              <IonGrid className='address-item-name-container'>
                <IonLabel>{t('your_home')}</IonLabel>
                {provider.getIn(['home_address', 'sub_district']) ? (
                  <IonGrid className='address-label-container'>
                    <IonRow>
                      <IonImg
                        className='label-icon'
                        src='/assets/img/arrow_location.png'
                      />
                      <IonLabel className='label-text'>
                        {provider.getIn(['home_address', 'sub_district'])}
                      </IonLabel>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <IonGrid className='address-label-container'>
                    <IonRow>
                      <IonImg
                        className='label-icon'
                        src='/assets/img/arrow_location.png'
                      />
                      <IonLabel className='label-text'>
                        {t('no_data_home_address')}
                      </IonLabel>
                    </IonRow>
                  </IonGrid>
                )}
              </IonGrid>
              <IonButton
                expand='block'
                onClick={() => setCurrentHomeAddress()}
                disabled={updating}
                className='address-update-button'
                large
              >
                <IonIcon
                  slot='icon-only'
                  icon={navigateCircleOutline}
                  className='address-update-icon'
                />
              </IonButton>
              <div className='update-label-container'>{t('update')}</div>
              <div className='arrow-down' />
            </IonItem>
          </IonFab>
        )}

      <IonToast
        isOpen={showSuccessToast}
        onDidDismiss={() => setShowSuccessToast(false)}
        message={t('update_home_success')}
        duration={2000}
        color='dark'
      />
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => setShowErrorToast(false)}
        message={t('update_home_error')}
        duration={2000}
        color='dark'
      />
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message='Please wait...'
        duration={5000}
      />
    </PageLayout>
  )
}

LeadIndexPage.propTypes = {
  location: PropTypes.object
}

export default LeadIndexPage
