import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  useIonViewWillEnter
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import ListSkeleton from 'components/ListSkeleton'
import TeamJobCard from 'components/TeamJobCard'
import { useTeamDistribution } from 'hooks/teamDistribution'

const PendingJob = () => {
  const { fetchTeam, jobSearch, currentTeam } = useTeamDistribution()
  const [loading, setLoading] = useState(true)
  const [jobs, setJob] = useState([])
  const { t } = useTranslation('team_lead')

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = ''
  })

  useEffect(() => {
    setLoading(true)
    fetchTeam('/jobs', {
      query: {
        status: 'pending_start',
        sort: 'startTime:asc',
        search: jobSearch,
        team: currentTeam?._id
      }
    }).then((res) => {
      setLoading(false)
      setJob(res.body)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearch, currentTeam])

  const doRefresh = (e) => {
    fetchTeam('/jobs', {
      query: {
        status: 'pending_start',
        sort: 'startTime:asc',
        search: jobSearch,
        team: currentTeam?._id
      }
    }).then((res) => {
      setJob(res.body)
      e.detail.complete()
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonItem lines='full'>
          {loading ? (
            <IonSkeletonText animated style={{ width: '30%' }} />
          ) : (
            `${t('number')} ${jobs.length} ${t('job')}`
          )}
        </IonItem>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {loading && <ListSkeleton />}
        <IonList>
          {jobs.map((job, index) => (
            <IonItem key={job.jobId} lines='full'>
              <TeamJobCard pending {...job} />
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default PendingJob
