import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { PageLayout, PageHeader, PageContent } from 'components/layout'
import { useResource } from 'hooks/resources'
import { jobProviderSchema } from '@seekster/schemas'
import QRCode from 'react-qr-code'
import './JobRatingPage.scss'

const JobRatingPage = ({ match, history, location }) => {
  const [jobProvider, { loading }] = useResource(
    `/job_providers/${match.params.id}`,
    jobProviderSchema,
    { id: match.params.id }
  )
  const job = jobProvider.get('job') || Map()

  const { t } = useTranslation('jobs')

  const onBack = () => {
    history.replace('/jobs', { fetch: true })
  }
  return (
    <PageLayout>
      <PageHeader
        title=''
        withBackButton
        onBack={onBack}
        noBorder
        textBack='Back'
      />

      <PageContent>
        <div className='job-rating-page--content-wrapper'>
          <IonText className='title'>{t('rating.title')}</IonText>
          {
            !loading && jobProvider ? (
              <>
                <div className='qrcode-wrapper'>
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={jobProvider.get('rating_link') || ''}
                    viewBox='0 0 256 256'
                  />
                </div>
                <IonText className='label-value'>{job.get('number')}</IonText>
                <IonText className='label-type'>{job.getIn(['service', 'name'])}</IonText>
              </>
            ) : undefined
          }
          <IonText className='label-description'>{t('rating.description')}</IonText>
        </div>
      </PageContent>
    </PageLayout>
  )
}

JobRatingPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default JobRatingPage
