import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'

import NotificationForm from './NotificationForm'

const formikConfig = {
  mapPropsToValues: ({ data = Map() }) => ({
    available_distance: data.get('available_distance'),
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    available_distance: Yup.number().min(0, 'ต้องมากกว่า 0 กม.').required(),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(NotificationForm)
