import { IonItem, IonLabel, IonList, IonSkeletonText, IonThumbnail } from '@ionic/react'
import React from 'react'

const ListSkeleton = ({ number }) => {
  const itemsArray = Array(number || 10).fill()
  return (
    <IonList>
      {itemsArray.map((_, index) => (
        <IonItem key={index} lines='full'>
          <IonThumbnail slot='start'>
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{ width: '80%' }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{ width: '60%' }} />
            </p>
            <p>
              <IonSkeletonText animated style={{ width: '30%' }} />
            </p>
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  )
}

export default ListSkeleton
