import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import PasswordInput from 'components/inputs/PasswordInput'
import BaseField from 'components/fields/BaseField'

import './SetPasswordForm.scss'
import BigButton from 'components/buttons/BigButton'
import SmallParagraph from 'components/typography/SmallParagraph'

const SetPasswordForm = ({ loading }) => {
  const { t } = useTranslation('sign_up')

  return (
    <Form>
      <IonGrid className='set-password-form-container'>
        <IonRow>
          <IonCol className='password-col'>
            <BaseField
              name='new_password'
              type='password'
              component={PasswordInput}
              inputmode='password'
              placeholder
              noLabel
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className='re-password-col'>
            <BaseField
              name='new_re_password'
              type='password'
              component={PasswordInput}
              placeholder
              inputmode='password'
              noLabel
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className='paragraph-col'>
            <SmallParagraph>{t('set_password.small_paragraph')}</SmallParagraph>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className='set-password-button-col'>
            <BigButton type='submit' disabled={loading}>
              {t('set_password.btn')}
            </BigButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Form>
  )
}

SetPasswordForm.propTypes = {
  loading: PropTypes.bool
}

export default SetPasswordForm
