import { PASSWORD_MIN_CHARACTERS } from 'constants/formConstants'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import SetPasswordForm from './SetPasswordForm'

const formikConfig = {
  validationSchema: ({ t }) =>
    Yup.object().shape({
      new_password: Yup.string()
        .min(
          PASSWORD_MIN_CHARACTERS,
          t('error.password_min_length', { min: PASSWORD_MIN_CHARACTERS })
        )
        .required(t('error.password_required')),
      new_re_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], t('error.password_not_match'))
        .required(t('error.password_required'))
    }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(SetPasswordForm)
