import React, { useState, useRef } from 'react'
import {
  IonGrid,
  IonCol,
  IonText,
  IonButton,
  IonImg,
  IonAlert,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react'
import { Plugins } from '@capacitor/core'
import { Storage } from '@capacitor/storage'
import { useTranslation } from 'react-i18next'
import { Map } from 'immutable'
import { useSelector } from 'react-redux'
import { tenantSchema } from '@seekster/schemas'
import moment from 'moment'

import { PageLayout, PageContent } from 'components/layout'
import { getCurrentResource } from 'selectors/authentication'

import i18n from 'i18n'

import './HomePage.scss'

const HomePage = () => {
  const backButtonListenerHandle = useRef()
  const { t } = useTranslation(['home', 'settings'])
  const [showChangeLanguageAlert, setShowChangeLanguageAlert] = useState(false)

  useIonViewDidEnter(() => {
    backButtonListenerHandle.current = Plugins.App.addListener(
      'backButton',
      Plugins.App.exitApp
    )
  })

  useIonViewDidLeave(() => {
    if (backButtonListenerHandle.current) {
      backButtonListenerHandle.current.remove()
      backButtonListenerHandle.current = undefined
    }
  })

  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()

  Storage.get({ key: 'locale' }).then(({ value }) => {
    !value && setShowChangeLanguageAlert(true)
  })

  const setLocale = (locale) => {
    Storage.set({ key: 'locale', value: locale }).then(() => {
      i18n.changeLanguage(locale)

      moment.locale(locale)
    })
  }

  return (
    <PageLayout>
      <PageContent className='home-page'>
        <IonGrid className='grid-container'>
          <IonCol>
            <IonImg
              className='workforce-logo'
              src='/assets/img/workforce_full_logo.png'
              alt='Workforce Full Logo'
            />

            <IonImg
              className='workforce-logo'
              src='/assets/img/workforce_intro_logo.png'
              alt='Workforce Intro Logo'
            />

            <IonButton
              className='sign-in-button'
              color='light'
              expand='block'
              routerLink='/sign_in'
            >
              {t('sign_in_to', { tenant: tenant.get('name') })}
            </IonButton>

            <div className='button-divider'>
              <div className='line' />

              <IonText className='text' color='light'>
                {t('or')}
              </IonText>

              <div className='line' />
            </div>

            <IonButton
              className='switch-tenant-button'
              color='light'
              expand='block'
              fill='outline'
              routerLink='/switch_tenant'
            >
              {t('sign_in_to_another_account')}
            </IonButton>
          </IonCol>
        </IonGrid>

        <IonAlert
          isOpen={showChangeLanguageAlert}
          onDidDismiss={() => setShowChangeLanguageAlert(false)}
          message='Please select your preferred language.'
          buttons={[
            {
              text: 'English',
              handler: () => setLocale('en')
            },
            {
              text: 'ภาษาไทย',
              handler: () => setLocale('th')
            }
          ]}
        />
      </PageContent>
    </PageLayout>
  )
}

export default HomePage
