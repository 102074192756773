import { withFormik } from 'formik'
import * as Yup from 'yup'
import { PASSWORD_MIN_CHARACTERS } from 'constants/formConstants'
import SignUpReferralForm from './SignUpReferralForm'

const formikConfig = {
  validationSchema: ({ t }) =>
    Yup.object().shape({
      first_name: Yup.string().required(t('error.first_name_required')),
      last_name: Yup.string().required(t('error.last_name_required')),
      email: Yup.string()
        .email(t('error.email_needs_to_be_email'))
        .required(t('error.email_required')),
      password: Yup.string()
        .required(t('error.password_required'))
        .min(
          PASSWORD_MIN_CHARACTERS,
          t('error.password_min_length', { min: PASSWORD_MIN_CHARACTERS })
        )
    }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(SignUpReferralForm)
