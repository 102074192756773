import React, { useRef } from 'react'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { bankAccountSchema } from '@seekster/schemas'

import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'

import BankAccountForm from 'forms/BankAccountForm'

import { useResourceActions } from 'hooks/resources'
import Spinner from 'components/Spinner'

const BankAccountNewPage = ({ history }) => {
  const { t } = useTranslation(['settings', 'bank_account'])
  const submitButtonRef = useRef()

  const { create: submitBankAccount, loading: updating } = useResourceActions(bankAccountSchema)

  const handleSubmit = (data) => {
   return submitBankAccount('/bank_accounts', data)
      .then((response) => {
        history.push(`/settings/bank_account/${response.body.id}/pending`)
      })
      .catch(() => alert('ERROR BANK ACCOUNT'))
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('my_bank_account')}
        withBackButton
        backTo='/settings/wallet'
      />

      <PageContent className='bank-account-new-page'>
        <BankAccountForm
          t={t}
          onSubmit={handleSubmit}
          submitButtonRef={submitButtonRef}
        />
      </PageContent>

      <PageFooter>
        <IonButton
          expand='block'
          onClick={() => {
            submitButtonRef.current.click()
          }}
          disabled={updating}
        >
          {t('save')}
        </IonButton>
      </PageFooter>

      {updating && <Spinner overlaid />}
    </PageLayout>
  )
}

export default BankAccountNewPage
