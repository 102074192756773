import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IonImg, IonRow, IonText } from '@ionic/react'
import { registerWebPlugin } from '@capacitor/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { PageLayout, PageContent } from 'components/layout'
import { setErrorAlertMessageSucceeded } from 'actions/resources'
import { signUp, updateCurrentDevice, signIn } from 'actions/authentication'
import referAFriend from 'assets/svg/image_refer_a_friend.svg'
import SignUpReferralForm from 'forms/SignUpReferralForm'
import LineLoginButton from 'components/LineLoginButton'
import FacebookLoginButton from 'components/FacebookLoginButon'
import Spinner from 'components/Spinner'
import './SignUpReferralPage.scss'
import { generateLineUrl, loginWithLine } from 'utils/lineweb'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { facebookLogin, initFacebookSdk } from 'utils/facebook'
import { getStorageValue, removeKeys, setStorageKey } from 'utils/storage'
registerWebPlugin(FacebookLogin)

const SignUpReferralPage = ({ history }) => {
  const { t, i18n } = useTranslation(['sign_in', 'sign_up_referral', 'fields'])
  const [loading, setLoading] = useState(false)
  const [referrerId, setReferrerId] = useState()
  const [referrerName, setReferrerName] = useState()
  const query = () => new URLSearchParams(window.location.search)
  const dispatch = useDispatch()
  const setCustomError = (errorType) => {
    const error = {
      response: {
        body: {
          message: t(errorType)
        }
      }
    }
    return dispatch(setErrorAlertMessageSucceeded(error))
  }

  const setReferralValues = async () => {
    const queryReferrerId = query().get('referrer_id')
    const queryReferrerName = query().get('referrer_name')
    if (queryReferrerId) {
      await setStorageKey('referrer_id', queryReferrerId)
      setReferrerId(query().get('referrer_id'))
    } else setReferrerId(await getStorageValue('referrer_id'))
    if (queryReferrerName) {
      await setStorageKey('referrer_name', queryReferrerName)
      setReferrerName(query().get('referrer_name'))
    } else setReferrerName(await getStorageValue('referrer_name'))
  }

  const handleLineLoginPressed = async () => {
    if (!referrerId) return setCustomError('no_provider_id')
    window.location.replace(generateLineUrl())
  }

  const signUpWithLine = async () => {
    setLoading(true)
    await setReferralValues()
    try {
      if (referrerId && referrerName) {
        const lineResponse = await loginWithLine(query().get('code'))
        if (lineResponse.access_token) {
          await dispatch(
            signIn(`/oauth?referrer_id=${referrerId}`, {
              first_name: lineResponse.user.name,
              email: lineResponse.user.email,
              remote_avatar_url: lineResponse.user.picture,
              omniauth_identities_attributes: {
                uid: lineResponse.user.sub,
                provider: 'line',
                token: lineResponse.access_token
              }
            })
          )
          pushToComplete(referrerName)
        }
      }
    } catch (ex) {
      handleErrors(ex, true)
    }
    setLoading(false)
  }

  const handleErrors = async (error, isLine) => {
    if (error.response.body.error === 'invalid_credentials') {
      return setCustomError('Invalid credentials')
    }
    switch (error.response.body.message) {
      case 'Provider could not be found':
        error.response.body.message = t('provider_not_found')
        break
      case 'provider-already-referred':
        error.response.body.message = t('provider_already_referred')
        break
      case 'no-strings':
        error.response.body.message = t('provider_no_strings')
        break
      case 'Validation failed: Email has already been taken':
        error.response.body.message = t('provider_email_taken')
        break
      case 'provider-has-account':
        error.response.body.message = t('provider_has_account')
        break
      default:
        break
    }
    dispatch(setErrorAlertMessageSucceeded(error))

    if (isLine) {
      history.push(
        `${history.location.pathname}${referrerId ? `?referrer_id=${referrerId}` : ''}${
          referrerName ? `&referrer_name=${referrerName}` : ''
        }`
      )
    }
  }

  const signInWithFacebook = async () => {
    if (!referrerId) return setCustomError('no-provider-id')
    setLoading(true)
    try {
      const facebookResponse = await facebookLogin()
      await dispatch(
        signIn(`/oauth?referrer_id=${referrerId}`, {
          first_name: facebookResponse.userData.first_name,
          last_name: facebookResponse.userData.last_name,
          email: facebookResponse.userData.email,
          remote_avatar_url: facebookResponse.userData.picture.data.url,
          omniauth_identities_attributes: {
            uid: facebookResponse.userId,
            provider: 'facebook',
            token: facebookResponse.token
          }
        })
      )
      await pushToComplete(referrerName)
    } catch (ex) {
      handleErrors(ex)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (data) => {
    if (!referrerId) {
      return setCustomError('no-provider-id')
    }
    setLoading(true)
    try {
      await dispatch(signUp(data, referrerId))
      const localeValue = await getStorageValue('locale')
      dispatch(updateCurrentDevice({ locale: localeValue }))
      pushToComplete(referrerName)
    } catch (e) {
      handleErrors(e)
    }
    setLoading(false)
  }

  const pushToComplete = async (referrerName) => {
    await removeKeys(['referrer_name', 'referrer_id'])
    history.push({
      pathname: '/sign_up_referral/complete',
      state: { referrerName }
    })
  }

  useEffect(() => {
    i18n.changeLanguage('th')
    initFacebookSdk()
    if (query().get('code')) {
      signUpWithLine()
    } else {
      setReferralValues()
    }
  }, [referrerId, referrerName])

  return (
    <PageLayout withSafeAreaTop withSafeAreaBottom className='sign-up-referral-container'>
      <PageContent>
        <div className='sign-up-referral-content-container'>
          <IonRow className='sign-up-referral-header-row'>
            <IonImg className='sign-up-referral-header-image' src={referAFriend} />
          </IonRow>
          <IonRow className='sign-up-referral-header-text-row'>
            <IonText className='sign-up-referral-header-text'>
              {t('referral_name', { referrer_name: referrerName })}
            </IonText>
          </IonRow>
          <IonRow className='sign-up-referral-content-row'>
            <IonText className='sign-up-referral-content-text'>
              {t('referral_sign_up_content')}
            </IonText>
          </IonRow>
          <LineLoginButton handleClick={handleLineLoginPressed}>
            {t('login_with_line')}
          </LineLoginButton>
          <FacebookLoginButton handleClick={signInWithFacebook}>
            {t('continue_with_facebook')}
          </FacebookLoginButton>
          <IonRow className='sign-up-referral-sign-in-with-email-row'>
            <div className='line' />
            <IonText className='sign-up-referral-sign-in-with-email-text'>
              {t('sign_up_with_email')}
            </IonText>
            <div className='line' />
          </IonRow>
          <SignUpReferralForm
            referrerId={referrerId}
            referrerName={referrerName}
            onSubmit={handleSubmit}
            t={t}
          />
        </div>
      </PageContent>
      {loading && <Spinner overlaid />}
    </PageLayout>
  )
}

SignUpReferralPage.propTypes = {
  history: PropTypes.object
}

export default SignUpReferralPage
