import React from 'react'
import PropTypes from 'prop-types'

import './TabButton.scss'
import { IonButton } from '@ionic/react'

const TabButton = ({ tabState, conditionalValue, color, children, setTabState }) => {
  return (
    <IonButton
      color={color}
      className={
        tabState === conditionalValue
          ? 'active refer-friend-tab-button'
          : 'inactive refer-friend-tab-button'
      }
      onClick={() => setTabState(conditionalValue)}
    >
      {children}
    </IonButton>
  )
}

TabButton.defaultProps = {
  color: 'white'
}

TabButton.propTypes = {
  tabState: PropTypes.string,
  conditionalValue: PropTypes.string,
  color: PropTypes.string,
  setTabState: PropTypes.func
}

export default TabButton
