import React, { useCallback, useEffect, useState } from 'react'
import OtpInput from 'components/inputs/OtpInput'

import './OtpForm.scss'

const RenderInput = (i, handleChange, hasErrors, handleDelete) => {
  const [inputValue, setInputValue] = useState('')

  const handleDeleteKey = (e) => {
    setInputValue('')
    handleDelete(i, e)
  }
  const handleUpdate = (e) => {
    if (inputValue.length > e.target.value) {
      return handleDelete(e)
    }
    const previousValue = inputValue
    if (e.target.value[e.target.value.length - 1] === previousValue) {
      setInputValue(e.target.value[0])
    } else {
      setInputValue(e.target.value[e.target.value.length - 1])
    }
    handleChange(i, e.target.value)
  }

  useEffect(() => {
    if (hasErrors) setInputValue('')
  }, [hasErrors])
  return (
    <div className='otp-input-container' key={i}>
      <OtpInput
        key={'otp-' + i}
        name={'otp-' + i}
        handleChange={(e) => handleUpdate(e)}
        value={inputValue}
        hasErrors={hasErrors}
        handleDelete={(e) => handleDeleteKey(e)}
      />
    </div>
  )
}
const OtpForm = ({ numberOfFields = 6, submit, hasErrors, resetError }) => {
  const arr = [...Array(numberOfFields).keys()]
  const [state, setState] = useState([])

  const registerChange = (i, value, event) => {
    if (hasErrors && !state.length) resetError()
    const nextSibling = getSibling(i)
    if (value !== null) {
      setState([...state.filter((val) => val.index !== i), { value: value, index: i }])
      if (nextSibling) {
        return nextSibling.focus()
      }
    }
  }

  const handleDelete = (i, e) => {
    const previous = getSibling(i, true)
    setState([...state.filter((val) => val.index !== i)])
    if (previous) previous.focus()
  }
  const getSibling = (index, prior = false) => {
    if (!prior)
      return document.querySelector(`input[name=otp-${parseInt(index, 10) + 1}]`)
    return document.querySelector(`input[name=otp-${parseInt(index, 10) - 1}]`)
  }

  const handleSubmit = useCallback(() => {
    const otp = state
      .map((val) => val.value)
      .toString()
      .replace(/,/g, '')
    submit(otp)
    document.querySelector(`input[name=otp-0]`).focus()
    setState([])
  }, [state, submit])

  useEffect(() => {
    if (state.length === arr.length) {
      handleSubmit()
    }
  }, [state, handleSubmit, hasErrors, arr.length])
  return (
    <div className='otp-form'>
      {arr.map((i) => RenderInput(i, registerChange, hasErrors, handleDelete))}
    </div>
  )
}

export default OtpForm
