import superagent from 'superagent'
import prefix from 'superagent-prefix'

// import { normalize } from 'normalizr'
// import { Plugins } from '@capacitor/core'
// const { Storage } = Plugins

// import { updateEntities } from 'actions/entities'
// import withTenant from './withTenant'

const agent = superagent.agent()

// ===== Create helper method for attaching Access Tokens =====
superagent.Request.prototype.authentication = function (accessToken) {
  if (accessToken) {
    return this.auth(accessToken, { type: 'bearer' })
  } else {
    return this
  }
}

// ===== Create helper method for setting locale =====
superagent.Request.prototype.locale = function (locale) {
  return this.query({ locale })
}

// export const setupRequest = ({ clientId, clientPassword }) => {
//   // debugger
//   return agent.auth(clientId, clientPassword)
// }

// export default (configs = {}) => {
//   let basicAuth = [
//     process.env.REACT_APP_SEEKSTER_ACCESS_KEY,
//     process.env.REACT_APP_SEEKSTER_SECRET_KEY
//   ]

//   if (configs.clientAccessKey && configs.clientSecretKey) {
//     basicAuth = [configs.clientAccessKey, configs.clientSecretKey]
//   }

//   return agent
//     .auth(...basicAuth)
//     .use(prefix(process.env.REACT_APP_BASE_API))
//     .on('error', error => console.warn(error))
// }

export default agent
  .use(prefix(process.env.REACT_APP_BASE_API))
  .on('error', (error) => console.warn(error))
