import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IonGrid } from '@ionic/react'
import { PageLayout, PageContent, PageHeader } from 'components/layout'
import { useDispatch } from 'react-redux'

import { signInWithPhoneNumberSendOtp } from 'actions/authentication'

import './ForgotPasswordPage.scss'
import SendOtpForm from 'forms/SendOtpForm'

const ForgotPasswordPage = ({ history }) => {
  const { t } = useTranslation(['forgot_password', 'verify_phone_number'])
  const dispatch = useDispatch()

  const navigate = () => history.push('/forget_password/verify')

  const handleSubmit = async (data) => {
    try {
      await dispatch(signInWithPhoneNumberSendOtp(data))
      navigate()
    } catch (error) {
      throw error
    }
  }

  return (
    <PageLayout withSafeAreaBottom>
      <PageHeader title={t('forgot_password')} withBackButton />
      <PageContent className='forgot-password-page-core-layout'>
        <IonGrid className='grid-container'>
          <SendOtpForm
            defaultNumber=''
            t={t}
            useBigButton
            buttonText={t('continue_sms')}
            onSubmit={(phoneNumber) => handleSubmit(phoneNumber)}
            p2={t('sign_up_to_verify')}
            placeholder={t('input_placeholder')}
          />
        </IonGrid>
      </PageContent>
    </PageLayout>
  )
}

ForgotPasswordPage.propTypes = {
  history: PropTypes.object
}

export default ForgotPasswordPage
