import React from 'react'
import { useHistory } from 'react-router-dom'
import { IonItem } from '@ionic/react'

import BankAccountCard from 'components/BankAccountCard'

const BankAccountList = ({ bankAccounts }) => {
  const { push } = useHistory()

  return (
    <>
      {
        bankAccounts.map(bankAccount => (
          <IonItem
            key={bankAccount.get('id')}
            button
            onClick={() => push(`/settings/bank_account/${bankAccount.get('id')}/edit`)}
            detail={false}
            lines='full'
            className='bank-account-item'
            style={{ marginBottom: 10, boxShadow: '1px 2px 4px 0 #B6B6B6' }}
          >
            <BankAccountCard bankAccount={bankAccount} />
          </IonItem>
        ))
      }
    </>
  )
}

export default BankAccountList
