import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { IonPage } from '@ionic/react'
import clsx from 'clsx'

import './PageLayout.scss'

const PageLayout = ({
  children,
  withSafeAreaTop,
  withSafeAreaBottom,
  noBackground,
  className
}) => {
  const validChildren =
    children instanceof Array ? children.filter((child) => child) : children

  return (
    <IonPage
      className={clsx(
        withSafeAreaTop && 'with-safe-area-top',
        withSafeAreaBottom && 'with-safe-area-bottom',
        noBackground && 'no-background',
        className
      )}
    >
      {Children.map(validChildren, (child) => {
        return cloneElement(child)
      })}
    </IonPage>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node,
  withSafeAreaTop: PropTypes.bool,
  withSafeAreaBottom: PropTypes.bool,
  noBackground: PropTypes.bool,
  className: PropTypes.string
}

export default PageLayout
