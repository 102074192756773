import React from 'react'
import { IonIcon } from '@ionic/react'
import { peopleCircle } from 'ionicons/icons'

export const JobProvider = ({ provider, jobStatus }) => {
  const renderProvider = () => {
    switch (jobStatus) {
      case 'pending_provider':
        return (
          <div className='provider'>
            <IonIcon icon={peopleCircle} />
            awaiting for assigned provider
          </div>
        )
      case 'pending_start':
        return (
          <div className='provider pending_start'>
            <IonIcon icon={peopleCircle} />
            <span className='display_name'>{provider?.fullName}</span>
            <span className='recipient'>ผู้รับผิดชอบ</span>
          </div>
        )
      case 'pending_complete':
        return (
          <div className='provider pending_complete'>
            <IonIcon icon={peopleCircle} />
            <span className='display_name'>{provider?.fullName}</span>
            <span className='recipient'>ผู้รับผิดชอบ</span>
          </div>
        )
      case 'completed':
        return (
          <div className='provider completed'>
            <IonIcon icon={peopleCircle} />
            <span className='display_name'>{provider?.fullName}</span>
            <span className='recipient'>ผู้รับผิดชอบ</span>
          </div>
        )
      case 'cancel':
        return (
          <div className='provider cancel'>
            <IonIcon icon={peopleCircle} />
            <span className='display_name'>ลูกค้ายกเลิกงาน</span>
          </div>
        )

      default:
        return (
          <div className='provider'>
            <IonIcon icon={peopleCircle} />
            awaiting for assigned provider
          </div>
        )
    }
  }

  return <>{renderProvider()}</>
}
