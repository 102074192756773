import {
  CLEAR_TEAM,
  LOAD_TEAM_DATA_SUCCEEDED,
  SET_CURRENT_TEAM,
  SET_JOB_SEARCH,
  SET_JOB,
  TD_AUTHENTICATED,
  SET_TEAM_DOCK
} from 'constants/teamDistribution'
import { Map } from 'immutable'

const initialState = Map({
  currentTeam: null,
  auth: {
    user: {},
    token: {}
  },
  teamDock: {
    isShow: false
  },
  teams: []
})

const teamDistributionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_TEAM:
      return state.set('currentTeam', payload)
    case CLEAR_TEAM:
      return state.set('currentTeam', null)
    case LOAD_TEAM_DATA_SUCCEEDED:
      return Map(payload)
    case SET_JOB_SEARCH:
    case SET_JOB:
      return state.merge(payload)
    case TD_AUTHENTICATED:
      return state.set('auth', payload)
    case SET_TEAM_DOCK:
      return state.set('teamDock', payload)
    default:
      return state
  }
}

export default teamDistributionReducer
