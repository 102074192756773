import { Share } from '@capacitor/share'

export const shareUniversalUrl = async (title, url) => {
  try {
    await Share.share({
      title: title,
      text: title,
      url: url,
      dialogTitle: title
    })
  } catch (ex) {
    throw ex
  }
}
