import { useDispatch, useSelector } from 'react-redux'

import {
  generateOtp,
  verifyOtp,
  enterModal,
  invalidateVerification
} from 'actions/phone_verification'

export const usePhoneVerification = (locale) => {
  const dispatch = useDispatch()
  const genOtp = async (number, countryCode) =>
    dispatch(generateOtp(number, countryCode, locale))
  const verOtp = async (otp) => dispatch(verifyOtp(otp, locale))
  const unValidateVerification = async () => dispatch(invalidateVerification())
  const otpLastRequestedOn = useSelector((state) =>
    state.getIn(['phoneVerification', 'otpRequestedOn'])
  )
  return { genOtp, verOtp, otpLastRequestedOn, unValidateVerification }
}

export const useGetNumberInfo = () => {
  const phoneNumber = useSelector((state) =>
    state.getIn(['phoneVerification', 'phoneNumber'])
  )
  const countryCode = useSelector((state) =>
    state.getIn(['phoneVerification', 'countryCode'])
  )
  return { phoneNumber, countryCode }
}

export const useEnterModal = () => {
  const dispatch = useDispatch()
  const enteredFrom = useSelector((state) =>
    state.getIn(['phoneVerification', 'enteredFrom'])
  )
  const enterModalFrom = (from) => enterModal(dispatch, from)
  return { enterModalFrom, enteredFrom }
}
