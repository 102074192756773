import React from 'react'
import {
  IonGrid, IonRow, IonCol,
  IonText, IonButton
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import './BankAccountCard.scss'

const BankAccountCard = ({ bankAccount }) => {
  const { t } = useTranslation('settings')

  return (
    <IonGrid className='bank-account-card-contanier' fixed>
      <IonRow>
        <IonCol size='9'>
          <IonRow>
            <IonText className='bank-account-top-container'>
              {t('account_name')} :  {bankAccount.get('account_name')}
            </IonText>
          </IonRow>

          <IonRow>
            <IonText className='bank-account-text-container'>
              {t('account_number')} : {bankAccount.get('formatted_account_number')}
            </IonText>
          </IonRow>

          <IonRow>
            <IonText className='bank-account-bottom-container'>
              {t('bank')} : <img className='bank-logo' alt='logo bank' src={bankAccount.getIn(['bank', 'logo_url'])} />ธนาคารกสิกรไทย
            </IonText>
          </IonRow>
        </IonCol>

        <IonCol size='3'>
          <IonRow className='row-flex-end'>
            <IonButton
              expand='block'
              fill='outline'
              className='bank-edit-button'
            >
              {t('edit')}
            </IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default BankAccountCard
