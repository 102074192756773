import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'

import BaseField from 'components/fields/BaseField'
import CheckboxInput from 'components/inputs/CheckboxInput'
import DateRangeField from 'components/DateRangeField'
import { useTranslation } from 'react-i18next'

import './LeadFilterForm.scss'

const LeadFilterForm = ({ submitButtonRef, filterDate }) => {
  const { t } = useTranslation('leads')

  const startTime = filterDate !== null ? filterDate?.start_time : {}

  return (
    <div className='filter-lead-form-container'>
      <Form>
        <div>
          <div className='date-range-field'>
            <DateRangeField
              name='start_time'
              label={t('filter_start_time_leads')}
              dateRange={startTime}
            />
          </div>

          <BaseField
            name='filter'
            label='กรองข้อมูล'
            component={CheckboxInput}
            options={[
              { label: 'ดูงานในวันที่พร้อมรับงาน', value: 'availabled' },
              { label: 'ดูงานในวันหยุด', value: 'disabled' }
            ]}
          />

          {/* <BaseField
            name='filter'
            label='กรองระยะทาง'
            component={CheckboxInput}
            options={[{ label: 'ดูงานในรัศมีที่กำหนด', value: 'distance' }]}
          /> */}
          <input ref={submitButtonRef} type='submit' style={{ display: 'none' }} />
        </div>
      </Form>
    </div>
  )
}

LeadFilterForm.propTypes = {
  submitButtonRef: PropTypes.object,
  values: PropTypes.object,
  filterDate: PropTypes.object
}

export default LeadFilterForm
