import React from 'react'
import { IonItem, IonAvatar, IonLabel } from '@ionic/react'
import Select, { components } from 'react-select'
import { Map } from 'immutable'
import { bankAccountSchema } from '@seekster/schemas'

import { useCollection } from 'hooks/collections'

import './BankAccountSelect.scss'

const makeBankAccountOption = (account = Map()) => ({
  value: account.get('id'),
  labelData: {
    accountName: account.get('account_name'),
    accountNumber: account.get('formatted_account_number'),
    bankName: account.getIn(['bank', 'name']),
    bankLogoUrl: account.getIn(['bank', 'logo_url'])
  }
})

const Label = ({ data }) => {
  return (
    <IonItem lines='none' className='bank-account-select__label'>
      <IonAvatar slot='start'>
        <img src={data.bankLogoUrl} alt='Bank Logo' width={44} height={44} />
      </IonAvatar>

      <IonLabel>
        <h2>{data.accountName}</h2>
        <h3>{data.bankName} : {data.accountNumber}</h3>
      </IonLabel>
    </IonItem>
  )
}

const Option = ({ data, ...rest }) => {
  return (
    <components.Option {...rest}>
      <Label data={data.labelData} />
    </components.Option>
  )
}

const SingleValue = ({ data, children, selectProps, ...rest }) => {
  return (
    <components.SingleValue {...rest}>
      {
        selectProps.isLoading ? (
          'loading....'
        ) : (
          <Label data={data.labelData} />
        )
      }
    </components.SingleValue>
  )
}

const customStyles = {
  valueContainer: base => ({ ...base, height: 68, padding: 8 }),
  indicatorSeparator: () => {},
  option: base => ({ ...base, backgroundColor: 'transparent' })
}

const BankAccountSelect = ({ value, onChange }) => {
  const [bankAccounts, { loading }] = useCollection('/bank_accounts', bankAccountSchema)

  const bankAccountOptions = bankAccounts
    .filter(account => account.get('state') === 'approved')
    .map(makeBankAccountOption)
    .toJS()

  const bankAccountValue = makeBankAccountOption(
    bankAccounts.find(account => account.get('id') === value)
  )

  const handleChange = (data) => {
    onChange && onChange(data.value)
  }

  return (
    <Select
      value={bankAccountValue}
      onChange={handleChange}
      options={bankAccountOptions}
      components={{ Option, SingleValue }}
      isSearchable={false}
      isLoading={loading}
      styles={customStyles}
    />
  )
}

export default BankAccountSelect
