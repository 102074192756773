import React from 'react'
import { IonAlert, IonButton, IonCol, IonRow } from '@ionic/react'
import PropTypes from 'prop-types'
import { callService } from 'utils/callService'
import { useHistory } from 'react-router'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const ConfirmReschedule = ({ rescheduleData, dismiss }) => {
  const { t } = useTranslation('jobs')
  const [action, setAction] = React.useState()
  const history = useHistory()
  const rescheduleId = _.find(rescheduleData.data, { state: 'pending_provider' }).id

  const rejectReschedule = async (id) => {
    try {
      const response = await callService.post(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-job/provider/reject/rechedule-job/${id}`
      )

      if (response.status === 200 || response.status === 201) {
        window.location.assign('jobs')
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const acceptReschedule = async (id) => {
    try {
      const response = await callService.post(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-job/provider/confirm/reschedule-job/${id}`,
        {
          action: 'provider_confirmed'
        }
      )

      if (response.status === 200 || response.status === 201) {
        window.location.assign('jobs')
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <IonRow>
      <IonCol size='6'>
        <IonButton expand='block' fill='outline' onMouseUp={() => setAction('reject')}>
          ปฎิเสธ
        </IonButton>
      </IonCol>
      <IonCol size='6'>
        <IonButton expand='block' onMouseUp={() => setAction('accept')}>
          ยอมรับ
        </IonButton>
      </IonCol>

      {/* --- reject -------  */}
      <IonAlert
        header={'คุณกำลังปฏิเสธรับงานนัดหมายใหม่นี้'}
        isOpen={action === 'reject'}
        message={'หากดำเนินการต่อ ระบบจะกระจายงานนี้ไปให้กับผู้บริการท่านอื่นๆแทน'}
        buttons={[
          {
            text: 'ยกเลิก',
            handler: () => dismiss()
          },
          {
            text: 'ยืนยัน',
            handler: () => rejectReschedule(rescheduleId)
          }
        ]}
      />
      {/* --- accept -------  */}
      <IonAlert
        header={'คุณกำลังยืนยันการรับงานนัดหมายใหม่นี้'}
        isOpen={action === 'accept'}
        message={'หากดำเนินการต่อ กรุณาเริ่มงานตามวัน-เวลาที่ลูกค้าได้เปลี่ยนแปลง'}
        buttons={[
          {
            text: 'ยกเลิก',
            handler: () => dismiss()
          },
          {
            text: 'ยืนยัน',
            handler: () => acceptReschedule(rescheduleId)
          }
        ]}
      />
    </IonRow>
  )
}

ConfirmReschedule.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.string,
  message: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string
}

export default ConfirmReschedule
