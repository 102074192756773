import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { providerSchema } from '@seekster/schemas'
import { briefcase, people } from 'ionicons/icons'
import Drawer from 'react-bottom-drawer'
import toast from 'react-hot-toast'

import { useResource } from 'hooks/resources'
import AssignProviderPage from '../AssignProviderPage'
import JobDetailPage from '../JobDetailPage'
import JobMapPage from '../JobMapPage'
import People from '../People'
import ProviderDetailPage from '../ProviderDetailPage'
import WorkPage from '../WorkPage'
import { clearTeamData } from 'actions/localStorage'
import TeamCustomerToast from 'components/TeamCustomToast/TeamCustomToast'

const Team = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const dispatch = useDispatch()

  const handleShowDrawer = (e) => {
    e.preventDefault()
    setShowDrawer(true)
    toast.dismiss()
  }

  const handleSelectAccount = () => {
    dispatch(clearTeamData('team')).then(() => {
      toast.dismiss()
      window.location.assign('/settings')
      setShowDrawer(false)

      toast(
        <TeamCustomerToast
          avatar={provider.get('avatar_url')}
          displayName={provider.get('display_name')}
        />,
        {
          duration: 3000,
          style: { width: '100%', background: '#5B8AC3ee', color: '#fff' },
          position: 'bottom-center'
        }
      )
    })
  }

  return (
    <IonPage>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route
              exact
              path='/team/people/:provider_id'
              component={ProviderDetailPage}
            />
            <Route
              path={[
                '/team/work/incomming/:job_id/assign_provider/:provider_id',
                '/team/work/pending/:job_id/assign_provider/:provider_id',
                '/team/work/completed/:job_id/assign_provider/:provider_id'
              ]}
              component={ProviderDetailPage}
              exact
            />
            <Route
              path={[
                '/team/work/incomming/:job_id/assign_provider',
                '/team/work/pending/:job_id/assign_provider',
                '/team/work/completed/:job_id/assign_provider'
              ]}
              component={AssignProviderPage}
              exact
            />
            <Route
              path={[
                '/team/work/incomming/:job_id',
                '/team/work/pending/:job_id',
                '/team/work/completed/:job_id'
              ]}
              component={JobDetailPage}
              exact
            />
            <Route
              path={[
                '/team/work/incomming/:job_id/map',
                '/team/work/pending/:job_id/map',
                '/team/work/completed/:job_id/map'
              ]}
              component={JobMapPage}
              exact
            />
            <Route
              exact
              path={[
                '/team/work/incomming',
                '/team/work/pending',
                '/team/work/completed',
                '/team/work'
              ]}
              render={(props) => <WorkPage {...props} provider={provider} />}
            />

            <Route
              exact
              path='/team/people'
              render={(props) => (
                <People
                  {...props}
                  handleShowDrawer={handleShowDrawer}
                  provider={provider}
                />
              )}
            />
            <Route
              exact
              path='/team'
              render={() => <Redirect to='/team/work/incomming' />}
            />
          </IonRouterOutlet>
          <IonTabBar id='team-tab-bar' mode='ios' slot='bottom' style={{ height: 64 }}>
            <IonTabButton mode='md' tab='work' href='/team/work'>
              <IonIcon icon={briefcase} style={{ fontSize: '1.8rem' }} />
              <IonLabel>Work</IonLabel>
            </IonTabButton>
            <IonTabButton mode='md' tab='people' href='/team/people'>
              <IonIcon icon={people} style={{ fontSize: '1.8rem' }} />
              <IonLabel>People</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        <Drawer isVisible={showDrawer} onClose={() => setShowDrawer(false)}>
          <IonList className='bottom-drawer'>
            <IonItem lines='full' onClick={handleSelectAccount}>
              <IonAvatar slot='start'>
                <IonImg src={provider.get('avatar_url')} alt='Provider Avatar' />
              </IonAvatar>
              <IonLabel>{provider.get('display_name')}</IonLabel>
            </IonItem>
          </IonList>
        </Drawer>
      </IonReactRouter>
    </IonPage>
  )
}

export default Team
