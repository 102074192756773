import { Filesystem, Directory } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'

export const loadReport = async (jobProviderId) => {
  const _report = await Storage.get({ key: `report_${jobProviderId}` })

  return JSON.parse(_report.value) || {}
}

export const deleteReport = async (jobProviderId) => {
  const report = await loadReport(jobProviderId)

  const _promises = Object.values(report).map((answer) => {
    if (answer.image) {
      return Filesystem.deleteFile({
        path: answer.image,
        directory: Directory.Documents
      })
    } else if (answer.attachments_attributes) {
      return Promise.all(
        answer.attachments_attributes.map((attchAttr) => {
          return Filesystem.deleteFile({
            path: attchAttr.file,
            directory: Directory.Documents
          })
        })
      )
    } else {
      return Promise.resolve({})
    }
  })

  await Promise.all(_promises)
  Storage.remove({ key: `report_${jobProviderId}` })

  return jobProviderId
}
