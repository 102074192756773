import { Map } from 'immutable'
import moment from 'moment'

const groupByStartDate = (list, schema, keyPath, order) => {
  return list.toSeq()
    .groupBy(item => (
      moment(item.getIn(keyPath)).format('YYYY-MM-DD')
    ))
    .map((groupedList, date) => {
      const key = keyPath[keyPath.length - 1]

      return Map({
        [key]: date,
        [schema._key]: groupedList
      })
    })
    .sortBy((_v, date) => {
      const result = new Date(date).getTime()

      return order === 'desc' ? -(result) : result
    })
    .toList()
}

export default groupByStartDate
