import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { conversationSchema } from '@seekster/schemas'

import {
  PageLayout,
  PageHeader,
  PageContent,
  PageFooter
} from 'components/layout'
import ChatForm from 'forms/ChatForm'
import ConversationBody from 'modules/conversations/ConversationBody'
import UpcomingJobCard from 'components/UpcomingJobCard'

import { useResourceActions } from 'hooks/resources'
import { useCollection } from 'hooks/collections'

const ConversationShowPage = ({ match, history }) => {
  const { create: createContent } = useResourceActions(conversationSchema)
  const [latestMessage, setLatestMessage] = useState(0)
  const [conversationDetail, setConversationDetail] = useState()
  const [conversations] = useCollection(
    '/conversations',
    conversationSchema
  )

  const handleBack = () => {
    history.goBack()
  }

  const handleSubmit = (endpoint, data) => {
    return createContent(endpoint, data)
      .then(response => setLatestMessage(response.body.id))
  }

  useEffect(() => {
    setConversationDetail(conversations.find(conversation => conversation.get('id').toString() === match.params.id))
  }, [conversations, match.params.id])

  return (
    <PageLayout>
      <PageHeader
        title={conversationDetail && conversationDetail.getIn(['customers', 0, 'display_name'])}
        withBackButton
        onBack={handleBack}
      />

      <PageContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#a7a7a7',
            height: '100%'
          }}
        >
          {
            conversationDetail && (
              <UpcomingJobCard
                inquiryId={conversationDetail.getIn(['source', 'inquiry', 'id'])}
              />
            )
          }
          <ConversationBody
            conversationId={match.params.id}
            latestMessage={latestMessage}
          />
        </div>
      </PageContent>
      <PageFooter>
        <ChatForm
          conversationId={match.params.id}
          onSubmit={handleSubmit}
        />
      </PageFooter>

    </PageLayout>
  )
}

ConversationShowPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default ConversationShowPage
