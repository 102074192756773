import React, { useState, useRef } from 'react'
import { IonGrid, IonAlert, IonButton } from '@ionic/react'
import { providerSchema } from '@seekster/schemas'
import { useTranslation } from 'react-i18next'

import { useResource, useResourceActions } from 'hooks/resources'
import NotificationForm from 'forms/NotificationForm'
import {
  PageLayout,
  PageHeader,
  PageContent,
  PageFooter
} from 'components/layout'

import './MyAvailableDistance.scss'

const MyAvailableDistancePage = ({ history }) => {
  const { t } = useTranslation('settings')

  const submitButtonRef = useRef()
  const [showUpdateSucceededAlert, setShowUpdateSucceededAlert] = useState(false)

  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const { update: updateProfile, loading: updating } = useResourceActions(providerSchema)

  const handleSubmit = (data) => {
    return updateProfile('/provider', data)
      .then(() => {
        setShowUpdateSucceededAlert(true)
        history.push('/settings')
      })
  }

  const handleDismissAlert = () => {
    setShowUpdateSucceededAlert(false)
  }

  return (
    <PageLayout className='my-available-distance-page'>
      <PageHeader
        title={t('my_available_distance')}
        withBackButton
        backTo='/settings'
      />
      <PageContent>
        <IonGrid className='wrapper'>
          <IonGrid className='label'>
            {t('available_distance_label')}
          </IonGrid>
          <IonGrid>
            <NotificationForm
              data={provider}
              onSubmit={handleSubmit}
              submitButtonRef={submitButtonRef}
            />
          </IonGrid>
        </IonGrid>
        <IonAlert
          isOpen={showUpdateSucceededAlert}
          onDidDismiss={handleDismissAlert}
          message={t('profile_updated')}
          buttons={[t('okay')]}
        />
      </PageContent>
      <PageFooter className='footer-container'>
        <IonButton
          expand='block'
          onClick={() => submitButtonRef.current.click()}
          disabled={updating}
        >
          {t('save')}
        </IonButton>
      </PageFooter>
    </PageLayout>
  )
}

export default MyAvailableDistancePage
