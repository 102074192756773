import React, { useState, useEffect } from 'react'
import { IonText, IonRow, IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { PageLayout, PageContent, PageHeader, PageFooter } from 'components/layout'
import { providerSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'

import './ReferralStatusPage.scss'
import ReferralList from 'components/lists/ReferalList'
import TabButton from 'components/TabButton'

import { shareUniversalUrl } from 'utils/share'
const ReferralStatusPage = () => {
  const { t } = useTranslation(['settings', 'referral'])
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const referrals = provider.get('referrals')
  const [tabState, setTabState] = useState('registering')
  const [filteredReferrals, setFilteredReferrals] = useState(referrals)
  const [referralUrl, setReferralUrl] = useState('')

  const BASE_URL = process.env.REACT_APP_APPLICATION_URL

  const filter = (referrals, tabState) => {
    switch (tabState) {
      case 'registering':
        return referrals.filter(
          (provider) =>
            provider.get('state') === 'registering' ||
            provider.get('state') === 'pending_approval'
        )
      case 'approved':
        return referrals.filter(
          (provider) =>
            provider.get('state') === 'approved' && provider.get('jobs_completed') < 5
        )
      case 'completed':
        return referrals.filter(
          (provider) =>
            provider.get('state') === 'approved' && provider.get('jobs_completed') >= 5
        )
      default:
        return referrals
    }
  }

  useEffect(() => {
    setReferralUrl(
      new URL(
        `${BASE_URL}/sign_up_referral?referrer_id=${provider.get(
          'id'
        )}&referrer_name=${provider.get('first_name')}`
      )
    )
    if (referrals) {
      setFilteredReferrals(filter(referrals, tabState))
    }
  }, [tabState, referrals, provider, BASE_URL])

  return (
    <PageLayout>
      <PageHeader
        title={t('pending_start_work')}
        withBackButton
        backTo='/settings/referral'
        className='refer-friend-pending-page-header'
      />
      <PageContent className=''>
        <div className='refer-friend-pending-page-container'>
          <IonRow className='refer-friend-pending-page-content-row'>
            <IonText className='refer-friend-pending-page-content-text'>
              {t('refer_friends_condition')}
            </IonText>
          </IonRow>
          <IonRow className='refer-friend-pending-page-btn-tab-row'>
            <TabButton
              tabState={tabState}
              conditionalValue={'registering'}
              setTabState={setTabState}
            >
              {t('referral_registered')}
            </TabButton>
            <TabButton
              tabState={tabState}
              conditionalValue={'approved'}
              setTabState={setTabState}
            >
              {t('referral_working')}
            </TabButton>
            <TabButton
              tabState={tabState}
              conditionalValue={'completed'}
              setTabState={setTabState}
            >
              {t('referral_completed')}
            </TabButton>
          </IonRow>
          <div className='refer-friend-pending-page-list-container'>
            {filteredReferrals && (
              <ReferralList
                className='refer-friend-pending-page-list'
                referrals={filteredReferrals}
              />
            )}
          </div>
        </div>
      </PageContent>
      <PageFooter className='ion-no-border'>
        <IonRow className='refer-friend-pending-footer'>
          <IonButton
            block
            className='refer-friend-pending-page-btn'
            onClick={() => shareUniversalUrl('Friend referral', referralUrl)}
          >
            {t('refer_friends')}
          </IonButton>
        </IonRow>
      </PageFooter>
    </PageLayout>
  )
}

export default ReferralStatusPage
