const convertUriToImageFile = async (uri, options = {}) => {
  const res = await fetch(uri)
  const blob = await res.blob()

  const name = options.fileNmae || new Date().getTime().toString()

  return new File([blob], `${name}.jpeg`, { type: 'image/jpeg' })
}

export default convertUriToImageFile
