import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import moment from 'moment'
import {
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonImg,
  IonThumbnail
} from '@ionic/react'
import { calendar, location as locationIcon } from 'ionicons/icons'

import { DateFormatter } from 'components/formatters'
import { JobProvider } from './JobProvider'
import { customLongText } from '../../utils/functions'

import './TeamJobCard.scss'

const TeamJobCard = (props) => {
  const {
    wfJobId,
    number,
    status,
    provider,
    payout,
    address,
    startTime,
    orders,
    service
  } = props
  const { t, i18n } = useTranslation('team_lead')
  const {
    push,
    location: { pathname }
  } = useHistory()
  const locale = i18n.language

  const handleAssignProvider = () => {
    // push(`/team/work/incomming/${props._id}/assign_provider`)
    push(`/team/work/incomming/${wfJobId}/assign_provider`)
  }

  const handleSelectJob = () => {
    push(`${pathname}/${wfJobId}`)
  }

  const renderButton = (props) => {
    switch (status) {
      case 'pending_start':
        return (
          <IonChip {...props} className='pending_start'>
            {t('pending_start')}
          </IonChip>
        )
      case 'pending_complete':
        return (
          <IonChip {...props} className='pending_complete'>
            {t('pending_completion')}
          </IonChip>
        )
      case 'completed':
        return (
          <IonChip {...props} className='completed'>
            {t('completed')}
          </IonChip>
        )
      case 'cancel':
        return (
          <IonChip {...props} className='cancel'>
            {t('cancelled')}
          </IonChip>
        )
      case 'pending_assign':
      case 'pending_provider':
      default:
        return (
          <IonChip className='pending_assign' {...props} onClick={handleAssignProvider}>
            {t('pending_assign')}
          </IonChip>
        )
    }
  }

  return (
    <IonGrid className='team-job-card'>
      <IonRow className='team-job-card__first-row'>
        <IonCol className='title ion-no-padding status' size={12}>
          <IonItem lines='none' className='ion-no-padding ion-no-margin'>
            <IonThumbnail slot='start' onClick={handleSelectJob}>
              <IonImg src={service.iconUrl} alt='icon-url' />
            </IonThumbnail>
            <IonLabel onClick={handleSelectJob} className='ion-text-wrap'>
              <h2 className='job-number'>{number}</h2>
              <p>{service.name}</p>
            </IonLabel>
            {renderButton({ slot: 'end' })}
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className='team-job-card__second-row' onClick={handleSelectJob}>
        <IonCol size={9} className='job-start-time'>
          <IonIcon color='primary' icon={calendar} />
          <IonText>{moment(startTime).format('ddd, D MMM YYYY, hh:mm')}</IonText>
          <IonText>
            <DateFormatter value={startTime} format='ddd, D MMM YYYY, hh:mm' />
          </IonText>
        </IonCol>
        <IonCol size={3} className='price'>
          <IonText>฿{payout}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className='team-job-card__third-row' onClick={handleSelectJob}>
        <IonGrid>
          {orders.map((order) => (
            <IonRow key={order.id} className='flex'>
              <IonCol size='10'>
                {customLongText(order[`name${locale.toUpperCase()}`], 36)}
              </IonCol>
              <IonCol size='2' className='ion-text-end'>
                x{order.qty}
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonRow>
      <IonRow className='team-job-card__fourth-row' onClick={handleSelectJob}>
        <IonCol>
          <div className='location'>
            <IonIcon color='primary' icon={locationIcon} />
            {address}
          </div>
          <JobProvider provider={provider} jobStatus={status} />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default TeamJobCard
