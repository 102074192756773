import React from 'react'
import { useHistory } from 'react-router'
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  IonThumbnail,
  useIonViewWillEnter,
  IonAvatar
} from '@ionic/react'
import { jobSchema } from '@seekster/schemas'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import JobComment from 'components/JobComment'
import Segment from 'components/Segment'
import QuestionAnswer from 'components/QuestionAnswer'
import { PageFooter, PageHeader } from 'components/layout'
import { cash, location as locationIcon } from 'ionicons/icons'
import { useResource } from 'hooks/resources'
import { useCurrentLocation } from 'hooks/interfaces'

import './JobDetailPage.scss'

const JobDetailPage = ({ match, ...props }) => {
  const jobId = match.params.job_id
  const { location, push } = useHistory()
  const [{ latitude, longitude }] = useCurrentLocation()
  const { t } = useTranslation(['jobs', 'team_lead'])

  const [job, { loading: jobLoading }] = useResource(`/jobs/${jobId}`, jobSchema, {
    id: jobId
  })

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = 'none'
  })

  const handleAssign = () => {
    push(`${match.url}/assign_provider`)
  }

  const renderJobState = () => {
    switch (job.get('state')) {
      case 'pending_assign':
        return (
          <IonChip className='pending_assign ion-no-margin ion-float-right'>
            <small>รอมอบหมายงาน</small>
          </IonChip>
        )
      case 'pending_start':
        return (
          <IonChip {...props} className='pending_start ion-no-margin ion-float-right'>
            {t('pending_start')}
          </IonChip>
        )
      case 'pending_complete':
        return (
          <IonChip {...props} className='pending_complete ion-no-margin ion-float-right'>
            {t('pending_completion')}
          </IonChip>
        )
      case 'completed':
        return (
          <IonChip {...props} className='completed ion-no-margin ion-float-right'>
            {t('completed')}
          </IonChip>
        )
      case 'cancel':
        return (
          <IonChip {...props} className='cancel ion-no-margin ion-float-right'>
            {t('cancelled')}
          </IonChip>
        )
      default:
        return (
          <IonChip className='pending_assign ion-no-margin ion-float-right'>
            <small>{t('pending_assign')}</small>
          </IonChip>
        )
    }
  }

  const backPath = () => {
    const pathname = location.pathname
    return pathname.split('/').slice(0, -1).join('/')
  }

  if (jobLoading) return null

  const getMapDirectionUrl = () => {
    const destinationLat = job.getIn(['location', 'latitude'])
    const destinationLng = job.getIn(['location', 'longitude'])
    if (latitude && longitude && destinationLat && destinationLng) {
      return `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destinationLat},${destinationLng}`
    }
  }

  return (
    <IonPage className='team-job-detail-page'>
      <PageHeader title='Job Detail' withBackButton backTo={backPath()} />
      <IonHeader className='sub-header'>
        <IonItem lines='full' className='ion-no-margin'>
          <IonThumbnail slot='start' onClick={() => {}}>
            <IonImg src={job.getIn(['service', 'icon_url'])} alt='icon-url' />
          </IonThumbnail>
          <IonLabel>
            <label className='job-number'>
              {job.get('number')}
              {renderJobState()}
            </label>
            <div className='location'>
              <IonIcon className='location-icon' icon={locationIcon} />
              <IonText className='ion-text-wrap'>
                {job.getIn(['location', 'full_address']) || '-'}
              </IonText>
            </div>
            <div className='cash'>
              <span className=''>
                <IonIcon color='' className='cash-icon' icon={cash} />
                <IonText color=''>{t('payout')}</IonText>
              </span>
              <IonText color='primary'>
                <h1 className='amount ion-float-right'>
                  <>{job.getIn(['payout', 'full_display'])}</>
                </h1>
              </IonText>
            </div>
          </IonLabel>
        </IonItem>
      </IonHeader>
      <IonContent className='content'>
        <Segment noPadding>
          <IonRouterLink routerLink={`${location.pathname}/map`}>
            <div style={{ width: '100%', overflow: 'hidden', height: '300px' }}>
              <iframe
                title='customer location'
                src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyAU_2eJ5XtFbDRTPcg0dYMGGO8d-c_AZ_M&q=${job.getIn(
                  ['location', 'latitude']
                )},${job.getIn(['location', 'longitude'])}`}
                width='100%'
                height='300'
                frameBorder='0'
                loading='lazy'
                style={{ border: 0 }}
              />
            </div>
          </IonRouterLink>
          <IonItem
            href={getMapDirectionUrl()}
            target='_blank'
            rel='noopener noreferrer'
            lines='none'
            className='item-open-google-map-app'
            disabled={!getMapDirectionUrl()}
          >
            <IonAvatar slot='start'>
              <img src='/assets/img/ic_get_direction.png' alt='ic_get_direction' />
            </IonAvatar>

            <IonLabel>{t('use_map_navigation')}</IonLabel>
          </IonItem>
        </Segment>
        <IonGrid>
          <IonRow>
            <IonCol className='start-date ion-align-items-center'>
              <IonText color='primary'>
                <strong>{t('start_date')}</strong>
              </IonText>
            </IonCol>
            <IonCol className='start-date ion-align-items-center'>
              <IonText color='primary'>
                <strong>
                  <IonChip color='primary'>
                    {moment(job.get('start_time')).format('D')}
                  </IonChip>
                </strong>
              </IonText>
              <IonText color='primary'>
                <strong>{moment(job.get('start_time')).format('MMM YYYY')}</strong>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines='none' className='ion-no-padding'>
                <IonText slot='start' color='primary'>
                  <strong>{t('duration')}</strong>
                </IonText>
                <span>-</span>
              </IonItem>
            </IonCol>
            <IonCol className='start-time'>
              <IonItem lines='none' className='ion-no-padding'>
                <IonText slot='start' color='primary'>
                  <strong>{t('start_time')}</strong>
                </IonText>
                <span>{moment(job.get('start_time')).format('hh:mm')}</span>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className='order-row order'>
            <IonCol>
              <IonText color='primary'>
                <h5>
                  <strong>{t('orders')}</strong>
                </h5>
              </IonText>
              <IonList>
                {job.get('orders') &&
                  job.get('orders').map((order) => (
                    <IonItem
                      key={order.get('id')}
                      lines='none'
                      className='ion-no-padding'
                    >
                      <div>{order.getIn(['package', 'name_th'])}</div>
                      <IonText slot='end'>x {order.get('quantity')}</IonText>
                    </IonItem>
                  ))}
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow className='contact-row contact'>
            <IonCol>
              <IonText color='primary'>
                <h5>
                  <strong>{t('contact_info')}</strong>
                </h5>
              </IonText>
              <IonItem lines='none' className='ion-no-padding'>
                <div>{job.getIn(['cuxtoer', 'full_name']) || '-'}</div>
              </IonItem>
              <IonItem lines='none' className='ion-no-padding'>
                <div>
                  โทร.{' '}
                  {job.getIn(['location', 'phone_number'])
                    ? job.getIn(['location', 'phone_number'])
                    : job.getIn(['customer', 'phone_number'])
                    ? job.getIn(['customer', 'phone_number'])
                    : '-'}
                </div>
              </IonItem>
              <IonItem lines='none' className='ion-no-padding'>
                <div>
                  {t('address')}: {job.getIn(['location', 'full_address']) || '-'}
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className='additional-info-row additional-info'>
            <IonCol>
              <IonText color='primary'>
                <h5>
                  <strong>{t('other')}</strong>
                </h5>
              </IonText>
              {job.get('answers')?.size > 0 ? (
                <IonList>
                  {job?.get('answers')?.map((answer) => (
                    <IonItem
                      key={answer.get('id')}
                      lines='full'
                      className='ion-no-padding'
                    >
                      <QuestionAnswer data={answer} />
                    </IonItem>
                  ))}
                </IonList>
              ) : (
                <IonText>-</IonText>
              )}
            </IonCol>
          </IonRow>
          <IonRow IonRow className='additional-info-row additional-info'>
            <IonText color='primary'>
              <h5>
                <strong>{t('customer_pictures')}</strong>
              </h5>
            </IonText>
          </IonRow>
          <IonRow class='margin-top-8'>
            {job.get('attachments') && job.get('attachments').length > 0
              ? job.get('attachments').map((attachment, key) => (
                  <IonCol key={key} size='3'>
                    <img src={attachment.file_url} alt={attachment.id} />
                  </IonCol>
                ))
              : '-'}
          </IonRow>
          <JobComment job={job} />
        </IonGrid>
      </IonContent>

      {job.get('state') === 'pending_provider' && (
        <PageFooter className='team-job-detail-page___footer'>
          <IonButton expand='block' onClick={handleAssign}>
            {t('assign_job')}
          </IonButton>
        </PageFooter>
      )}
    </IonPage>
  )
}

export default JobDetailPage
