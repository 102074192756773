import { Storage } from '@capacitor/storage'
import { AppVersion } from '@ionic-native/app-version'
import {
  IonAlert,
  IonAvatar,
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  useIonViewWillEnter
} from '@ionic/react'
import {
  bankAccountSchema,
  providerSchema,
  tenantSchema,
  walletSchema
} from '@seekster/schemas'
import { signOut, updateCurrentDevice } from 'actions/authentication'
import { clearTeam, handleTeamDock } from 'actions/teamDistribution'
import people from 'assets/svg/icon_mypeople.svg'
import supportIcon from 'assets/svg/icon_support.svg'
import termsIcon from 'assets/svg/icon_terms.svg'
import { PageContent, PageLayout } from 'components/layout'
import PolicyTermsModal from 'components/modals/PolicyTermsModal'
import { useCollection } from 'hooks/collections'
import { usePolicyModal } from 'hooks/policy'
import { useResource } from 'hooks/resources'
import { lockClosed, settings } from 'ionicons/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { removeKeys } from 'utils/storage'
import { useTeamDistribution } from 'hooks/teamDistribution'

import './SettingsPage.scss'

const SettingPage = () => {
  const { t, i18n } = useTranslation('settings')
  const dispatch = useDispatch()
  const [wallet] = useResource('/wallet', walletSchema, { implicit: true })
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const [tenant] = useResource('/tenant', tenantSchema, { implicit: true })
  const [bankAccounts, { loading: loadingBankAccounts }] = useCollection(
    '/bank_accounts',
    bankAccountSchema
  )
  const [showChangeLanguageAlert, setShowChangeLanguageAlert] = useState(false)
  const [showLogoutAlert, setShowLogoutAlert] = useState(false)
  const [version, setVersion] = useState(0)
  const { openPolicyModal } = usePolicyModal()
  const { teams } = useTeamDistribution()
  const canSwapTeam = teams && teams.length > 0

  const handleSignOut = () => {
    dispatch(updateCurrentDevice({ registration_token: null })).then(() => {
      dispatch(signOut())
    })
  }

  const setLocale = (locale) => {
    return dispatch(updateCurrentDevice({ locale }))
      .then(() => {
        return Storage.set({ key: 'locale', value: locale })
      })
      .then(() => {
        i18n.changeLanguage(locale)

        moment.locale(locale)
      })
  }

  useIonViewWillEnter(() => {
    clearTeam()
    removeKeys(['team'])
  })

  useEffect(() => {
    AppVersion.getVersionNumber().then((ver) => setVersion(ver))
  }, [])

  const handleOpenPrivacyPolicy = (variant) => {
    openPolicyModal(variant)
  }

  const onSwapTeam = () => dispatch(handleTeamDock(true))

  return (
    <PageLayout withSafeAreaTop>
      <PageContent loading={loadingBankAccounts}>
        <div className='setting-page-header'>
          <IonItem className='setting-page-header__provider' lines='none'>
            <div className='setting-page-header__avatar-container' slot='start'>
              <IonAvatar
                slot='start'
                className='setting-page-header__provider__avatar'
                {...(canSwapTeam && { onClick: onSwapTeam })}
              >
                <img src={provider.get('avatar_url')} alt='Provider Avatar' />
              </IonAvatar>
              {canSwapTeam && (
                <IonButton onClick={onSwapTeam}>{t('swap_account')}</IonButton>
              )}
            </div>
            <IonGrid fixed>
              <IonCol>
                <IonRow>
                  <IonLabel color='light' className='setting-page-header__provider__name'>
                    {provider.get('display_name')}
                  </IonLabel>
                </IonRow>
                {provider.get('public_rating') && (
                  <IonRow className='setting-page-row__provider__rating'>
                    <IonLabel
                      color='light'
                      className='setting-page-header__provider__rating'
                    >
                      {t('your_rate')}
                    </IonLabel>
                    <img
                      className='icon-rating'
                      src='/assets/img/rate-yellow.png'
                      alt='rating'
                    />
                    <IonLabel
                      color='light'
                      className='setting-page-header__provider__point'
                    >
                      {provider.get('public_rating')}
                    </IonLabel>
                  </IonRow>
                )}
              </IonCol>
            </IonGrid>
          </IonItem>

          <IonButton
            className='wallet-balance-card'
            color='secondary'
            expand='block'
            routerLink='/settings/wallet'
          >
            <IonItem className='wallet-balance-card__content-wrapper' lines='none'>
              <IonLabel color='light'>
                <IonText className='wallet-balance-card__label'>
                  {t('available_balance')}
                </IonText>

                <IonText className='wallet-balance-card__amount'>
                  {wallet.getIn(['available_balance', 'display_value'])}
                </IonText>
              </IonLabel>
              <img
                className='wallet-balance-card__icon'
                src='/assets/img/ic_wallet_light.png'
                alt='Wallet'
              />
            </IonItem>
          </IonButton>
        </div>

        <IonList lines='full' className='setting-menu-list'>
          <IonItem detail button routerLink='/settings/wallet'>
            <IonImg className='wallet-icon-image' src='/assets/img/ic_wallet_grey.png' />
            <IonLabel className='setting-item-label'>{t('my_wallet')}</IonLabel>
          </IonItem>

          <IonItem
            detail
            button
            routerLink={
              bankAccounts.isEmpty()
                ? '/settings/wallet_withdrawal/error'
                : '/settings/my_bank_account'
            }
          >
            <IonImg className='wallet-icon-image' src='/assets/img/icon-bank-acc.png' />
            <IonLabel className='setting-item-label'>
              {t('my_bank_account_page')}
            </IonLabel>
          </IonItem>

          <IonItem detail button routerLink='/settings/profile'>
            <IonImg
              className='profile-icon-image'
              src='/assets/img/ic_profile_grey.png'
            />
            <IonLabel className='setting-item-label'>{t('my_profile')}</IonLabel>
          </IonItem>

          <IonItem detail button routerLink='/settings/my_calendar'>
            <IonImg
              className='profile-icon-image'
              src='/assets/img/icon_calendar_management.png'
            />
            <IonLabel className='setting-new-item-label'>{t('my_calendar')}</IonLabel>
          </IonItem>

          <IonItem
            detail
            button
            routerLink={
              !provider.get('home_address') ? '/settings/address' : '/settings/my_address'
            }
          >
            <IonImg
              className='address-icon-image'
              src='/assets/img/ic_address_grey.png'
            />
            <IonLabel className='setting-item-label'>{t('my_address')}</IonLabel>
            {provider.getIn(['home_address', 'sub_district']) && (
              <IonLabel className='setting-item-label setting-item-label-text-right'>
                {provider.getIn(['home_address', 'sub_district'])}
              </IonLabel>
            )}
          </IonItem>

          <IonItem detail button routerLink='/settings/my_available_distance'>
            <IonImg
              className='profile-icon-image'
              src='/assets/img/ic_profile_grey.png'
            />
            <IonGrid className='setting-new-item-label'>
              <IonLabel>{t('my_available_distance')}</IonLabel>
            </IonGrid>
            {provider.get('available_distance') && (
              <IonGrid className='setting-item-label setting-item-label-right'>
                <IonBadge color='danger' className='new-badge'>
                  {t('new')}
                </IonBadge>
                <IonLabel>
                  {provider.get('available_distance')} {t('kilometer_unit')}
                </IonLabel>
              </IonGrid>
            )}
          </IonItem>
        </IonList>
        <IonList lines='full' className='setting-menu-list'>
          {tenant.get('slug') === 'seekster' && (
            <IonItem detail button routerLink='/settings/referral'>
              <IonIcon
                className='refer-friend-icon'
                slot='start'
                icon={people}
                mode='md'
              />
              <IonLabel className='setting-item-label'>{t('refer_friend')}</IonLabel>
            </IonItem>
          )}
          <IonItem detail button onClick={() => setShowChangeLanguageAlert(true)}>
            <IonIcon className='change-language-icon' slot='start' icon={settings} />
            <IonLabel className='setting-item-label'>{t('change_language')}</IonLabel>
          </IonItem>
        </IonList>

        <IonList lines='full' className='setting-menu-list'>
          <IonItem detail button onClick={() => handleOpenPrivacyPolicy('terms')}>
            <IonIcon className='terms-icon' slot='start' icon={termsIcon} />
            <IonLabel className='setting-item-label'>
              {t('terms_and_conditions')}
            </IonLabel>
          </IonItem>
          <IonItem detail button onClick={() => handleOpenPrivacyPolicy('privacy')}>
            <IonIcon className='privacy-icon' slot='start' icon={lockClosed} />
            <IonLabel className='setting-item-label'>{t('privacy_policy')}</IonLabel>
          </IonItem>
          <IonItem detail button onClick={() => window.open('https://lin.ee/xS0fu7e')}>
            <IonIcon className='contact-icon' slot='start' icon={supportIcon} />
            <IonLabel className='setting-item-label'>{t('contact_admin')}</IonLabel>
          </IonItem>
        </IonList>

        <IonList lines='full' className='setting-menu-list'>
          <IonItem detail={false} button onClick={() => setShowLogoutAlert(true)}>
            <IonLabel className='setting-item-label logout-button'>
              {t('logout')}
            </IonLabel>
          </IonItem>
        </IonList>

        <IonImg
          className='workforce-small-logo'
          src='/assets/img/workforce_small_logo.png'
        />

        <IonText color='medium' className='app-version'>
          {t('version', { version })}
        </IonText>

        <IonAlert
          isOpen={showChangeLanguageAlert}
          onDidDismiss={() => setShowChangeLanguageAlert(false)}
          message={t('select_language')}
          buttons={[
            {
              text: 'English',
              handler: () => setLocale('en')
            },
            {
              text: 'ภาษาไทย',
              handler: () => setLocale('th')
            }
          ]}
        />

        <IonAlert
          isOpen={showLogoutAlert}
          onDidDismiss={() => setShowLogoutAlert(false)}
          message={t('logout_prompt')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('okay'),
              handler: handleSignOut
            }
          ]}
        />
        <PolicyTermsModal isMenu swipeToClose />
      </PageContent>
    </PageLayout>
  )
}

export default SettingPage
