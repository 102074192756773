import React from 'react'
import ActiveCalendarForm from 'forms/ActiveCalendarForm'

import './MyCalendarActivePage.scss'

const MyCalendarActivePage = ({ history }) => {
  return <ActiveCalendarForm history={history} />
}

export default MyCalendarActivePage
