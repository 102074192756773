import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Form } from 'formik'
import { CameraSource } from '@capacitor/camera'

import BaseField from 'components/fields/BaseField'
import RadioInput from 'components/inputs/RadioInput'
import CheckboxInput from 'components/inputs/CheckboxInput'
import Select from 'components/inputs/Select'
import { CameraInput, CustomCameraInput } from 'components/inputs/CameraInput'

const JobReportForm = ({
  reportQuestions,
  setFieldValue,
  submitButtonRef,
  onSaveAnswers,
  values
}) => {
  const renderAnswer = (question, i) => {
    const fieldName = `answers_attributes[${i}]`
    const questionId = question.get('id')

    switch (question.get('display_type')) {
      case 'string':
        return (
          <BaseField
            name={`${fieldName}.value`}
            label={question.get('name')}
            required={question.get('required')}
            placeholder={question.get('placeholder')}
            onChange={(value) => {
              let data

              if (value) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  value
                }
              }

              onSaveAnswers({ questionId, value })

              setFieldValue(fieldName, data)
            }}
          />
        )
      case 'textarea':
        return (
          <BaseField
            textarea
            name={`${fieldName}.value`}
            label={question.get('name')}
            required={question.get('required')}
            placeholder={question.get('placeholder')}
            onChange={(value) => {
              let data

              if (value) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({ questionId, value })
            }}
          />
        )
      case 'number':
        return (
          <BaseField
            type='number'
            name={`${fieldName}.value`}
            label={question.get('name')}
            required={question.get('required')}
            placeholder={question.get('placeholder')}
            onChange={(value) => {
              let data

              if (value) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({ questionId, value })
            }}
          />
        )
      case 'radio':
        return (
          <BaseField
            name={`${fieldName}.choice_id`}
            label={question.get('name')}
            required={question.get('required')}
            component={RadioInput}
            options={question
              .get('choices')
              .map((choice) => ({
                label: choice.get('value'),
                value: choice.get('id')
              }))
              .toJS()}
            onChange={(value) => {
              setFieldValue(fieldName, {
                ...values.answers_attributes[i],
                question_id: questionId,
                choice_id: value
              })

              onSaveAnswers({ questionId, choice_id: value })
            }}
          />
        )
      case 'checkboxes':
        return (
          <BaseField
            name={`${fieldName}.choice_ids`}
            label={question.get('name')}
            required={question.get('required')}
            component={CheckboxInput}
            options={question
              .get('choices')
              .map((choice) => ({
                label: choice.get('value'),
                value: choice.get('id')
              }))
              .toJS()}
            onChange={(value) => {
              setFieldValue(fieldName, {
                ...values.answers_attributes[i],
                question_id: questionId,
                choice_ids: value
              })

              onSaveAnswers({ questionId, choice_ids: value })
            }}
          />
        )
      case 'select':
      case 'searchable_select':
        return (
          <BaseField
            name={`${fieldName}.choice_id`}
            label={question.get('name')}
            required={question.get('required')}
            component={Select}
            options={question
              .get('choices')
              .map((choice) => ({
                label: choice.get('value'),
                value: choice.get('id')
              }))
              .toJS()}
            onChange={(value) => {
              const tempData = values.answers_attributes
              if (value === '' || value === undefined) {
                const newData = tempData.filter(
                  (data) => data.question_id !== question.get('id')
                )

                setFieldValue('answers_attributes', newData)
              } else {
                setFieldValue(fieldName, {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  choice_id: value
                })
                onSaveAnswers({ questionId, choice_id: value })
              }
            }}
            header={question.get('name')}
          />
        )
      case 'camera':
        return (
          <BaseField
            name={`${fieldName}.image`}
            label={question.get('name')}
            required={question.get('required')}
            component={CameraInput}
            onChange={async (value) => {
              let data

              if (value) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  image: value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({ questionId, image: value })
            }}
          />
        )
      case 'camera_multiple':
        return (
          <BaseField
            name={`${fieldName}.attachments_attributes`}
            isMulti
            label={question.get('name')}
            required={question.get('required')}
            component={CameraInput}
            onChange={async (value) => {
              let data

              if (value && value.length !== 0) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  attachments_attributes: value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({
                questionId,
                attachments_attributes: value
              })
            }}
          />
        )
      case 'gallery':
        return (
          <BaseField
            name={`${fieldName}.image`}
            label={question.get('name')}
            required={question.get('required')}
            component={CameraInput}
            cameraOptions={{ source: CameraSource.Prompt }}
            onChange={async (value) => {
              let data

              if (value) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  image: value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({ questionId, image: value })
            }}
          />
        )
      case 'gallery_multiple':
        return (
          <BaseField
            name={`${fieldName}.attachments_attributes`}
            label={question.get('name')}
            required={question.get('required')}
            component={CustomCameraInput}
            onChange={async (value) => {
              let data

              if (value && value.length !== 0) {
                data = {
                  ...values.answers_attributes[i],
                  question_id: questionId,
                  attachments_attributes: value
                }
              }

              setFieldValue(fieldName, data)

              onSaveAnswers({
                questionId,
                attachments_attributes: value
              })
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <Form className='job-report-form-container'>
      {reportQuestions.map((question, i) => {
        return <div key={question.get('id')}>{renderAnswer(question, i)}</div>
      })}

      <input ref={submitButtonRef} type='submit' style={{ display: 'none' }} />
    </Form>
  )
}

JobReportForm.defaultProps = {
  reportQuestions: List()
}

JobReportForm.propTypes = {
  reportQuestions: ImmutablePropTypes.list,
  setFieldValue: PropTypes.func,
  submitButtonRef: PropTypes.object,
  onSaveAnswers: PropTypes.func,
  values: PropTypes.object
}

export default JobReportForm
