import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'immutable'
import { isEqual } from 'lodash'

import request from 'utils/request'
import { fetchCollection } from 'actions/collections'
import { getCollection, getTotalCount, getTotalPages } from 'selectors/collections'
import { usePrevious } from 'hooks/utils'
import selectQueryToServer from 'utils/selectQueryToServer'

import { FETCH_CHILDREN_COLLECTION_SUCCEEDED } from 'constants/collections'

const fetchChildrenSucceeded = (id, response, schema, childrenSchema, options) => ({
  type: FETCH_CHILDREN_COLLECTION_SUCCEEDED,
  id,
  response,
  schema,
  childrenSchema,
  options
})

export const useCollection = (endpoint, schema, options = {}) => {
  const prevOptions = usePrevious(options)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const collection =
    useSelector((state) => getCollection(state, schema, options.cacheKey)) || List()
  const totalCount =
    useSelector((state) => getTotalCount(state, schema, options.cacheKey)) || 0
  const totalPages =
    useSelector((state) => getTotalPages(state, schema, options.cacheKey)) || 1

  useEffect(() => {
    if (!isEqual(prevOptions, options)) {
      setLoading(true)

      dispatch(
        fetchCollection(endpoint, schema, {
          query: options.query,
          cacheKey: options.cacheKey
        })
      )
        .then((response) => {
          setLoading(false)
        })
        .catch((error) => {
          setError(error)

          setLoading(false)

          // dispatch()
        })
    }
  }, [dispatch, endpoint, schema, options, prevOptions])

  return [collection, { totalCount, totalPages, loading, error }]
}

export const fetchChildren =
  (schema, id, childrenSchema, options = {}) =>
  (dispatch, getState) => {
    const type = options.type || schema._key
    const childrenType = options.childrenType || childrenSchema._key.split('/').pop()
    const url = options.url || `/${type}/${id}/${childrenType}`
    const locale = options.locale
    const accessToken = getState().getIn(['authentication', 'accessToken'])
    const query = {
      query: selectQueryToServer(childrenSchema, options),
      ...options.queryParam
    }
    const page = (options.page = options.page || 1)

    return request
      .get(url)
      .query({ ...query, page, locale })
      .authentication(accessToken)
      .then((response) => {
        dispatch(fetchChildrenSucceeded(id, response, schema, childrenSchema, options))

        return response
      })
  }
