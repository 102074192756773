import { Plugins } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { useDispatch, useSelector } from 'react-redux'
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { IonReactRouter } from '@ionic/react-router'
import { providerSchema } from '@seekster/schemas'
import leadIcon from 'assets/svg/icon_lead.svg'
import myHomeIcon from 'assets/svg/icon_myhome.svg'
import myJobIcon from 'assets/svg/icon_myjob.svg'
import profileIcon from 'assets/svg/icon_profile.svg'
import PrivateRoute from 'components/PrivateRoute'
import { useTeamDistribution } from 'hooks/teamDistribution'
import { Map } from 'immutable'
import { chatbubbles } from 'ionicons/icons'
import ConversationIndexPage from 'pages/conversations/ConversationIndexPage'
import ConversationShowPage from 'pages/conversations/ConversationShowPage'
import ConfirmForgotPasswordPage from 'pages/forgot_password/ConfirmForgotPasswordPage'
import ConfirmResetPasswordPage from 'pages/forgot_password/ConfirmResetPasswordPage'
import ForgotPasswordPage from 'pages/forgot_password/ForgotPasswordPage'
import ResetPasswordPage from 'pages/forgot_password/ResetPasswordPage'
import HomePage from 'pages/home/HomePage'
import JobCompletionPage from 'pages/jobs/JobCompletionPage'
import JobRatingPage from 'pages/jobs/JobRatingPage'
import JobDeliveryShowPage from 'pages/jobs/JobDeliveryShowPage'
import JobHistoryIndexPage from 'pages/jobs/JobHistoryIndexPage'
import JobHistoryShowPage from 'pages/jobs/JobHistoryShowPage'
import JobIndexPage from 'pages/jobs/JobIndexPage'
import JobMapPage from 'pages/jobs/JobMapPage'
import JobShowPage from 'pages/jobs/JobShowPage'
import LeadIndexPage from 'pages/leads/LeadIndexPage'
import LeadMapPage from 'pages/leads/LeadMapPage'
import LeadShowPage from 'pages/leads/LeadShowPage'
import MainPage from 'pages/main/MainPage'
import NotFoundPage from 'pages/not_found/NotFoundPage'
import AddressPage from 'pages/settings/AddressPage'
import BankAccountEditPage from 'pages/settings/BankAccountEditPage'
import BankAccountNewPage from 'pages/settings/BankAccountNewPage'
import BankAccountPendingPage from 'pages/settings/BankAccountPendingPage'
import MyAddressPage from 'pages/settings/MyAddressPage'
import MyAvailableDistancePage from 'pages/settings/MyAvailableDistancePage'
import MyBankAccountPage from 'pages/settings/MyBankAccountPage'
import MyCalendarActivePage from 'pages/settings/MyCalendarActivePage'
import MyCalendarDisablePage from 'pages/settings/MyCalendarDisablePage'
import MyCalendarPage from 'pages/settings/MyCalendarPage'
import ProfilePage from 'pages/settings/ProfilePage'
import ReferralPage from 'pages/settings/ReferralPage'
import ReferralStatusPage from 'pages/settings/ReferralPage/ReferralStatusPage'
import SettingsPage from 'pages/settings/SettingsPage'
import WalletPage from 'pages/settings/WalletPage'
import WalletWithdrawalErrorPage from 'pages/settings/WalletWithdrawalErrorPage'
import WalletWithdrawalPage from 'pages/settings/WalletWithdrawalPage'
import PhotosPage from 'pages/shared/PhotosPage'
import SignInPage from 'pages/sign_in/SignInPage'
import SignUpDetailsPage from 'pages/sign_up/SignUpDetailsPage'
import SignUpPage from 'pages/sign_up/SignUpPage'
import SignUpPendingPage from 'pages/sign_up/SignUpPendingPage'
import SignUpSetPasswordPage from 'pages/sign_up/SignUpSetPasswordPage'
import VerifyAuthenticationPage from 'pages/sign_up/VerifyAuthenticationPage'
import SignUpReferralCompletePage from 'pages/sign_up_referral/SignUpReferralCompletePage'
import SignUpReferralPage from 'pages/sign_up_referral/SignUpReferralPage'
import SwitchTenantPage from 'pages/switch_tenant/SwitchTenantPage'

import Team from 'pages/team/Team'
import VerifyCompletePage from 'pages/verify_phone_number/VerifyCompletePage'
import VerifyGeneratePage from 'pages/verify_phone_number/VerifyGeneratePage'
import VerifyPage from 'pages/verify_phone_number/VerifyPage'

import React, { useEffect } from 'react'

import { Redirect, Route } from 'react-router-dom'
import { getCurrentResource } from 'selectors/authentication'
import SwitchTeamDock from 'components/SwitchTeamDock'
import { selectTeamDock, handleTeamDock, authenticate } from 'actions/teamDistribution'

const { App } = Plugins

const generateRoutingPaths = (pathSegments = []) => {
  const paths = []
  let path = ''

  pathSegments.forEach((segment) => {
    path += `/${segment}`

    paths.push(path)
  })

  return paths
}

const MainPages = ({ history }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['leads', 'jobs', 'conversations', 'settings', 'home'])
  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()
  const disabledLeads = provider.get('disable_leads')

  useEffect(() => {
    dispatch(authenticate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const navigate = (destinationPathSegments = [], state) => {
      const currentPath = history.location.pathname
      const destinationPath = ['', ...destinationPathSegments].join('/')

      if (destinationPath === currentPath) {
        return history.replace(currentPath, state)
      }

      const currentTab = currentPath.split('/')[1]
      const isOnInnerPage = currentPath.split('/').length > 2

      if (isOnInnerPage) {
        history.replace(`/${currentTab}`, state)
      }

      generateRoutingPaths(destinationPathSegments).forEach((path, i) => {
        if (currentTab === destinationPathSegments[0] && i === 0) return

        history.push(path, state)
      })
    }

    const listerner = PushNotifications.addListener(
      'pushNotificationActionPerformed',
      ({ notification }) => {
        let conversationId

        switch (notification.data.event) {
          case 'new_lead':
          case 'new_lead_favorite_provider':
          case 'new_lead_near_job_with_icon':
            navigate(['leads', notification.data.id])
            break
          case 'provider_reminder':
            navigate(['jobs', notification.data.id])
            break
          case 'assign_provider':
            navigate(['jobs'], { fetch: true })
            break
          case 'inquiry_cancelled':
            navigate(['jobs', 'history'], { fetch: true })
            break
          case 'new_message':
            conversationId = notification.data.scheme.split('/').reverse()[0]

            navigate(['conversations', conversationId])
            break
          default:
            break
        }
      }
    )

    const appUrlOpenListerner = App.addListener('appUrlOpen', (data) => {
      const destinationPath = data.url.split('.myworkforce.io/')[1].split('/')

      navigate(destinationPath)
    })

    return () => {
      listerner.remove && listerner.remove()
      appUrlOpenListerner.remove && appUrlOpenListerner.remove()
    }
  }, [history])

  if (disabledLeads === undefined) return null

  function getContent() {
    return document.querySelector('ion-content')
  }

  function scrollToTop() {
    getContent().scrollToPoint(0, 0, 500)
  }

  function updateContent(url) {
    scrollToTop()
    history.replace(url, { fetch: true })
  }

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route path='/:tab(main)' component={MainPage} exact />
          <Route
            exact
            path='/:tab(leads)'
            component={disabledLeads ? () => <Redirect to='/jobs' /> : LeadIndexPage}
          />
          <Route path='/:tab(jobs)' component={JobIndexPage} exact />
          <Route path='/:tab(conversations)' component={ConversationIndexPage} exact />
          <Route
            path='/:tab(settings)'
            render={(props) => <SettingsPage {...props} />}
            exact
          />
          <Route exact path='/' render={() => <Redirect to='/main' />} />
        </IonRouterOutlet>

        <IonTabBar slot='bottom' style={{ height: 64 }}>
          <IonTabButton mode='md' tab='main' href='/main' onClick={() => scrollToTop()}>
            <IonIcon icon={myHomeIcon} />
            <IonLabel>{t('home')}</IonLabel>
          </IonTabButton>

          {!disabledLeads && (
            <IonTabButton
              mode='md'
              tab='leads'
              href='/leads'
              onClick={() => updateContent('/leads')}
            >
              <IonIcon icon={leadIcon} />
              <IonLabel>{t('leads')}</IonLabel>
            </IonTabButton>
          )}

          <IonTabButton mode='md' tab='jobs' href='/jobs' onClick={() => scrollToTop()}>
            <IonIcon icon={myJobIcon} mode='md' />
            <IonLabel>{t('jobs')}</IonLabel>
          </IonTabButton>

          <IonTabButton
            mode='md'
            tab='conversations'
            href='/conversations'
            onClick={() => history.replace('/conversations', { fetch: true })}
          >
            <IonIcon icon={chatbubbles} />
            <IonLabel>{t('messages')}</IonLabel>
          </IonTabButton>

          <IonTabButton mode='md' tab='settings' href='/settings'>
            <IonIcon icon={profileIcon} />
            <IonLabel>{t('settings')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  )
}

const Router = ({ isError }) => {
  const dispatch = useDispatch()
  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()
  const { currentTeam } = useTeamDistribution()
  const { isShow: isShowTeamDock } = useSelector(selectTeamDock)

  const onCloseTeamDock = () => dispatch(handleTeamDock(false))

  return (
    <IonReactRouter>
      {currentTeam && <Redirect to='/team/work/incomming' />}
      <SwitchTeamDock
        isShow={isShowTeamDock}
        onClose={onCloseTeamDock}
        currentUser={provider}
      />
      <IonRouterOutlet>
        <Route path='/home' component={HomePage} />
        <Route path='/sign_in' component={SignInPage} />
        <Route path='/forget_password' component={ForgotPasswordPage} exact />
        <Route
          path='/forget_password/verify'
          component={VerifyAuthenticationPage}
          exact
        />
        <Route path='/confirm_forget_password' component={ConfirmForgotPasswordPage} />
        <Route path='/reset_password/:token' component={ResetPasswordPage} exact />
        <Route path='/confirm_reset_password' component={ConfirmResetPasswordPage} />
        <Route path='/switch_tenant' component={SwitchTenantPage} />

        <Route path='/sign_up/registering' component={SignUpDetailsPage} />
        <Route path='/sign_up/pending_approval' component={SignUpPendingPage} />
        <Route path='/sign_up' component={SignUpPage} exact />
        <Route path='/sign_up/verify' component={VerifyAuthenticationPage} exact />
        <Route path='/sign_up/set_password' component={SignUpSetPasswordPage} exact />
        <Route path='/sign_up_referral' component={SignUpReferralPage} exact />
        <Route path='/sign_up_referral/complete' component={SignUpReferralCompletePage} />

        <PrivateRoute
          path={[
            '/:tab(main)',
            '/:tab(leads)',
            '/:tab(jobs)',
            '/:tab(conversations)',
            '/:tab(settings)',
            '/'
          ]}
          component={MainPages}
          isError={isError}
          exact
        />

        <Route
          path='/verification/generate'
          name='verification-generate'
          component={VerifyGeneratePage}
          exact
        />
        <Route
          path='/verification/verify'
          name='verification-verify'
          component={VerifyPage}
          exact
        />
        <Route
          path='/verification/complete'
          name='verification-complete'
          component={VerifyCompletePage}
          exact
        />

        <PrivateRoute
          path={[
            '/:tab(leads)/:id/answer_photos',
            '/:tab(jobs)/:id/answer_photos',
            '/:tab(jobs)/:id/report_photos',
            '/:tab(jobs)/history/:id/report_photos'
          ]}
          component={PhotosPage}
        />

        <PrivateRoute path='/:tab(leads)/:id' component={LeadShowPage} exact />
        <PrivateRoute path='/:tab(leads)/:id/map' component={LeadMapPage} />

        <PrivateRoute path='/:tab(jobs)/history' component={JobHistoryIndexPage} exact />
        <PrivateRoute
          path='/:tab(jobs)/history/:id'
          component={JobHistoryShowPage}
          exact
        />

        <PrivateRoute path='/:tab(jobs)/:id' component={JobShowPage} exact />
        <PrivateRoute path='/:tab(jobs)/:id/map' component={JobMapPage} />
        <PrivateRoute path='/:tab(jobs)/:id/completion' component={JobCompletionPage} />
        <PrivateRoute path='/:tab(jobs)/:id/delivery' component={JobDeliveryShowPage} />
        <PrivateRoute path='/:tab(jobs)/:id/rating' component={JobRatingPage} />

        {/* <PrivateRoute path='/:tab(conversations)/admin' component={ConversationWithAdminPage} /> */}
        <PrivateRoute
          path='/:tab(conversations)/:id'
          component={ConversationShowPage}
          exact
        />

        <PrivateRoute path='/:tab(settings)/profile' component={ProfilePage} />
        <PrivateRoute path='/:tab(settings)/address' component={AddressPage} />
        <PrivateRoute path='/:tab(settings)/my_address' component={MyAddressPage} />
        <PrivateRoute path='/:tab(settings)/my_calendar' component={MyCalendarPage} />
        <PrivateRoute
          path='/:tab(settings)/disable_calendar'
          component={MyCalendarDisablePage}
        />
        <PrivateRoute
          path='/:tab(settings)/active_calendar'
          component={MyCalendarActivePage}
        />
        <PrivateRoute
          path='/:tab(settings)/my_available_distance'
          component={MyAvailableDistancePage}
        />

        <PrivateRoute path='/:tab(settings)/wallet' component={WalletPage} />
        <PrivateRoute
          path='/:tab(settings)/wallet_withdrawal'
          component={WalletWithdrawalPage}
          exact
        />
        <PrivateRoute
          path='/:tab(settings)/my_bank_account'
          component={MyBankAccountPage}
        />
        <PrivateRoute
          path='/:tab(settings)/wallet_withdrawal/error'
          component={WalletWithdrawalErrorPage}
        />

        <PrivateRoute
          path='/:tab(settings)/bank_account/new'
          component={BankAccountNewPage}
        />
        <PrivateRoute path='/:tab(settings)/referral/' component={ReferralPage} exact />
        <PrivateRoute
          path='/:tab(settings)/referral/status'
          component={ReferralStatusPage}
        />

        <PrivateRoute
          path='/:tab(settings)/bank_account/:id/edit'
          component={BankAccountEditPage}
          exact
        />
        <PrivateRoute
          path='/:tab(settings)/bank_account/:id/pending'
          component={BankAccountPendingPage}
        />

        <PrivateRoute
          path={[
            '/team/work/incomming',
            '/team/work/pending',
            '/team/work/completed',
            '/team/work',
            // '/team/work/:status(incomming)',
            // '/team/work/:status(pending)',
            // '/team/work/:status(completed)',
            '/team/people',
            '/team'
          ]}
          component={Team}
        />

        <PrivateRoute component={NotFoundPage} />
      </IonRouterOutlet>
    </IonReactRouter>
  )
}

export default Router
