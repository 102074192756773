import React from 'react'
import { useHistory } from 'react-router'
import { IonAlert } from '@ionic/react'
import PropTypes from 'prop-types'
import { useEnterModal } from 'hooks/phone_verification'

const VerifyPhoneAlert = ({
  isOpen,
  dissmiss,
  openedFrom,
  header,
  message,
  confirm,
  cancel
}) => {
  const { push } = useHistory()
  const { enterModalFrom } = useEnterModal()

  const handleConfirm = () => {
    enterModalFrom(openedFrom)
    push('/verification/generate')
  }
  return (
    <IonAlert
      header={header}
      isOpen={isOpen}
      message={message}
      onDidDismiss={dissmiss}
      buttons={[
        {
          text: confirm,
          handler: () => dissmiss()
        },
        {
          text: cancel,
          handler: () => handleConfirm()
        }
      ]}
    />
  )
}

VerifyPhoneAlert.propTypes = {
  isOpen: PropTypes.bool,
  dissmiss: PropTypes.func,
  openedFrom: PropTypes.string,
  header: PropTypes.string,
  message: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string
}

export default VerifyPhoneAlert
