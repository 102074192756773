import { IonIcon, IonItem, IonLabel } from '@ionic/react'
// import { useHookWithRefCallback } from 'hooks/utils'
import { chevronDown, chevronUp } from 'ionicons/icons'
import React, { useState } from 'react'
import './Accordion.scss'

const Accordion = ({ children, label = '', defaultExpand, labelRight }) => {
  const [expanded, setExpand] = useState(true)
  // const [height, setHeight] = useState('100vh')
  // const [height, setHeight] = useState('auto')

  // const [ref, setRef] = useHookWithRefCallback()

  // useEffect(() => {
  // if (children) {
  // if (expanded) {
  // const scrollHeight = ref.current?.scrollHeight
  // setHeight(scrollHeight > 0 ? `${scrollHeight}px` : 'auto')
  // setHeight('auto')
  // } else {
  // setHeight('0px')
  // }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [expanded])

  const handleExpand = () => {
    setExpand(!expanded)
  }

  return (
    <div lines='inset' className='accordion'>
      <IonItem lines='full' onClick={handleExpand} className='accordion__header'>
        <div slot='start' className='label__container ion-align-items-center'>
          <IonLabel>{label}</IonLabel>
          <IonIcon icon={expanded ? chevronDown : chevronUp} />
        </div>
        {labelRight && <div slot='end'>{labelRight}</div>}
      </IonItem>
      {/* <div ref={setRef style={{ maxHeight: `${height}` }} className='accordion__content'> */}
      <div
        // style={{ maxHeight: `${height}` }}
        className={`accordion__content ${expanded ? 'expanded' : 'closed'}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
