import { IonContent, IonGrid, IonRow, IonText, useIonViewDidLeave } from '@ionic/react'
import { tenantSchema } from '@seekster/schemas'
import AdditionalInfoBox from 'components/AdditionalInfoBox'
import VerifyAlertError from 'components/alerts/VerifyAlertError'
import { PageHeader, PageLayout } from 'components/layout'
import TenantLogoHeader from 'components/TenantLogoHeader'
import Timer from 'components/Timer'
import Subheader from 'components/typography/Subheader'
import OtpForm from 'forms/OtpForm'
import { useGetNumberInfo, usePhoneVerification } from 'hooks/phone_verification'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getCurrentResource } from 'selectors/authentication'
import { formatPhoneString } from 'utils/stringUtils'
import { errorHasResponseBody, errorIsAlert } from '../shared/verificationErrors'

import './VerifyPage.scss'

const VerifyPage = () => {
  const { t, i18n } = useTranslation(['verify_phone_number'])
  const { push } = useHistory()
  const { phoneNumber, countryCode } = useGetNumberInfo()
  const { verOtp, genOtp } = usePhoneVerification(i18n.language)
  const [error, setError] = useState('')
  const [alertError, setAlertError] = useState({
    display: false,
    header: '',
    body: ''
  })

  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  useIonViewDidLeave(() => setError(''))

  const formattedPhoneNumber = formatPhoneString(phoneNumber, countryCode)

  const handleError = (error) => {
    if (errorHasResponseBody(error)) {
      if (errorIsAlert(error.response.body.error)) {
        setAlertError({
          display: true,
          header: t(`errors.${error.response.body.error}`),
          body: error.response.body.message
        })
        if (
          error.response.body.error === 'verificaition_suspension' ||
          error.response.body.error === 'verificaition_suspension_admin'
        ) {
          push('/verification/generate')
        }
        setError('temp')
        setError('')
      } else {
        setError('')
        setError(error.response.body.message)
      }
      return
    }
    return setError(error)
  }

  const resetError = () => {
    setError('')
  }

  const navigate = () => push('/verification/complete')

  const generateOtp = async () => genOtp(phoneNumber).catch((error) => handleError(error))
  const verifyOtp = async (otp) =>
    verOtp(otp)
      .then(() => navigate())
      .catch((error) => handleError(error))

  return (
    <PageLayout>
      <PageHeader
        title={'Verify phone number'}
        withBackButton
        backTo='/verification/generate'
      />
      <IonContent>
        <IonGrid className='verify-grid'>
          <IonRow className='verify-logo-row'>
            <TenantLogoHeader url={tenant.get('dark_logo_url')} />
          </IonRow>
          <IonRow className='verify-subheader-row'>
            <Subheader>{t('verify.subheader', { tenant: tenant.get('name') })}</Subheader>
          </IonRow>
          <IonRow className='verify-phone-number-row'>
            <Subheader isInfo>{formattedPhoneNumber}</Subheader>
          </IonRow>
          <IonRow className='verify-phone-number-form-row'>
            <OtpForm
              submit={verifyOtp}
              hasErrors={error.length}
              resetError={resetError}
            />
          </IonRow>
          {error && (
            <IonRow className='verify-phone-number-error-row'>
              <IonText className='verify-error'>{error}</IonText>
            </IonRow>
          )}
          <IonRow className='timer-row'>
            <IonRow>
              <Timer
                pre={t('timer.pre')}
                after={t('timer.after')}
                onReset={generateOtp}
                resetPre={t('timer.resetPre')}
                resetAfter={t('timer.resetAfter')}
              />
            </IonRow>
            <IonRow>
              <AdditionalInfoBox>{t('info_box.text')}</AdditionalInfoBox>
            </IonRow>
          </IonRow>
        </IonGrid>
        <VerifyAlertError
          isOpen={alertError.display}
          errorBody={alertError.body}
          errorHeader={alertError.header}
          closeText={'Ok'}
          dissmiss={() =>
            setAlertError({
              display: false,
              body: '',
              headeR: ''
            })
          }
        />
      </IonContent>
    </PageLayout>
  )
}

export default VerifyPage
