import React, { useState, useRef } from 'react'
import {
  IonAvatar,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTextarea
} from '@ionic/react'
import moment from 'moment'
import { jobSchema } from '@seekster/schemas'
import { contract, expand, navigate } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { PageHeader } from 'components/layout'
import { fetchCollection } from 'actions/collections'
import { useDispatch } from 'react-redux'
import { useResourceActions } from 'hooks/resources'

import './TeamJobCommentModal.scss'

const TeamJobCommentModal = ({ isOpen, handleCloseModal, comments, jobId }) => {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const [content, setContent] = useState('')
  const { t } = useTranslation('team_lead')

  const { create: createComment } = useResourceActions(jobSchema)

  const handleTextarea = (e) => {
    setContent(e.target.value)
  }

  const handleExpand = (e) => {
    setExpanded(!expanded)
    const ionTextarea = ref.current
    ionTextarea.setFocus()
  }

  const handleCreateComment = async () => {
    await createComment(`/jobs/${jobId}/comments`, { content })
    dispatch(fetchCollection(`/jobs/${jobId}/comments`, jobSchema))
    setContent('')
    handleCloseModal()
  }

  const sortedComments = () => {
    return comments.sort(
      (a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    )
  }

  return (
    <IonModal isOpen={isOpen} className='team-job-comment-modal' onDidDismiss={() => {}}>
      <PageHeader noColor withBackButton title={t('comment')} onBack={handleCloseModal} />
      <IonContent>
        <IonList className='job-comment-list'>
          {sortedComments().map((comment) => {
            return <JobCommentItem key={comment.id} comment={comment} />
          })}
        </IonList>
      </IonContent>
      <IonFooter className={`${expanded ? 'expanded' : ''}`}>
        <IonGrid>
          <IonRow>
            <IonCol className='comment-column ion-no-padding' size='10'>
              <IonTextarea
                className=''
                ref={ref}
                autofocus
                value={content}
                onIonChange={handleTextarea}
              />
            </IonCol>
            <IonCol className='icon-column ion-no-padding' size='1'>
              <IonIcon
                className='expand-icon'
                icon={expanded ? contract : expand}
                onClick={handleExpand}
              />
              <IonIcon
                onClick={handleCreateComment}
                className='navigate-icon'
                icon={navigate}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonModal>
  )
}

export default TeamJobCommentModal

const JobCommentItem = ({ comment }) => {
  return (
    <IonItem
      className='job-comment-item'
      key={comment.id}
      lines='none'
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        border: '1px solid #CECECE66',
        borderRadius: '5px'
      }}
    >
      <IonAvatar slot='start'>
        <IonImg
          src={
            'assets/img/default_avatar.png' ||
            comment.agent.avatar_url ||
            comment.provider.avatar_url
          }
          alt='avatar_url'
        />
      </IonAvatar>
      <IonLabel>
        <h2>{comment.agent?.display_name || comment.provider?.display_name}</h2>
        <div>{moment(comment.created_at).format('D MMM YYYY, HH:mm')}</div>
        <div className='ion-text-wrap'>{comment.content}</div>
      </IonLabel>
    </IonItem>
  )
}
