import { withFormik } from 'formik'
import { List, Map } from 'immutable'

import convertUriToImageFile from 'utils/convertUriToImageFile'

import JobReportForm from './JobReportForm'

const parseValues = async (values) => {
  const _ans = values.answers_attributes.map(async (answer) => {
    const isImageType = answer.image
    const isImagesType = answer.attachments_attributes && answer.attachments_attributes.length !== 0

    if (isImageType) {
      const image = await convertUriToImageFile(answer.image)

      return { ...answer, image }
    }
    else if (isImagesType) {
      const _images = answer.attachments_attributes.map(async (attchAttr) => {
        const image = await convertUriToImageFile(attchAttr.file)

        return { file: image }
      })

      const attachmentsAttributes = await Promise.all(_images)

      return { ...answer, attachments_attributes: attachmentsAttributes }
    }
    else {
      return answer
    }
  })

  const answersAttributes = await Promise.all(_ans)

  return { answers_attributes: answersAttributes }
}

const formikConfig = {
  mapPropsToValues: ({ cachedAnswers = {}, reportQuestions = List(), existingAnswers = List() }) => {
    return {
      answers_attributes: reportQuestions.map(question => {
        const questionId = question.get('id')
        const ans = existingAnswers.find(a => a.getIn(['question', 'id']) === questionId) || Map()

        return {
          id: ans.get('id'),
          question_id: questionId,
          ...cachedAnswers[questionId]
        }
      }).toJS()
    }
  },

  enableReinitialize: true,

  handleSubmit: async (values, { props, setSubmitting }) => {
    // setSubmitting(true)

    const parsedValues = await parseValues(values)

    props.onSubmit(parsedValues)
    // .then(response => {
    //   setSubmitting(false)

    //   props.push(`/jobs/${response.body.id}/completion`)
    // })
    // .catch(() => {
    //   setSubmitting(false)
    // })
  }
}

export default withFormik(formikConfig)(JobReportForm)
