import NotificationModal from 'components/modals/NotificationModal/NotificationModal';
import React, { useState } from 'react';

// TODO: passing props 
const dataSource = {
  "title": "งานถูกปิด",
  "body": "งานหมายเลข JAYFORTEST2022103131003H-221031349806 วันที่ 02/11/2022 เวลา 09:00-12:00 ถูกเอาออกจากรายการที่ต้องทำแล้ว",
  "image": "https://api.staging.true-e-logistics.com/v2/file-service/static/IkwikHvoz.png",
  // "sound": "default",
  // "mutable_content": true,
};

const useNotification = (_props) => {
  const [isOpen, setIsOpen] = useState(false);

  // TODO: should received from props or redux
  const title = dataSource.title;
  const description = dataSource.body;
  const imageUrl = dataSource.image;

  // TODO: listen and trigger notification modal
  // Redux or Some event ?

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };


  // TODO: implement multiple display modal, waiting notification service
  const notificationModal = (
    <NotificationModal
      isOpen={isOpen}
      title={title}
      description={description}
      imageUrl={imageUrl}
      // buttonText={ปิด|แสดง}
      onClose={onCloseModal}
    />
  );

  return {
    isOpen,
    // func
    onOpenNotificationModal: onOpenModal,
    onCloseNotificationModal: onCloseModal,
    // Element
    notificationModal,
  }
}

export default useNotification;
