import React from 'react'
import PropTypes from 'prop-types'
import { IonCheckbox, IonList, IonItem, IonLabel } from '@ionic/react'

import './CheckboxInput.scss'

const CheckboxInput = ({ value, onChange, options, required }) => {
  const handleChange = (e) => {
    const targetValue = e.target.value
    let newValue = []

    if (value.includes(targetValue)) {
      newValue = value.filter((el) => el.toString() !== targetValue)
    } else {
      newValue = [...value, targetValue]
    }

    onChange && onChange(newValue)
  }

  return (
    <IonList className='checkbox-input-list-container'>
      {options.map(({ value: optionValue, label }, i) => (
        <IonItem key={i} lines='none'>
          <IonLabel>{label}</IonLabel>

          <IonCheckbox
            slot='start'
            value={optionValue.toString()}
            mode='md'
            checked={value.includes(optionValue.toString())}
            onClick={handleChange}
          />
        </IonItem>
      ))}

      <input
        required={required}
        value={value.length > 0 ? '-' : ''}
        style={{ opacity: 0, height: 0, position: 'absolute' }}
      />
    </IonList>
  )
}

CheckboxInput.defaultProps = {
  value: []
}

CheckboxInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool
}

export default CheckboxInput
