import superagent from 'superagent'
import prefix from 'superagent-prefix'

// import { normalize } from 'normalizr'
// import { Plugins } from '@capacitor/core'
// const { Storage } = Plugins

// import { updateEntities } from 'actions/entities'
// import withTenant from './withTenant'

const agent = superagent.agent()

// ===== Create helper method for attaching Access Tokens =====
superagent.Request.prototype.authentication = function (accessToken) {
  if (accessToken) {
    return this.auth(accessToken, { type: 'bearer' })
  } else {
    return this
  }
}

// ===== Create helper method for setting locale =====
superagent.Request.prototype.locale = function (locale) {
  return this.query({ locale })
}

export default agent
  .use(prefix(`${process.env.REACT_APP_TEAM_DISTRIBUTION_API}/v1`))
  .on('error', (error) => console.warn(error))
