import { combineReducers } from 'redux-immutable'

import entities from './entities'
import collections from './collections'
import authentication from './authentication'
import interfaces from './interfaces'
import phoneVerification from './phone_verification'
import policy from './policy'
import teamDistribution from './teamDistribution'

const rootReducer = combineReducers({
  entities,
  collections,
  authentication,
  interfaces,
  phoneVerification,
  policy,
  teamDistribution
})

export default rootReducer
