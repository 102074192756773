import {
  SET_OPEN_POLICY_MODAL,
  SET_CLOSE_POLICY_MODAL,
  ACCEPT_POLICY_SUCCESS,
  ACCEPT_POLICY_FAIL,
  DECLINE_POLICY_SUCCESS,
  DECLINE_POLICY_FAIL
} from 'constants/policy'
import { getAccessToken, getBasicAuth } from 'selectors/authentication'
import request from 'utils/request'

export const setOpenPolicyModal = (variant = false) => {
  return {
    type: SET_OPEN_POLICY_MODAL,
    payload: {
      open: true,
      variant
    }
  }
}

export const setClosePolicyModal = () => {
  return {
    type: SET_CLOSE_POLICY_MODAL
  }
}

export const acceptPolicy = (policyId) => async (dispatch, getState) => {
  try {
    return request
      .auth(getBasicAuth(getState()))
      .post(`/policy_terms/accept?id=${policyId}`)
      .authentication(getAccessToken(getState()))
      .send()
      .then((response) => {
        dispatch({
          type: ACCEPT_POLICY_SUCCESS,
          response
        })
        return response
      })
  } catch (error) {
    dispatch({
      type: ACCEPT_POLICY_FAIL
    })
    throw error
  }
}

export const declinePolicy = (policyId) => async (dispatch, getState) => {
  try {
    return request
      .auth(getBasicAuth(getState()))
      .put(`/policy_terms/decline?id=${policyId}`)
      .authentication(getAccessToken(getState()))
      .send()
      .then((response) => {
        dispatch({
          type: DECLINE_POLICY_SUCCESS,
          response
        })
        return response
      })
  } catch (error) {
    dispatch({
      type: DECLINE_POLICY_FAIL
    })
    throw error
  }
}
